export const SET_PRINTERS = "SET_PRINTERS";
export const SET_PRINTER_EVENTS = "SET_PRINTER_EVENTS";
export const SET_RESTAURANT = "SET_RESTAURANT";
export const SET_RESTAURANT_PLACE = "SET_RESTAURANT_PLACE";
export const SET_DATAS = "SET_DATAS";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_EXTRAS = "SET_EXTRAS";
export const SET_FOODS = "SET_FOODS";
export const SET_LOADING = "SET_LOADING";
export const ADD_CART = "ADD_CART";
export const SET_SEARCH = "SET_SEARCH";
export const UPDATE_CART = "UPDATE_CART";
export const SHOW_EXTRA = "SHOW_EXTRA";
export const SHOW_LOCATION = "SHOW_LOCATION";
export const SET_PROCESSING = "SET_PROCESSING";
