import React, {useContext, useState} from 'react';
import {View, Text, TextInput, TouchableOpacity, StyleSheet, Dimensions, Alert, BackHandler} from 'react-native';
import Feather from 'react-native-vector-icons/Feather';
import AsyncStorage from "@react-native-async-storage/async-storage";
import Observables from "@happy-gastro/global-observables";
import {BluetoothContext} from "../helpers/BluetoothContext";
import {LinearGradient} from "expo-linear-gradient";
import StatisticsHelper from "../helpers/StatisticsHelper";
import {priceFormatter} from "../helpers/numbers";
import {OrdersContext} from "../helpers/OrdersContext";

const { width, height } = Dimensions.get('window');

const DayClosingScreen = ({ navigation }) => {
    const {orders} = useContext(OrdersContext);
    const {sendData} = useContext(BluetoothContext);
    const {productsSum, statistics} = StatisticsHelper(orders);

    const [amount, setAmount] = useState('');

    const handleInput = (value) => {
        setAmount((prev) => prev + value);
    };

    const handleBackspace = () => {
        setAmount((prev) => prev.slice(0, -1));
    };

    const handleClear = () => {
        setAmount('');
    };

    const handleProcess = () => {
        Alert.alert(
            'Megerősítés',
            'Biztosan szeretnéd a pénzügyi napot lezárni?',
            [
                {
                    text: 'Mégsem',
                    onPress: () => console.log('Cancel Pressed'),
                    style: 'cancel',
                },
                {
                    text: 'Igen',
                    onPress: async () => {
                        try {
                            await AsyncStorage.setItem('dayOpened', 'false');
                            await AsyncStorage.setItem('dayClosed_amount', (amount || 0).toString());
                            Observables.call("reloadDayOpeningClosing");
                            try {
                                await sendData(`fiscat/AEE|DC||||1`);
                            } catch (fiscatCloseError) {
                                console.error("Fiscat close error", fiscatCloseError);
                            }
                        } catch (dayClosingError) {
                            console.error("Day closing error", dayClosingError);

                        } finally {
                            navigation.navigate("SuccessDayClosingScreen")
                        }

                    },
                },
            ],
            { cancelable: true }
        );
    }

    return (
        <LinearGradient
            colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']}
            style={styles.background}
            start={{ x: 0.3, y: 0 }}
            end={{ x: 0.7, y: 1 }}
        ><View style={{ width: '90%', justifyContent: 'flex-start', paddingTop: 50, marginTop: 40}}>
            <View style={styles.section}>
                <Text style={styles.title}>Forgalmi adatok</Text>
                <View style={styles.row}>
                    <Text>Termék forgalom:</Text>
                    <Text>{priceFormatter(statistics.productsSum || 0)}</Text>
                </View>
                <View style={styles.row}>
                    <Text>Szervízdíjak:</Text>
                    <Text>{priceFormatter(statistics.serviceFeePrice || 0)}</Text>
                </View>
                <View style={styles.row}>
                    <Text style={{fontWeight: "700", fontSize: 17}}>Teljes forgalom:</Text>
                    <Text style={{
                        fontWeight: "700",
                        fontSize: 17
                    }}>{priceFormatter((statistics.finalSum) || 0)}</Text>
                </View>
                <View style={styles.row}>
                    <Text>Borravaló (Gyorsnyugta):</Text>
                    <Text>{priceFormatter(statistics?.tipAmount?.takeAway || 0)}</Text>
                </View>
            </View>

            {/* Payment Method */}
            <View style={styles.section}>
                <Text style={styles.title}>Fizetési mód</Text>
                <View style={styles.row}>
                    <Text>Készpénz:</Text>
                    <Text>{statistics.cashOrders.length} rendelés
                        / {priceFormatter(statistics.cashOrders.reduce((a, b) => +a + b.orderSum, 0))}</Text>
                </View>
                <View style={styles.row}>
                    <Text>Üzlet bankkártya:</Text>
                    <Text>{statistics.creditCardOrders.length} rendelés
                        / {priceFormatter(statistics.creditCardOrders.reduce((a, b) => +a + b.orderSum, 0))}</Text>
                </View>
                <View style={styles.row}>
                    <Text>Online bankkárty:</Text>
                    <Text>{statistics.onlineCreditCardOrders.length} rendelés
                        / {priceFormatter(statistics.onlineCreditCardOrders.reduce((a, b) => +a + b.orderSum, 0))}</Text>
                </View>
            </View>
            <View style={styles.buttonContainer}>
                <TouchableOpacity style={styles.backButton} onPress={() => navigation.navigate('HomeScreen')}>
                    <Feather name="arrow-left" size={24} color="#fff" />
                </TouchableOpacity>
                <TouchableOpacity style={styles.processButton} onPress={() => handleProcess()}>
                    <Text style={styles.processButtonText}>Nap zárása</Text>
                </TouchableOpacity>
            </View>
        </View>
        </LinearGradient>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
    },
    background: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingTop: 10,
        height: height,
    },
    amountContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 5,
        width: '100%',
    },
    amountInput: {
        fontSize: 32,
        textAlign: 'center',
        color: '#333',
        borderBottomWidth: 1,
        width: width * 0.8,
    },
    pinpadContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        width: width,
        paddingHorizontal: 0,
    },
    pinpadButton: {
        width: width / 3.4,
        height: height / 10,
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 5,
        borderColor: '#e8e8e8',
        borderWidth: 0.5,
    },
    pinpadText: {
        fontSize: 28,
        color: '#333',
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingHorizontal: 16,
        marginTop: 20,
    },
    backButton: {
        backgroundColor: 'black',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 15,
        borderRadius: 5,
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
    },
    processButton: {
        backgroundColor: 'orange',
        alignItems: 'center',
        padding: 15,
        flex: 1,
        marginLeft: 0,
        borderBottomRightRadius: 5,
        borderTopRightRadius: 5,
    },
    processButtonText: {
        fontSize: 20,
        color: '#fff',
        fontWeight: 'bold',
    },

    section: {
        marginBottom: 24,
        padding: 16,
        backgroundColor: '#f9f9f9',
        borderRadius: 8,
        shadowColor: '#000',
        shadowOpacity: 0.1,
        shadowRadius: 4,
        shadowOffset: {width: 0, height: 2},
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 8,
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 8,
    },
});

export default DayClosingScreen;