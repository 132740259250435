import React from 'react';
import {ScrollView, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {LinearGradient} from "expo-linear-gradient";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import {FontAwesome, FontAwesome5, MaterialIcons} from "@expo/vector-icons";

const FunctionsScreen = (props) => {
    const {navigation} = props;

    return (
        <LinearGradient
            colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']}
            style={{flex: 1, width: '100%', height: '100%'}}
        >
            <ScrollView style={{paddingBottom: 50}}>
                <View style={styles.container}>
                <View style={{width: "100%", marginTop: 30, padding: 15}}>
                    <Text
                        style={{
                            fontWeight: "bold",
                            textAlign: "left",
                            fontSize: 17,
                        }}
                    >
                        Napi kimutatások és pénztárgép funkciók
                    </Text>
                    <Text style={{textAlign: "left", marginTop: 15, borderRadius: 10}}>
                        Az alábbi felületen érhetőek el a napi kimutatások, a nap zárása, az egyéb pénztárgép funkciók, valamint a készletkezelési lehetőségek.
                    </Text>
                </View>
                <View style={{width: "100%", marginTop: 20, paddingHorizontal: 15}}>
                    <Text style={{fontWeight: "bold", textAlign: "left", fontSize: 17}}>
                        Forgalmi kimutatás
                    </Text>
                    <View style={{flexDirection: "row", justifyContent: "space-between", marginTop: 15}}>
                        <TouchableOpacity activeOpacity={0.7} style={styles.box} onPress={() => navigation.navigate("StatisticsScreen")}>
                            <View style={styles.boxInner}>
                                <Icon name="chart-line" size={30} color="#000"/>
                                <Text style={styles.boxTitle}>Napi forgalom</Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity activeOpacity={0.7} style={styles.box} onPress={() => navigation.navigate("DayClosingScreen")}>
                            <View style={styles.boxInner}>
                                <Icon name="lock" size={30} color="#000"/>
                                <Text style={styles.boxTitle}>Pénzügyi nap zárása</Text>
                            </View>
                        </TouchableOpacity>
                        <View style={styles.box}>
                            <Icon name="calendar" size={30} color="#000"/>
                            <Text style={styles.boxTitle}>Egyéb forgalmak</Text>
                        </View>
                    </View>
                </View>
                <View style={{width: "100%", marginTop: 20, paddingHorizontal: 15}}>
                    <Text style={{fontWeight: "bold", textAlign: "left", fontSize: 17}}>
                        Pénztárgép funkciók
                    </Text>
                    <View style={{flexDirection: "row", justifyContent: "space-between", marginTop: 15}}>
                        <TouchableOpacity activeOpacity={1} style={styles.box}>
                            <View style={styles.boxInner}>
                                <Icon name="feed" size={30} color="#000"/>
                                <Text style={styles.boxTitle}>Státusz lekérdezése</Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity activeOpacity={1} style={styles.box}>
                            <View style={styles.boxInner}>
                                <Icon name="chart-line" size={30} color="#000"/>
                                <Text style={styles.boxTitle}>Napi lekérdezés</Text>
                            </View>
                        </TouchableOpacity>
                        <View style={styles.box}>
                            <MaterialIcons name="table" size={30} color="#000"/>
                            <Text style={styles.boxTitle}>Időszakos lekérdezés</Text>
                        </View>
                    </View>
                    <View style={{flexDirection: "row", justifyContent: "space-between", marginTop: 15}}>
                        <TouchableOpacity activeOpacity={1} style={styles.box}>
                            <View style={styles.boxInner}>
                                <MaterialIcons name="attach-money" size={30} color="#000"/>
                                <Text style={styles.boxTitle}>Pénz be</Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity activeOpacity={1} style={styles.box}>
                            <View style={styles.boxInner}>
                                <MaterialIcons name="money-off" size={30} color="#000"/>
                                <Text style={styles.boxTitle}>Pénz ki</Text>
                            </View>
                        </TouchableOpacity>
                        <View style={styles.box}>
                            <MaterialCommunityIcons name="bottle-soda-classic-outline" size={30} color="#000"/>
                            <Text style={styles.boxTitle}>Göngyöleg</Text>
                        </View>
                    </View>
                </View>
                <View style={{width: "100%", marginTop: 20, paddingHorizontal: 15}}>
                    <Text style={{fontWeight: "bold", textAlign: "left", fontSize: 17}}>
                        Teendők
                    </Text>
                    <View style={{flexDirection: "row", justifyContent: "space-between", marginTop: 15}}>
                        <TouchableOpacity activeOpacity={1} style={styles.box}>
                            <View style={styles.boxInner}>
                                <Icon name="feed" size={30} color="#000"/>
                                <Text style={styles.boxTitle}>Előrendelések</Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity activeOpacity={1} style={styles.box}>
                            <View style={styles.boxInner}>
                                <FontAwesome name="list-ul" size={30} color="#000"/>
                                <Text style={styles.boxTitle}>Teendők</Text>
                            </View>
                        </TouchableOpacity>
                        <View style={styles.box}>
                            <Icon name="calendar-check-o" size={30} color="#000"/>
                            <Text style={styles.boxTitle}>Foglalások</Text>
                        </View>
                    </View>
                </View>
                <View style={{width: "100%", marginTop: 20, paddingHorizontal: 15}}>
                    <Text style={{fontWeight: "bold", textAlign: "left", fontSize: 17}}>
                        Raktár műveletek
                    </Text>
                    <View style={{flexDirection: "row", justifyContent: "space-between", marginTop: 15}}>
                        <TouchableOpacity activeOpacity={1} style={styles.box}>
                            <View style={styles.boxInner}>
                                <Icon name="book" size={30} color="#000"/>
                                <Text style={styles.boxTitle}>Standolás</Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity activeOpacity={1} style={styles.box}>
                            <View style={styles.boxInner}>
                                <FontAwesome5 name="file-invoice" size={30} color="#000"/>
                                <Text style={styles.boxTitle}>Bevételezés</Text>
                            </View>
                        </TouchableOpacity>
                        <View style={styles.box}>
                            <MaterialCommunityIcons name="chart-bell-curve" size={30} color="#000"/>
                            <Text style={styles.boxTitle}>Fogyó készlet</Text>
                        </View>
                    </View>
                </View>
            </View>
            </ScrollView>
        </LinearGradient>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
    },
    subtitle: {
        fontSize: 16,
        marginBottom: 40,
    },
    boxInner: {
        alignItems: "center",
        justifyContent: "center"
    },
    box: {
        width: "30%",
        padding: 10,
        backgroundColor: "#fff",
        borderRadius: 10,
        alignItems: "center",
        justifyContent: "center",
        shadowColor: "#000",
        shadowOffset: {width: 0, height: 2},
        shadowOpacity: 0.2,
        shadowRadius: 2,
        elevation: 3,
    },
    boxTitle: {
        marginTop: 10,
        fontSize: 14,
        fontWeight: "bold",
        textAlign: "center",
    }
});

export default FunctionsScreen;