import React, {useState} from 'react';
import {Alert, Button, StyleSheet, Text, View} from 'react-native';
import {connect, useDispatch, useSelector} from 'react-redux';
import Accordion from 'react-native-collapsible/Accordion';
import {Checkbox} from 'react-native-paper';
import axios from "axios";
import {getAccessToken} from "../helpers/storage";
import {ApiConfig} from "../config/ApiConfig";
import {setPrinterEvents, setPrinters} from "../store/actions/terminal";
import notFoundEmojiAnimation from "../../assets/animations/not-found-emoji.json";
import LottieView from "lottie-react-native";
import {LinearGradient} from "expo-linear-gradient";

const SettingsPrinterPairing = (props) => {
    const [activeSections, setActiveSections] = useState([]);

    React.useEffect(() => {
        _getPrinterEvents();
    }, []);

    React.useEffect(() => {
        setPrinterEvents(props.printerEvents)
    }, [props.printerEvents]);



    const _getPrinterEvents = async () => {
        const token = await getAccessToken();
        axios.get(ApiConfig.apiURL + "/api/restaurant/printerEvent/get", {
            headers: {
                "Accept-Language": "hu_HU",
                authorization: `Bearer ${token}`,
            },
        })
            .then(async function ({data}) {
                console.log("data", data.map((printerEvent) => {
                    const printersFind = props.printerEvents.find(
                        (pE) => pE._id === printerEvent._id
                    );
                    return {
                        ...printerEvent,
                        printers:
                            printersFind && printersFind.printers
                                ? printersFind.printers
                                : [],
                    };
                }))
            })
            .catch((e) => {
                Alert.alert(
                    "Nem sikerült a profilok betöltése!",
                    "Próbáld újra majd vedd fel velünk a kapcsolatot, ha a hiba továbbra is fennáll!"
                );
                console.error(e);
            });
    };

    const handleTogglePrinterSelection = (eventId, printer) => {
        //props.setPairingEvents(eventId, printer);
    };

    const renderHeader = (section, _, isActive) => (
        <View style={[styles.header, isActive ? styles.active : styles.inactive]}>
            <Text style={styles.headerText}>{section.name}</Text>
        </View>
    );

    const renderContent = (section) => (
        <View style={styles.content}>
            <View style={styles.content}>
                {props.printers.length === 0 ? (
                    <View style={styles.noPrintersFound}>
                        <View style={styles.lottieContainer}>
                            <LottieView
                                source={notFoundEmojiAnimation}
                                autoPlay
                                loop
                                style={styles.notFoundAnimation}
                            />
                        </View>
                        <View style={styles.noPrintersTextContainer}>
                            <Text style={styles.noPrintersTitle}>Nyomtatók nem találhatóak</Text>
                            <Button
                                title={"Nyomtatók felderítése"}
                                mode="contained"
                                color="#fca34c"
                                onPress={() => props.navigation.navigate("SettingsPrinterSearchScreen")}
                            >
                                Nyomtatók felderítése
                            </Button>
                        </View>
                    </View>
                ) : (
                    [...props.printers].map(printer => (
                        <View key={printer._id} style={styles.printerItem}>
                            <Checkbox
                                status={section.printers.some(p => p.id === printer.id) ? 'checked' : 'unchecked'}
                                onPress={() => handleTogglePrinterSelection(section.id, printer)}
                            />
                            <Text>{printer.name || printer.deviceName || printer.ip || printer.ipAddress || printer.mac || printer.macAddress}</Text>
                        </View>
                    ))
                )}
            </View>
        </View>
    );

    return (
        <LinearGradient
            colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']}
            style={{ flex: 1, width: '100%', height: '100%' }}
        >
        <View style={styles.container}>
            <Accordion
                sections={props.printerEvents || []}
                activeSections={activeSections}
                renderHeader={renderHeader}
                renderContent={renderContent}
                onChange={setActiveSections}
            />
        </View>
        </LinearGradient>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 10,
    },
    header: {
        padding: 10,
        fontWeight: 'bold',
        backgroundColor: '#fca34c',
        borderBottomWidth: 1,
        borderBottomColor: '#ddd',
    },
    headerText: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    content: {
        padding: 10,
    },
    printerItem: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 5,
    },

    active: {
        backgroundColor: '#f3a965',
    },
    inactive: {
        backgroundColor: '#f4f4f4',
    },
    noPrintersFound: {
        alignItems: 'center',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-around'
    },
    lottieContainer: {
        width: 80,
        height: 80,
    },
    notFoundAnimation: {
        width: '70px',
        height: '70px',
    },
    noPrintersTextContainer: {
        marginTop: 20,
    },
    noPrintersTitle: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    buttonContainer: {
        marginTop: 20,
    },
});


function mapStateToProps(state) {
    return {
        printerEvents: state.device.printerEvents,
        printers: state.device.printers
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setPrinterEventsRedux: (printerEvents) =>
            dispatch(setPrinterEvents(printerEvents)),
        setPrintersRedux: (printers) => dispatch(setPrinters(printers)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPrinterPairing);