const ThemeColor = {
	primary: "#2A3042",
	header: "#1E1F20",
	darkbg: "#94A3B8",
	secondary: "#2A3042",
	GooDo: "#2B516A",
	orange: "#ba733b",
	orangeHighlight: "#cb8e5d",
	loading: "#9AC5D3",
	success: "#34c28f",
	button: "#4C6F7B",
	lock: "#e32e2e",
	white: "#FFF",
	lighter2: "#FEFEFE",
	lighter: "#F3F3F3",
	light: "#DAE1E7",
	dark: "#444",
	black: "#000",
	gray: "#8e8e93",
	gray2: "#636366",
	gray3: "#48484a",
	gray4: "#3a3a3c",
	gray5: "#2c2c2e",
	gray6: "#1c1c1e",
	lightGray: "#8e8e93",
	lightGray2: "#aeaeb2",
	lightGray3: "#c7c7cc",
	lightGray4: "#d1d1d6",
	lightGray5: "#e5e5ea",
	lightGray6: "#f2f2f7",
	gradient: ["#2A3042", "#3d4444"],
};

export default ThemeColor;
