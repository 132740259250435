import React, { useState, useContext, useEffect, useRef } from "react";
import {
    StyleSheet,
    View,
    Animated,
    TouchableOpacity,
    Text,
    ImageBackground,
    Dimensions,
    ScrollView
} from "react-native";
import { LinearGradient } from "expo-linear-gradient";
import { ReactNativeZoomableView } from "@openspacelabs/react-native-zoomable-view";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Menu, SegmentedButtons } from "react-native-paper";
import IonIcons from "react-native-vector-icons/Ionicons";
import { TableContext } from "../helpers/TableContext";
import {OrdersContext} from "../helpers/OrdersContext";
import {CartContext} from "../helpers/CartContext";

export default function TableMapScreen({ navigation }) {
    const [selectedFloor, setSelectedFloor] = useState(0);
    const [restaurantPlace, setRestaurantPlace] = useState({ name: '', raw: '', floors: [] });
    const [menuVisible, setMenuVisible] = useState(false);
    const { selectTable, removeTable } = useContext(TableContext);
    const { orders, fetchOrdersFromAPI } = useContext(OrdersContext);
    const { setAlreadySentCartItems } = useContext(CartContext);

    useEffect(() => {
        console.log("orders " , orders);
    }, [orders]);

    useEffect(() => {
        fetchOrdersFromAPI();
        const setDatas = async () => {
            const restaurantPlaceStored = await AsyncStorage.getItem("restaurantPlace");
            setRestaurantPlace(JSON.parse(restaurantPlaceStored));
        };
        setDatas();
        removeTable(); // Remove the already selected table when the screen is loaded
    }, [navigation]);

    const zoomAnimatedValue = useRef(new Animated.Value(1)).current;
    const handleZoomIn = () => {
        zoomAnimatedValue.setValue(zoomAnimatedValue._value * 1.2);
    };
    const handleZoomOut = () => {
        if (zoomAnimatedValue._value / 1.2 >= 1) {
            zoomAnimatedValue.setValue(zoomAnimatedValue._value / 1.2);
        } else {
            zoomAnimatedValue.setValue(1);
        }
    };

    const GenerateElementView = ({ element = {}, index }) => {

        const tableOrder = orders.find(
            (order) =>
                !order.storno &&
                order.status !== "cancelled" &&
                order.status !== "closed" &&
                order.tableId === element._id
        );
        const _getColor = () => {
            if (!tableOrder) return "transparent";
            if (tableOrder.proforma) return "rgba(255,235,59,1)";
            if (tableOrder.status === "preparing") return "rgba(79,195,247,1)";
            if (tableOrder.status === "ready") return "rgba(75,124,71, 1)";
            if (tableOrder.status === "busy") return "rgb(79,195,247, 1)";
            if (tableOrder.status === "waitingForWaitress") return "#f1b44c";
            if (tableOrder.status === "waitingForPayment") return "rgb(140,14,9)";

            if (!tableOrder) return "transparent";
        };

        const getPhoto = () => {
            if (element.photo) {
                switch (element.photo) {
                    case 'light': return 'https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/tables/table-light.jpg';
                    case 'middle': return 'https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/tables/table-middle.jpg';
                    case 'dark': return 'https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/tables/table-dark.jpg';
                    default: return 'https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/tables/table-light.jpg';
                }
            }
            return 'https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/Images/tables/table-light.jpg';
        };
        return (
            <TouchableOpacity
                activeOpacity={0.5}
                onPress={() => {
                    setAlreadySentCartItems([]); // Clear the already sent cart items
                    selectTable(element); // Select the table
                    navigation.navigate("HomeScreen", { table: element });
                }}
                key={`${element._id}-${index}`}
                style={{
                    position: 'absolute',
                    left: `${element.left / 10}%`,
                    top: `${(element.top / 10) + 10}%`,
                    width: `${element.width / 2}px`,
                    height: `${element.height / 2}px`,
                    opacity: 0.8,
                    borderWidth: 0,
                    borderColor: 'black',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: 'center',
                    backgroundColor: element.color || 'transparent',

                }}
            >
                <View style={{
                    width: `${element.width / 2}px`,
                    height: `${element.height / 2}px`,
                    backgroundColor: _getColor(),
                    opacity: 0.8,
                    borderWidth: 0.7,
                    borderColor: 'black',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: 'center'
                }}>
                    {element.photo && (
                        <ImageBackground
                            source={{ uri: getPhoto() }}
                            fadeDuration={200}
                            defaultSource={{ uri: getPhoto() }}
                            resizeMode={"contain"}
                            resizeMethod={'auto'}
                            style={{ width: '100%', height: '100%' }}
                        />
                    )}
                    <Text style={{ textAlign: 'center', alignSelf: 'center', justifyContent: 'center' }}>{element.name || element.code}</Text>
                </View>
            </TouchableOpacity>
        );
    };

    const handleSelect = (value) => {
        console.log(`Selected: ${value}`);
        setMenuVisible(false);
        if (value === "dineIn") {
            navigation.navigate("TablesScreen");
        }
        if (value === "takeAway") {
            navigation.navigate("HomeScreen");
        }
    };

    return (
        <LinearGradient
            colors={['#f2e2d2', '#e6d6c1', '#dcd5ce']}
            style={styles.background}
        >
            <View style={styles.container}>
                <ImageBackground resizeMethod={'auto'} style={styles.contents}
                                 source={{ uri: 'https://1.bp.blogspot.com/-JKIEUkNZ3NQ/Xdp0zbaStpI/AAAAAAAAMQQ/a5kMu4CVlkYbHgO3AbU9fwEW_JPXRHvhgCEwYBhgL/s1600/tiles_seamless_simple_white_texture.jpg' }}
                                 resizeMode="repeat">

                    <View style={[styles.header, { zIndex: 9999999, position: 'absolute', top: 10, left: 10, right: 10, width: (Dimensions.get("screen").width - 20), flexDirection: 'row' }]}>
                        <ScrollView horizontal nestedScrollEnabled style={{ maxWidth: '85%' }}><SegmentedButtons
                            density={"small"}
                            style={{ marginTop: 7, flex: 1, width: '100%', height: 40 }}
                            value={selectedFloor.toString()}
                            onValueChange={setSelectedFloor}
                            theme={{ colors: { primary: '#fde1b6', onSecondaryContainer: 'white', secondaryContainer: '#F1B44C' }, fonts: { regular: { fontWeight: 'bold' } } }}
                            buttons={[
                                ...restaurantPlace?.floors.map((floor, index) => ({ checkedColor: 'black', value: index.toString(), label: floor.name }))
                            ]}
                        /></ScrollView>
                        <Menu
                            visible={menuVisible}
                            onDismiss={() => setMenuVisible(false)}
                            anchor={
                                <TouchableOpacity activeOpacity={0.6} onPress={() => setMenuVisible(true)}>
                                    <IonIcons name="grid" size={30} style={[styles.icon, { margin: 10, right: -5, position: 'fixed' }]} />
                                </TouchableOpacity>
                            }
                            contentStyle={styles.menuContent}
                            style={styles.menu}
                        >
                            <Menu.Item titleStyle={{ color: '#000', fontWeight: "900" }} contentStyle={{ color: '#000' }} color={"#000"} rippleColor={'rgba(185,185,185,0.3)'} onPress={() => handleSelect('takeAway')} title="Gyorsnyugta"
                                       leadingIcon="food-takeout-box" />
                            <Menu.Item titleStyle={{ color: '#F1B44C', fontWeight: "900" }} contentStyle={{ color: '#F1B44C' }} color={"#F1B44C"} rippleColor={"rgba(255,215,151,0.3)"} onPress={() => handleSelect('dineIn')} title="Asztaltérkép"
                                       leadingIcon="silverware-fork-knife" />
                            <Menu.Item titleStyle={{ color: '#50A5F1', fontWeight: "900" }} contentStyle={{ color: '#50A5F1' }} color={'#50A5F1'} rippleColor={'rgba(154,203,248,0.3)'} onPress={() => handleSelect('delivery')} title="Házhozszállítás"
                                       leadingIcon="truck-delivery" />
                        </Menu>
                    </View>
                    <ReactNativeZoomableView
                        maxZoom={10}
                        initialZoom={1}
                        minZoom={0.7}
                        contentWidth={1400}
                        contentHeight={1000}
                        panBoundaryPadding={20}
                        bindToBorders={true}
                        zoomAnimatedValue={zoomAnimatedValue}
                        background={true}
                    >
                        {false && <ImageBackground resizeMethod={'auto'} style={styles.contents}
                                                   source={{ uri: 'https://1.bp.blogspot.com/-JKIEUkNZ3NQ/Xdp0zbaStpI/AAAAAAAAMQQ/a5kMu4CVlkYbHgO3AbU9fwEW_JPXRHvhgCEwYBhgL/s1600/tiles_seamless_simple_white_texture.jpg' }}
                                                   resizeMode="repeat">
                            {restaurantPlace?.floors[selectedFloor]?.elements?.map((table, index) => {
                                return (<GenerateElementView element={table} />);
                            })}
                        </ImageBackground>}
                        {restaurantPlace?.floors[selectedFloor]?.elements?.map((table, index) => {
                            return (<GenerateElementView element={table} index={index} />);
                        })}
                    </ReactNativeZoomableView>
                    <View style={styles.zoomControls}>
                        <TouchableOpacity style={[styles.zoomButton, { borderTopLeftRadius: 4, borderTopRightRadius: 4 }]} activeOpacity={0.9} onPress={handleZoomIn}>
                            <Text style={styles.zoomButtonText}>+</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={[styles.zoomButton, { borderBottomLeftRadius: 4, borderBottomRightRadius: 4 }]} activeOpacity={0.9} onPress={handleZoomOut}>
                            <Text style={styles.zoomButtonText}>-</Text>
                        </TouchableOpacity>
                    </View>
                </ImageBackground>
            </View>
        </LinearGradient>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        height: '100%',
    },
    webview: {
        flex: 1,
        width: '100%',
        height: '100%',
    },
    background: {
        flex: 1,
        width: '100%',
        height: '100%',
    },
    contents: {
        flex: 1,
        alignSelf: 'stretch',
        width: '100%',
        height: '100%',
    },
    marker: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        width: 25,
        height: 25,
        marginLeft: -10,
        marginTop: -10,
        backgroundColor: 'white',
        borderWidth: 2,
    },
    zoomControls: {
        position: 'absolute',
        bottom: 30,
        right: 10,
        flexDirection: 'column'
    },
    zoomButton: {
        backgroundColor: 'rgb(253,155,55)',
        padding: 15,
        marginHorizontal: 0,
    },
    zoomButtonText: {
        color: 'white',
        fontSize: 20,
        fontWeight: '900'
    },

    menu: {
        width: 240,
        position: 'absolute',
        top: 0,
        zIndex: 999,
        backgroundColor: '#fff',
    },
    menuContent: {
        paddingVertical: 0,
        zIndex: 999999,
        backgroundColor: '#fff',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.40,
        shadowRadius: 1.41,

        elevation: 2,
    },
    topGradient: {
        position: 'absolute',
        zIndex: 1,
        top: 0,
        left: 0,
        right: 0,
        height: 80, // Transition height
    },
});