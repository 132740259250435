import React, {createContext, useState, useEffect} from 'react';
import {
    PermissionsAndroid,
    Platform,
    Alert,
    View,
    Text,
    TouchableOpacity,
    StyleSheet,
    Modal,
    ToastAndroid
} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {Buffer} from 'buffer';
import Feather from 'react-native-vector-icons/Feather';
import Toast from "react-native-toast-message";
import BluetoothClassic from 'react-native-bluetooth-classic';

export const BluetoothContext = createContext();

export const BluetoothProvider = ({children}) => {
    const [devices, setDevices] = useState([]);
    const [connectedDevice, setConnectedDevice] = useState(null);
    const [isScanning, setIsScanning] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);

    // Request permissions (Android-specific)
    const requestPermissions = async () => {
        if (Platform.OS === 'android') {
            try
            {
                //await BluetoothClassic.requestBluetoothEnabled();
                await PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.BLUETOOTH_CONNECT);
                await PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION);

                return true;
            } catch (error) {
                console.error('Error requesting permissions:', error);
                return false;
            }
        }
    };


    useEffect(() => {
        const initializeDevice = async () => {

            const permissionGranted = await requestPermissions();
            if (!permissionGranted) {
                Alert.alert("Hozzáférés megtagadva!", "Bluetooth engedélyekre van szükség a szoftver integrációhoz.");
            }
            /*

            const storedDevice = await AsyncStorage.getItem('cashierDevice');
            if (storedDevice) {
                try {
                    const storedDeviceData = JSON.parse(storedDevice) || storedDevice;
                    await BluetoothClassic.connectToDevice(storedDeviceData.address);
                    const device = await BluetoothClassic.getConnectedDevice(storedDeviceData.address);
                    setConnectedDevice(device);
                } catch (error) {
                    console.error('Error initializing device:', {device: storedDevice, error: error});
                }
            }
             */
        };
        initializeDevice();
    }, [false]);


    const scanForDevices = async () => {
        if (Platform.OS === 'android') {
            const permissionGranted = await requestPermissions();
            if (!permissionGranted) {
                Alert.alert("Hozzáférés megtagadva!", "Bluetooth engedélyekre van szükség a szoftver integrációhoz.");
                return;
            }

            setIsScanning(true);
            setDevices([]);

            try {
                const availableDevices = await BluetoothClassic.list();
                setDevices(availableDevices);
                setModalVisible(true);
            } catch (error) {
                console.error('Error scanning for Bluetooth devices:', error);
            } finally {
                setIsScanning(false);
            }
        }
    };
    const pairDevice = async (device) => {
        try {
            await BluetoothClassic.connectToDevice(device.address);
            await AsyncStorage.setItem('cashierDevice', JSON.stringify(device));
            await AsyncStorage.setItem("selectedCashierDevice", JSON.stringify(device));
            Toast.show({type: 'success', text1: "Connected to: " + device.name});
            setModalVisible(false);
        } catch (error) {
            console.error("Pairing error:", error);
            Toast.show({type: 'error', text1: "Connection failed: " + device.name, text2: error.message});
        }
    };

    const sendData = async (data) => {

        const storedDevice = await AsyncStorage.getItem('cashierDevice');
        const storedDeviceData = JSON.parse(storedDevice) || storedDevice;

        const deviceAddress = storedDeviceData.address || storedDeviceData.address;

        try {
            const connection = await BluetoothClassic.connectToDevice(storedDeviceData.address);
            await BluetoothClassic.writeToDevice(deviceAddress, data);
        } catch (error) {
            Toast.show({type: 'error', text1: "Hiba a nyugta nyomtatása közben:" + storedDeviceData.name, text2: JSON.stringify(error), visibilityTime: 10000});
        }
    };

    return (
        <BluetoothContext.Provider
            value={{
                devices,
                connectedDevice,
                isScanning,
                scanForDevices,
                sendData
            }}
        >
            {children}
            <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
                onRequestClose={() => setModalVisible(false)}
            >
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <Text style={styles.modalTitle}>Available Devices</Text>
                        {devices.map((device) => (
                            <View key={device.id} style={styles.deviceRow}>
                                <Feather name="printer" size={24} color="#000"/>
                                <View style={styles.deviceInfo}>
                                    <Text style={styles.deviceName}>{device.name}</Text>
                                    <Text style={styles.deviceMac}>{device.id}</Text>
                                </View>
                                <TouchableOpacity style={styles.connectButton} onPress={() => pairDevice(device)}>
                                    <Text style={styles.connectButtonText}>Csatlakozás</Text>
                                </TouchableOpacity>
                            </View>
                        ))}
                        <TouchableOpacity style={styles.closeButton} onPress={() => setModalVisible(false)}>
                            <Text style={styles.closeButtonText}>Bezárás</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </Modal>
        </BluetoothContext.Provider>
    );
};

const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        width: '80%',
        backgroundColor: 'white',
        borderRadius: 10,
        padding: 20,
        alignItems: 'center',
    },
    modalTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 20,
    },
    deviceRow: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
        width: '100%',
    },
    deviceInfo: {
        flex: 1,
        marginLeft: 10,
    },
    deviceName: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    deviceMac: {
        fontSize: 14,
        color: '#666',
    },
    connectButton: {
        backgroundColor: '#2196F3',
        borderRadius: 5,
        padding: 10,
    },
    connectButtonText: {
        color: 'white',
        fontWeight: 'bold',
    },
    closeButton: {
        marginTop: 20,
        backgroundColor: '#f44336',
        borderRadius: 5,
        padding: 10,
    },
    closeButtonText: {
        color: 'white',
        fontWeight: 'bold',
    },
});