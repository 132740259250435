import { combineReducers } from "redux";
import DeviceReducer from "./deviceReducer";
import OrdersReducer from "./ordersReducer";
import UserReducer from "./userReducer";

export default combineReducers({
  user: UserReducer,
  device: DeviceReducer,
  orders: OrdersReducer,
});
