import AsyncStorage from "@react-native-async-storage/async-storage";
import {Alert, Platform} from "react-native";
import axios from 'axios';
const API_URL = "https://api.happygastro.hu/api";
const generateRandomNumberString = (length = 10) => {
    let result = '';
    const characters = '0123456789';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
};

const fetchBarcodeAsBase64 = async (value) => {
    const url = `${API_URL}/tickets/barcode/${value}`;

    try {
        const response = await axios.get(url, { responseType: 'json' });
        const base64Str = response.data.barcode || response.barcode;
        return base64Str;
    } catch (error) {
        console.error('Error fetching barcode:', error);
    }
};

export default function RawBTPrintTicket(items) {
    const printTicket = async () => {

        if (Platform.OS !== 'android') return;

        const {
            BluetoothManager,
            BluetoothEscposPrinter,
            ALIGN
        } = require("tp-react-native-bluetooth-printer");
        // CS50 Printer address: 4B:42:43:53:31:30
        const printerDevice = await AsyncStorage.getItem('printerDevice');

        if (!printerDevice || !JSON.parse(printerDevice).address) return;

        new Promise(() => BluetoothManager.connect(JSON.parse(printerDevice).address || '4B:42:43:53:31:30') // the device address scanned.
            .then(
                async () => {
                    await BluetoothEscposPrinter.printerInit();
                    await BluetoothEscposPrinter.setWidth(384); // Set paper width to 58mm

                    items.forEach((item, index) => {
                        setTimeout(async () => {
                            const itemCode = item.barcode || generateRandomNumberString() || "0987654321"
                            const barcodeData = await fetchBarcodeAsBase64(itemCode);

                            await BluetoothEscposPrinter.printerAlign(ALIGN.LEFT);
                            await BluetoothEscposPrinter.printText(" ", {
                                encoding: "UTF-8",
                                codepage: 0,
                                widthtimes: 1,
                                heigthtimes: 1,
                                fonttype: 2,
                            });
                            await BluetoothEscposPrinter.printerAlign(ALIGN.CENTER);
                            await BluetoothEscposPrinter.setBold(3);
                            await BluetoothEscposPrinter.printText("Múzeum belépő\n", {
                                encoding: "UTF-8",
                                codepage: 0,
                                widthtimes: 1,
                                heigthtimes: 1,
                                fonttype: 2,
                            });
                            await BluetoothEscposPrinter.printText(
                                "---------------------------------------------\n",
                                {}
                            );

                            await BluetoothEscposPrinter.printColumn([14, 18], [ALIGN.LEFT, ALIGN.RIGHT], ["Esemény ideje:", "2024-12-24"], {
                                encoding: "UTF-8",
                                codepage: 0,
                                widthtimes: 0,
                                heigthtimes: 0,
                                fonttype: 1,
                            });
                            await BluetoothEscposPrinter.printColumn([16, 16], [ALIGN.LEFT, ALIGN.RIGHT], ["", "12:00:00"], {
                                encoding: "UTF-8",
                                codepage: 0,
                                widthtimes: 0,
                                heigthtimes: 0,
                                fonttype: 1,
                            });
                            await BluetoothEscposPrinter.printColumn([12, 20], [ALIGN.LEFT, ALIGN.RIGHT], ["Belépőjegy:", item.name || "Teljesáru (1 fő)"], {
                                encoding: "UTF-8",
                                codepage: 0,
                                widthtimes: 0,
                                heigthtimes: 0,
                                fonttype: 1,
                            });
                            await BluetoothEscposPrinter.printColumn([11, 21], [ALIGN.LEFT, ALIGN.RIGHT], ["Sorszáma:", itemCode], {
                                encoding: "UTF-8",
                                codepage: 0,
                                widthtimes: 0,
                                heigthtimes: 0,
                                fonttype: 1,
                            });


                            await BluetoothEscposPrinter.printColumn([10, 22], [ALIGN.LEFT, ALIGN.RIGHT], ["Kiállítva:", new Date().toLocaleDateString('hu-HU')], {
                                encoding: "UTF-8",
                                codepage: 0,
                                widthtimes: 0,
                                heigthtimes: 0,
                                fonttype: 1,
                            });
                            await BluetoothEscposPrinter.printColumn([16, 16], [ALIGN.LEFT, ALIGN.RIGHT], ["", new Date().toLocaleTimeString('hu-HU')], {
                                encoding: "UTF-8",
                                codepage: 0,
                                widthtimes: 0,
                                heigthtimes: 0,
                                fonttype: 1,
                            });

                            await BluetoothEscposPrinter.printColumn([10, 22], [ALIGN.LEFT, ALIGN.RIGHT], ["Érvényes:", new Date("2024-12-24 12:00:00").toLocaleDateString("hu-HU")], {
                                encoding: "UTF-8",
                                codepage: 0,
                                widthtimes: 0,
                                heigthtimes: 0,
                                fonttype: 1,
                            });
                            await BluetoothEscposPrinter.printColumn([20, 12], [ALIGN.LEFT, ALIGN.RIGHT], ["", new Date("2024-12-24 12:00:00").toLocaleTimeString("hu-HU")], {
                                encoding: "UTF-8",
                                codepage: 0,
                                widthtimes: 0,
                                heigthtimes: 0,
                                fonttype: 1,
                            });
                            await BluetoothEscposPrinter.printColumn([23, 9], [ALIGN.LEFT, ALIGN.RIGHT], ["Vásárlás azonosítója:", "#361-732"], {
                                encoding: "UTF-8",
                                codepage: 0,
                                widthtimes: 0,
                                heigthtimes: 0,
                                fonttype: 1,
                            });

                            await BluetoothEscposPrinter.printerAlign(ALIGN.CENTER);
                            await BluetoothEscposPrinter.printText(
                                "---------------------------------------------\n",
                                {
                                    encoding: "UTF-8",
                                    codepage: 0,
                                    widthtimes: 1,
                                    heigthtimes: 1,
                                    fonttype: 0,
                                }
                            );

                            await BluetoothEscposPrinter.printPic(barcodeData, {
                                width: 384,
                                height: 25,
                                left: 0,
                            });


                            await BluetoothEscposPrinter.printerAlign(ALIGN.CENTER);
                            await BluetoothEscposPrinter.printText("Happy Solutions", {
                                encoding: "UTF-8",
                                codepage: 0,
                                widthtimes: 0,
                                heigthtimes: 0,
                                fonttype: 2,
                            });
                            await BluetoothEscposPrinter.printText("www.happysolutions.hu", {
                                encoding: "UTF-8",
                                codepage: 0,
                                widthtimes: 0,
                                heigthtimes: 0,
                                fonttype: 2,
                            });

                            await BluetoothEscposPrinter.printText(
                                "---------------------------------------------\n\n\n\n",
                                {
                                    encoding: "UTF-8",
                                    codepage: 0,
                                    widthtimes: 1,
                                    heigthtimes: 1,
                                    fonttype: 0,
                                }
                            );
                        }, 3000 * index)
                    });
                },
                (e) => {
                    console.error(e);
                    Alert.alert("Hiba a nyomtatás közben:", e);
                }
            ));
    }

    try {
        new Promise(() => printTicket());
    } catch (e) {
        console.error(e);
        Alert.alert("Hiba a nyomtatás közben:", e);
    }
}