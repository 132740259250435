import React, { useContext, useEffect, useState } from 'react';
import {
    Dimensions,
    Linking,
    Platform,
    StyleSheet,
    Switch,
    Text,
    TextInput,
    ToastAndroid,
    TouchableOpacity,
    View
} from 'react-native';
import { LinearGradient } from "expo-linear-gradient";
import Feather from 'react-native-vector-icons/Feather';
import Spinner from "react-native-loading-spinner-overlay";
import { priceFormatter } from "../helpers/numbers";
import VivaPaymentModule from "../paymentModules/VivaPaymentsModule";
import { CartContext } from "../helpers/CartContext";
import { BluetoothContext } from "../helpers/BluetoothContext";
import { OrdersContext } from "../helpers/OrdersContext";
import { handlePayment, send_storeOrder } from "../helpers/paymentHelper";
import Icon from "react-native-vector-icons/Feather";
import VatModal from "../components/VatModal";
import {TableContext} from "../helpers/TableContext";
import { hungarianRoundNumbers } from '../helpers/hungarianRoundNumbers';

const { width, height } = Dimensions.get('window');

const PaymentScreen = ({ navigation }) => {
    const { cartTotal = 0, setCart, cartItems = [], originalCartTotal, productsPrice, discountsForWholeCart, setCartItems, serviceFeeAmount, cartTotalWithServiceFee, alreadySentCartItems } = useContext(CartContext);
    const { currentTable } = useContext(TableContext);
    const { sendData } = useContext(BluetoothContext);

    const [processing, setProcessing] = useState(false);
    const [needInvoice, setNeedInvoice] = useState(false);
    const [paymentType, setPaymentType] = useState(false);
    const [originalAmount, setOriginalAmount] = useState(cartTotal || 0);
    const [amount, setAmount] = useState(Math.round(cartTotal) || 0);
    const [paidAmount, setPaidAmount] = useState(0);
    const [amountToPay, setAmountToPay] = useState(Math.round(originalAmount));
    const [changeToReturn, setChangeToReturn] = useState(0);
    const [modalVisible, setModalVisible] = useState(false);
    const [vatInvoiceData, setVatInvoiceData] = useState(false);
    const {orders} = useContext(OrdersContext);


    useEffect(() => {
        if (currentTable) {
            setOriginalAmount(cartTotalWithServiceFee);
            setAmount(Math.round(cartTotalWithServiceFee));
        }
    }, [currentTable, cartTotalWithServiceFee]);

    useEffect(() => {
        const numericAmount = parseInt(amount) || 0;
        const remainingAmount = Math.max(originalAmount - numericAmount, 0);
        const change = Math.max(numericAmount - originalAmount, 0);

        setPaidAmount(numericAmount);
        if(paymentType === 'Készpénz'){
            setAmountToPay(hungarianRoundNumbers(remainingAmount));
        }else{
            setAmountToPay(Math.round(remainingAmount));
        }

        setChangeToReturn(change);
    }, [amount, originalAmount]);

    const handleSwitchChange = (value) => {
        setNeedInvoice(value);
        if (value) {
            setModalVisible(true);
        }
    };

    const handleModalDismiss = () => {
        setModalVisible(false);
        setNeedInvoice(false);
        setVatInvoiceData(false);
    };
    useEffect(() => {
        if(vatInvoiceData){
            setModalVisible(false)
        }
        console.log(vatInvoiceData)
    },[vatInvoiceData]);

    // useEffect(() => {
    //     const numericAmount = parseInt(amount) || 0;
    //     const remainingAmount = Math.max(originalAmount - numericAmount, 0);
    //     const change = Math.max(numericAmount - originalAmount, 0);
    //
    //     setPaidAmount(numericAmount);
    //     setAmountToPay(remainingAmount);
    //     setChangeToReturn(change);
    // }, [amount]);


    useEffect(() => {
        console.log(processing)
    },[processing])

    const handleInput = (value) => {
        setAmount((prev) => ((prev === originalAmount || prev === 0) ? value : prev + value));
    };

    const handleBackspace = () => {
        setAmount((prev) => (prev.length > 1 ? prev.slice(0, -1) : ''));
    };

    const handleClear = () => {
        setAmount('');
    };

    const handlePaymentTypeChange = (type) => {
        setPaymentType(type);
        console.log("amount",amount)
        console.log("currentTable",currentTable)
        console.log("originalAmount",originalAmount)
        console.log("changeToReturn",changeToReturn)
        if(type == 'Készpénz'){
            setAmount(hungarianRoundNumbers(amount))
        } else if (type === 'Bankkártya') {
            if(cartTotalWithServiceFee && currentTable){
                console.log("cartTotalWithServiceFee",cartTotalWithServiceFee)
                setAmount(Math.round(cartTotalWithServiceFee));
            }else{
                setAmount(Math.round(originalAmount));
            }
        }else{
            setAmount(originalAmount || 0)
        }

    };

    useEffect(() => {
        return () => {
            setProcessing(false);
        }
    }, []);

        useEffect(() => {
            const callback = async (response) => {
                try {
                    if (response.responseData && response.responseData.status === "success" || response.responseData.status === "SUCCESS") {
                        setCart([]);
                        const tableOrder = orders?.find(
                            (order) =>
                                !order.storno &&
                                order.status !== "cancelled" &&
                                order.status !== "closed" &&
                                order.tableId === currentTable?._id
                        );
                        await handlePayment(paymentType, paidAmount, originalAmount, setProcessing, navigation, currentTable && serviceFeeAmount > 0 ? cartTotalWithServiceFee : cartTotal, setCart, cartItems, sendData, onClose, originalCartTotal, productsPrice, discountsForWholeCart, vatInvoiceData, needInvoice, true, currentTable, serviceFeeAmount, alreadySentCartItems, tableOrder?._id)
                        return setProcessing(false);
                    } else {
                        setProcessing(false);
                        return navigation.navigate('FailedPayment', {...response.responseData, orderResponse: null});
                    }
                } catch (e) {
                    setProcessing(false);
                    console.error("Failed to redirect, error: ", e);
                }
            }

            const handleUrl = async ({url}) => {
                const response = VivaPaymentModule.handleCallbackUrl(url);
                return callback(response)
            };

            const subscription = Linking.addEventListener('url', handleUrl);

            return () => {
                subscription.remove();
            };
        }, []);

    return (
        <View style={styles.container}>
            <LinearGradient
                colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']}
                style={{ flex: 1, width: '100%', height: '100%' }}
            >
                <Spinner
                    visible={processing}
                    textContent={'Feldolgozás...'}
                    textStyle={styles.spinnerTextStyle}
                />
                <View style={styles.tabsContainer}>
                    {[
                        { type: 'Készpénz', icon: 'dollar-sign' },
                        { type: 'Bankkártya', icon: 'credit-card' },
                        { type: 'Utalvány', icon: 'tag' },
                    ].map(({ type, icon }) => (
                        <TouchableOpacity
                            key={type}
                            style={[styles.tab, paymentType === type && styles.activeTab]}
                            onPress={() => handlePaymentTypeChange(type)}
                        >
                            <Feather name={icon} size={18} color={paymentType === type ? '#f1b34b' : '#666'} />
                            <Text style={[styles.tabText, paymentType === type && styles.activeTabText]}>{type}</Text>
                        </TouchableOpacity>
                    ))}
                </View>

                    {/* Payment Amount Input */}
                    <View style={styles.amountContainer}>
                        <TextInput
                            style={styles.amountInput}
                            value={amount.toString()}
                            editable={false}
                            keyboardType="numeric"
                            placeholder={'Kapott összeg'}
                            placeholderTextColor={'#AAA'}
                        />
                        <TouchableOpacity onPress={handleBackspace} style={styles.backspaceButton}>
                        <Text style={styles.backspaceText}>⌫</Text>
                    </TouchableOpacity>
                    </View>

                    {/* Info Lines */}
                    {(cartItems.length > 0 || alreadySentCartItems?.length > 0) && <View style={styles.infoContainer}>
                        <View style={styles.infoRow}>
                            <Text style={styles.infoLabel}>Fizetendő:</Text>
                            <Text style={styles.infoValue}>{priceFormatter(originalAmount)}</Text>
                        </View>
                        <View style={[styles.infoRow, {
                            color: Number(amountToPay) > 0 ? "red" : "#333",
                            fontWeight: Number(amountToPay) > 0 ? "900" : "200"
                        }]}>
                            {amountToPay >= 1 && (<><Text style={[styles.infoLabel, {
                                color: Number(amountToPay) > 0 ? "red" : "#333",
                                fontWeight: Number(amountToPay) > 0 ? "900" : "200"
                            }]}>Még fizetendő:</Text>
                                <Text style={[styles.infoValue, {
                                color: Number(amountToPay) > 0 ? "red" : "#333",
                                fontWeight: Number(amountToPay) > 0 ? "900" : "200"
                            }]}>{priceFormatter(amountToPay)}</Text></>)}
                    </View>
                        {Number(changeToReturn) > 0 && <View style={styles.infoRow}>
                            {paymentType === 'Bankkártya'  ?
                                Number(changeToReturn) > 1 && (<>
                                    <Text style={[styles.infoLabel, {fontWeight: Number(changeToReturn) > 1 ? "900" : "200"}]}>
                                        Túlfizetés:
                                    </Text>
                                    <Text style={[styles.infoValue, {fontWeight: Number(changeToReturn) > 1 ? "900" : "200"}]}>
                                        {priceFormatter(changeToReturn)}
                                    </Text>
                                </>)
                                :
                                changeToReturn > 4 && <>
                                    <Text style={[styles.infoLabel, {fontWeight: Number(changeToReturn) > 4 ? "900" : "200"}]}>
                                        Visszajáró:
                                    </Text>
                                    <Text style={[styles.infoValue, {fontWeight: Number(changeToReturn) > 4 ? "900" : "200"}]}>
                                        {priceFormatter(hungarianRoundNumbers(changeToReturn))}
                                    </Text>
                                </>
                            }


                        </View>}
                    </View>}

                <View style={styles.switchContainer}>
                    <Text style={styles.switchLabel}>ÁFÁ-s számla igénylése</Text>
                    <Switch
                        vibrate={true}
                        thumbColor="#f1b34b"
                        trackColor={{ false: "#767577", true: "#ecbb69" }}
                        ios_backgroundColor="#3e3e3e"
                        value={needInvoice}
                        onValueChange={handleSwitchChange}
                    />
                    <VatModal visible={modalVisible} onSubmit={setVatInvoiceData} onDismiss={handleModalDismiss} />
                </View>

                    {/* Pinpad and Process Payment Button Container */}
                    {(cartItems.length > 0 || alreadySentCartItems?.length > 0) && <View style={styles.bottomContainer}>
                        {/* Pinpad */}
                        <View style={styles.pinpadContainer}>
                            {[1, 2, 3, 4, 5, 6, 7, 8, 9, '00', 0, 'X'].map((value, index) => (
                                <TouchableOpacity
                                    key={index}
                                    style={styles.pinpadButton}
                                    onPress={() => (value === 'X' ? handleClear() : handleInput(value.toString()))}
                                >
                                    {value === 'X' ? <Icon name="trash-2" color="black" size={24} /> :  <Text style={styles.pinpadText}>{value}</Text>}
                                </TouchableOpacity>
                            ))}
                        </View>

                        {/* Back and Process Payment Buttons */}
                        {(cartItems.length > 0 || alreadySentCartItems?.length > 0) && <View style={styles.buttonContainer}>
                            <TouchableOpacity style={styles.backButton} onPress={() => {
                                navigation.navigate('HomeScreen');
                            }}>
                                <Feather name="arrow-left" size={24} color="#fff"/>
                            </TouchableOpacity>

                            <TouchableOpacity style={[styles.processButton, {backgroundColor: (amountToPay > 0) ? "#f3a965" : "#ff8000"}]} disabled={(amountToPay > 0)} onPress={async () => {
                                const tableOrder = orders?.find(
                                    (order) =>
                                        !order.storno &&
                                        order.status !== "cancelled" &&
                                        order.status !== "closed" &&
                                        order.tableId === currentTable?._id
                                );
                                return handlePayment(paymentType, paidAmount, originalAmount, setProcessing, navigation, cartTotal, setCart, cartItems, sendData, ()=>{/*onClose*/},originalCartTotal, productsPrice, discountsForWholeCart, vatInvoiceData, needInvoice, false, currentTable, serviceFeeAmount, alreadySentCartItems, tableOrder?._id);
                            }}>
                                {amountToPay > 0 && <Text style={styles.processButtonText}>Még
                                    fizetendő: {priceFormatter(amountToPay)}</Text>}
                                {amountToPay === 0 && <Text style={styles.processButtonText}>Nyugta zárása</Text>}
                                {/*{changeToReturn < 0 && <Text style={styles.processButtonText}>Visszajáró: {priceFormatter(changeToReturn)}</Text>}*/}
                            </TouchableOpacity>
                        </View>}
                    </View>}
                </LinearGradient>
            </View>
        );
    };

        const styles = StyleSheet.create({
            container: {
                flex: 1,
                backgroundColor: '#fff',
                justifyContent: 'space-between',
            },
            tabsContainer: {
                flexDirection: 'row',
                justifyContent: 'space-around',
                padding: 10,
                backgroundColor: '#f1f1f1',
            },
            tab: {
                flex: 1,
                padding: 12,
                alignItems: 'center',
            },
            activeTab: {
                borderBottomWidth: 2,
                borderBottomColor: '#f1b34b',
            },
            tabText: {
                fontSize: 16,
                color: '#666',
                marginTop: 4,
            },
            activeTabText: {
                color: '#f1b34b',
            },
            infoContainer: {
                marginTop: 20,
                padding: 10,
            },
            infoRow: {
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginVertical: 2,
            },
            infoLabel: {
                fontSize: 18,
                color: '#333',
                fontWeight: "500",
            },
            infoValue: {
                fontSize: 18,
                color: '#333',
                fontWeight: 'bold',
            },
            amountContainer: {
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                paddingVertical: 5,
                width: '100%',
            },
            backspaceButton: {
                marginRight: 10,
            },
            backspaceText: {
                fontSize: 26,
                color: '#333',
            },
            amountInput: {
                marginTop: 10,
                fontSize: 32,
                textAlign: 'center',
                color: '#333',
                borderBottomWidth: 1,
                width: width * 0.8,
            },
            clearButton: {
                marginLeft: 10,
            },
            clearText: {
                fontSize: 26,
                color: '#333',
            },
            bottomContainer: {
                flex: 1,
                justifyContent: 'flex-end',
                paddingBottom: 10,
            },
            pinpadContainer: {
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
                width: width,
                paddingHorizontal: 0,
            },
            pinpadButton: {
                width: width / 3.4,
                height: height / 10,
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 5,
                borderColor: '#e8e8e8',
                borderWidth: 0.5
            },
            pinpadText: {
                fontSize: 28,
                color: '#333',
            },
            buttonContainer: {
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingHorizontal: 16,
            },
            backButton: {
                backgroundColor: 'black',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 15,
                marginTop: 20,
                borderRadius: 5,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 0
            },
            processButton: {
                backgroundColor: 'orange',
                alignItems: 'center',
                padding: 15,
                marginTop: 20,
                flex: 1,
                marginLeft: 0,
                borderBottomRightRadius: 5,
                borderTopRightRadius: 5
            },
            processButtonText: {
                fontSize: 20,
                color: '#fff',
                fontWeight: 'bold',
            },
            spinnerTextStyle: {
                color: '#FFF'
            },
            switchContainer: {
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingHorizontal: 16,
                marginVertical: 10,
            },
            switchLabel: {
                fontSize: 16,
                color: '#000',
            },
        });

export default PaymentScreen;