// TablesPendingScreen.js
import React, {useContext} from "react";
import {FlatList, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {LinearGradient} from "expo-linear-gradient";
import {OrdersContext} from "../helpers/OrdersContext";
import {priceFormatter} from "../helpers/numbers";
import TimeAgo from "react-native-timeago";
import StatisticsHelper from "../helpers/StatisticsHelper";
import {Surface} from "react-native-paper";

export default function TablesPendingScreen({navigation}) {
    const {orders} = useContext(OrdersContext);
    const {productsSum, statistics} = StatisticsHelper(orders);

    return (
        <LinearGradient
            colors={['#f2e2d2', '#e6d6c1', '#dcd5ce']}
            style={styles.background}
        >
            <View style={styles.container}>
                {/* Invoice List */}
                    <FlatList
                        data={[
                            ...statistics.orders.filter(order => ((order.status !== "closed" || order.status !== "cancelled"))).map(order => ({
                                id: '#' + order.orderCode,
                                amount: priceFormatter(order.orderSum),
                                when: order.createdAt,
                                orderData: order
                            })),
                        ]}
                        renderItem={({item}) => (
                            <TouchableOpacity style={[styles.row, {marginVertical: 10}]}
                                              onPress={() => navigation.navigate('OrderDetailsScreen', {orderData: item.orderData})}>
                                <Surface style={styles.surface} elevation={4}>
                                <Text style={{fontWeight: "700", flex: 1}}><TimeAgo time={new Date(item.when)}
                                                                                    interval={10}/></Text>
                                <Text style={{fontWeight: "700", flex: 1, textAlign: 'right'}}>{item.id}</Text>
                                <Text
                                    style={{fontWeight: "700", flex: 1, textAlign: 'right'}}>{item.amount}</Text>
                                </Surface>
                            </TouchableOpacity>
                        )}
                        keyExtractor={(item) => item.id}
                    />
            </View>
        </LinearGradient>
    );
}

const styles = StyleSheet.create({
    surface: {
        padding: 8,
        height: 60,
        width: '95%',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 15,
        paddingRight: 15,
        flexDirection: 'row',
        marginHorizontal: 15,
        alignSelf: 'center',
    },
    container: {
        flex: 1,
        width: '100%',
        height: '100%',
        paddingTop: 16
    },
    webview: {
        flex: 1,
        width: '100%',
        height: '100%',
    },
    background: {
        flex: 1,
    },
    section: {
        marginBottom: 24,
        padding: 16,
        backgroundColor: '#f9f9f9',
        borderRadius: 8,
        shadowColor: '#000',
        shadowOpacity: 0.1,
        shadowRadius: 4,
        shadowOffset: {width: 0, height: 2},
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 8,
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 8,
    },
});
