import React, { useEffect, useState, useRef } from 'react';
import {View, StyleSheet, Dimensions, Animated, Text, Platform, TouchableOpacity} from 'react-native';
import LottieView from 'lottie-react-native';

const SuccessEntryScreen = ({ navigation, route }) => {
    const [animationFinished, setAnimationFinished] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState('#81F174');
    const animation = useRef(null);

    const handleAnimationFinish = async () => {
        setBackgroundColor('#81F174');
        setAnimationFinished(true)
    };
    const { ticketCode, reason = "A Jegy érvénytelen vagy már fel lett használva." } = route.params;

    return (
        <View style={[styles.container, { backgroundColor : '#81F174' }]}>
            {animationFinished && (
                <Animated.View style={[styles.logoContainer, { opacity: 1 }]}>
                    <LottieView
                        source={require('../../assets/animations/success_center.json')} // Path to your Lottie animation file
                        autoPlay
                        loop={true}
                        style={styles.lottieCenter}
                    />
                    <Text style={styles.logo}>Sikeres jegyérvényesítés!</Text>
                    <Text style={[styles.logo, {fontSize: 15, textAlign: 'center'}]}>A {ticketCode} számú jegy érvényesítése megtörtént!</Text>

                    <TouchableOpacity style={styles.button}
                                      onPress={() => navigation.navigate("TicketEntry")}>
                        <Text style={styles.buttonText}>Visszalépés</Text>
                    </TouchableOpacity>
                </Animated.View>
            )}
            {!animationFinished && <LottieView
                ref={animation}
                source={require('../../assets/animations/success_animation.json')} // Path to your Lottie animation file
                autoPlay
                loop={false}
                speed={2}
                onAnimationFinish={handleAnimationFinish}
                resizeMode={'contain'}
                renderMode={'HARDWARE'}
                style={[styles.lottie, {width: Dimensions.get('screen').width, height: Dimensions.get('screen').height}]}
            />}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#81F174',
    },
    lottie: {
        width: Dimensions.get('screen').width,
        height: Dimensions.get('screen').height,
    },
    lottieCenter: {
        width: Dimensions.get('screen').width - 100,
        height: Dimensions.get('screen').width - 100,
    },
    logoContainer: {
        position: 'absolute',
        alignItems: 'center',
        zIndex: 99999999
    },
    logo: {
        fontSize: 25,
        fontWeight: 'bold',
        color: '#FFF',
    },
    text: {
        fontSize: 25,
        fontWeight: 'bold',
        color: 'black',
        marginTop: 10,
    },
    button: {
        backgroundColor: "#F1B34B",
        paddingVertical: 15,
        paddingHorizontal: 30,
        borderRadius: 5,
        marginTop: 40
    },
    buttonText: {
        fontSize: 24,
        color: "white",
        paddingHorizontal: 20,
        textAlign: 'center',
    },
});

export default SuccessEntryScreen;