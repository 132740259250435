export const hungarianRoundNumbers = (price = 0) => {;
    const priceTags = (price / 10).toFixed(1).toString().split('.');
    let firstTag = parseInt(priceTags[0]);
    let lastTag = parseInt(priceTags[1]);
    if (lastTag === 5 || lastTag === 0) {
        return price;
    } else {
        if (lastTag === 3 || lastTag === 4 || lastTag === 6 || lastTag === 7) {
            lastTag = 5;
        } else if (lastTag === 8 || lastTag === 9) {
            lastTag = 0;
            firstTag = firstTag + 1;
        } else {
            lastTag = 0;
        }
        return parseInt(firstTag.toString() + lastTag + toString());
    }
}