import React, { createContext, useState } from 'react';

// Create a Context for the table
export const TableContext = createContext();

// Create a provider component
export const TableProvider = ({ children }) => {
    const [currentTable, setCurrentTable] = useState(null);

    const selectTable = (table) => {
        setCurrentTable(table);
    };

    const removeTable = () => {
        setCurrentTable(null);
    };

    return (
        <TableContext.Provider value={{ currentTable, selectTable, removeTable }}>
            {children}
        </TableContext.Provider>
    );
};