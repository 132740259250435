import {
  SET_DISCOUNT,
  SET_ITEM,
  SET_ITEMS,
  SET_ORDERS,
  SET_SERVICEFEE,
  SET_TABLE,
  UPDATE_ITEMS,
} from "../actions/keys";
import DefaultState from "./default";

const ObjectId = (rnd = (r16) => Math.floor(r16).toString(16)) =>
  rnd(Date.now() / 1000) +
  " ".repeat(16).replace(/./g, () => rnd(Math.random() * 16));

const INIT_STATE = DefaultState.orders;

const OrdersReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ITEM:
      return {
        ...state,
        cartItems: [
          ...state.cartItems,
          {
            _id: ObjectId(),
            item: action.cartItem,
            size: action.size,
            extras: action.extras,
            amount: action.amount,
          },
        ].filter((c) => c.amount > 0),
      };
    case SET_ITEMS:
      return { ...state, cartItems: [...action.cartItem] };
    case SET_TABLE:
      return {
        ...state,
        selectedTable: action.table || undefined,
        serviceFee: action.serviceFee,
        order: action.order || {},
      };
    case SET_SERVICEFEE:
      return { ...state, serviceFee: action.serviceFee };
    case SET_DISCOUNT:
      return {
        ...state,
        discounts: { percentage: action.discount, amount: 0 },
      };
    case UPDATE_ITEMS:
      let items = [...state.cartItems];

      const index = items.findIndex((i) => i._id === action.cartItem._id);

      if (action.updates.amount === 0) {
        items.splice(index, 1);
        return { ...state, cartItems: items };
      }

      if (items[index]) {
        items[index] = {
          ...items[index],
          ...action.updates,
        };
        return { ...state, cartItems: items };
      }

      return { ...state, cartItems: items };
    case SET_ORDERS:
      return { ...state, orders: [...action.orders] };
    default:
      return state;
  }
};

export default OrdersReducer;
