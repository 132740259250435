export function priceFormatter(value, decimals = 0, withoutCurrency = true) {
    return value.toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + (withoutCurrency ? ' Ft' : '');
    /*
    const formatter = new Intl.NumberFormat('hu-HU', {
        style: 'currency',
        type: 'integer',
        currency: 'HUF',
        minimumFractionDigits: 0,
        maximumFractionDigits: decimals
    });
    return formatter.format(value);

     */
}