import React, {useContext, useEffect, useState} from 'react';
import {Modal, ScrollView, StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native';
import {priceFormatter} from '../helpers/numbers';
import {CartContext} from "../helpers/CartContext";
import Icon from 'react-native-vector-icons/Feather';

const DiscountModal = ({ visible, onClose, totalPrice, itemPrice, selectedCartItemID, openFrom }) => {
    const { addDiscount, productsPrice, cartItems, setCartItems, discountsForWholeCart, setDiscountsForWholeCart } = useContext(CartContext);
    const [discountAmount, setDiscountAmount] = useState('');
    const [discountPercentage, setDiscountPercentage] = useState('');

    const handleDiscountAmountChange = (value) => {
        setDiscountAmount(value);
        setDiscountPercentage('');
    };

    const handleDiscountPercentageChange = (value) => {
        setDiscountPercentage(value);
        setDiscountAmount('');
    };

    const handleSaveDiscount = () => {
        if (discountAmount && openFrom) {
            addDiscount(selectedCartItemID, { amount: discountAmount, percentage: 0 }, openFrom);
        } else if (discountPercentage && openFrom) {
            addDiscount(selectedCartItemID, { amount: 0, percentage: discountPercentage }, openFrom);
        }
        setDiscountPercentage("");
        setDiscountAmount("");
        onClose();
    };

    const handleDeleteDiscount = () => {
        if(openFrom === "cartTotal") {
            setDiscountsForWholeCart({ amount: 0, percentage: 0 });
        }
        const updatedCartItems = cartItems.map(item => {
            if (item._id === selectedCartItemID) {
                item.discounts = undefined;
                item.remotePrice = item.price * item.quantity;
            }
            return item;
        });
        setCartItems(updatedCartItems);
    };

    const selectedItem = cartItems.find(item => item._id === selectedCartItemID);
    const discounts = selectedItem && selectedItem?.discounts;

    return (
        <Modal
            animationType="slide"
            transparent={true}
            visible={visible}
            onRequestClose={onClose}
        >
            <View style={styles.modalView}>
                <Text style={styles.modalTitle}>Kedvezmény</Text>
                <Text style={styles.originalAmount}>Eredeti összeg: {priceFormatter(itemPrice || productsPrice)}</Text>

                {discounts && (<><Text style={styles.originalAmount}>Leértékelt ár: {priceFormatter((itemPrice || productsPrice) - (discounts?.amount || 0) - ((itemPrice || productsPrice) * (discounts?.percentage || 0)/100))}</Text>
                        <View style={styles.discountsContainer}>
                            {openFrom === "cartItem" ? (
                                <>
                                    <Text style={styles.discountsTitle}>Jelenlegi kedvezmény:</Text>
                                    <ScrollView style={styles.scrollView}>
                                        <View  style={styles.discountRow}>
                                            <Text style={styles.discountText}>
                                                {discounts.amount > 0 ? `${priceFormatter(discounts?.amount)}` : `${discounts?.percentage}%`}
                                            </Text>
                                            <TouchableOpacity onPress={() => handleDeleteDiscount()} style={styles.deleteButton}>
                                                <Icon name="x" size={20} color="red" />
                                            </TouchableOpacity>
                                        </View>
                                    </ScrollView>
                                </>
                            ) : <></>}
                        </View> </>
                )}
                {(openFrom === "cartTotal" && (discountsForWholeCart?.amount > 0 || discountsForWholeCart?.percentage > 0)) && (<><Text style={styles.discountsTitle}>Egész kosár kedvezménye:
                    {discountsForWholeCart.amount > 0 ? `${priceFormatter(discountsForWholeCart?.amount)}` : `${discountsForWholeCart?.percentage}%`}
                </Text> <TouchableOpacity onPress={() => handleDeleteDiscount()} style={styles.deleteButton}>
                    <Icon name="x" size={20} color="red" />
                </TouchableOpacity></>)}
                <TextInput
                    style={styles.textInput}
                    placeholder="Kedvezmény összege"
                    value={discountAmount}
                    onChangeText={handleDiscountAmountChange}
                    keyboardType="numeric"
                />
                <TextInput
                    style={styles.textInput}
                    placeholder="Kedvezmény százalékban"
                    value={discountPercentage}
                    onChangeText={handleDiscountPercentageChange}
                    keyboardType="numeric"
                />
                <View style={styles.buttonContainer}>
                    <TouchableOpacity style={[styles.button, styles.closeButton]} onPress={() => { setDiscountPercentage(""); setDiscountAmount(""); onClose() }}>
                        <Text style={styles.buttonText}>Bezárás</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={[styles.button, styles.saveButton]} onPress={handleSaveDiscount}>
                        <Text style={styles.buttonText}>Mentés</Text>
                    </TouchableOpacity>

                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modalView: {
        margin: 20,
        backgroundColor: '#fff',
        borderRadius: 20,
        paddingTop: 8,
        paddingHorizontal: 30,
        paddingBottom: 10,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        maxHeight: '80%', // Set the maximum height to 80% of the screen height
    },
    modalTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 15,
    },
    originalAmount: {
        fontSize: 16,
        marginBottom: 20,
    },
    discountsContainer: {
        marginBottom: 20,
        maxHeight: '30%', // Set the maximum height to 50% of the screen height
    },
    scrollView: {
        maxHeight: '100%', // Ensure the scroll view takes the full height of the container
    },
    discountsTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 5,
    },
    discountRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingVertical: 10,
    },
    discountText: {
        fontSize: 14,
        color: '#888',
    },
    deleteButton: {
        padding: 10,
    },
    textInput: {
        height: 40,
        borderColor: 'grey',
        borderWidth: 1,
        borderRadius: 4,
        marginBottom: 20,
        width: '100%',
        paddingHorizontal: 10,
    },
    buttonContainer: {
        flexDirection: 'row',
        width: '100%',
        marginBottom: 20,
    },
    button: {
        flex: 1,
        padding: 18,
        alignItems: 'center',
        borderRadius: 4,
    },
    closeButton: {
        backgroundColor: 'grey',
        marginRight: 10,
    },
    saveButton: {
        backgroundColor: '#F76E24',

    },
    buttonText: {
        color: 'white',
        fontSize: 16,
    },
});

export default DiscountModal;