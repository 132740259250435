import React, {useEffect, useState} from 'react';
import {
    Button,
    Dimensions,
    FlatList,
    Platform,
    SafeAreaView,
    ScrollView,
    StatusBar,
    StyleSheet,
    Text,
    TextInput,
    TouchableHighlight,
    View,
} from 'react-native';
import {LinearGradient} from "expo-linear-gradient";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {COMMANDS, NetPrinter} from "react-native-thermal-receipt-printer-image-qr";
import ThemeColor from "../Theme/Colors";
import LottieView from "lottie-react-native";
import HappyComponent from "../helpers/crossPlatformTools/HappyComponent";
import DropDownPicker from "react-native-dropdown-picker";
import ThermalPrinterModule from "react-native-thermal-printer";
import {getPrinterTestData, sendPrinterAndroid} from "../helpers/printerLibrary/printer";
import loadingAnimation from "../../assets/animations/search-global.json";
import * as Network from "expo-network";
import {connect, useDispatch} from "react-redux";
import {setPrinterEvents, setPrinters} from "../store/actions/terminal";

const {width, height} = Dimensions.get('screen');

const ipSearchArray = [{label: "192.168.0.1 - 254", value: "192.168.0"}, {
    label: "192.168.1.1 - 254",
    value: "192.168.1"
}, {label: "192.168.2.1 - 254", value: "192.168.2"}, {
    label: "192.168.3.1 - 254",
    value: "192.168.3"
}, {label: "192.168.4.1 - 254", value: "192.168.4"}, {
    label: "192.168.5.1 - 254",
    value: "192.168.5"
}, {label: "192.168.6.1 - 254", value: "192.168.6"}, {
    label: "192.168.7.1 - 254",
    value: "192.168.7"
}, {label: "192.168.8.1 - 254", value: "192.168.8"}, {
    label: "192.168.9.1 - 254",
    value: "192.168.9"
}, {label: "100.64.0.0 - 254", value: "100.64.0"}, {label: "172.16.0.0 - 254", value: "172.16.0"},];

let foundPrinters = [];
let searchTimeout = null;

function SettingsPrinterScreen(props) {
    const [selectedIPRange, setSelectedIPRange] = useState('192.168.0');
    const [port, setPort] = useState('9100');
    const [stopSearch, setStopSearch] = useState(true);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState('');
    const [manualSetupIP, setManualSetupIP] = useState('');
    const [manualSetupPort, setManualSetupPort] = useState('9100');
    const [manualSetupName, setManualSetupName] = useState('Nyomtató ');
    const [manualSettings, setManualSettings] = useState(false);
    const [open, setOpen] = useState(false);
    const [networkPrinters, setNetworkPrinters] = useState([]);
    const [blePrinters, setBlePrinters] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        const loadSettings = async () => {
            const storedIPRange = await AsyncStorage.getItem('selectedIPRange');
            const storedPort = await AsyncStorage.getItem('port');
            if (storedIPRange) setSelectedIPRange(storedIPRange);
            if (storedPort) setPort(storedPort);
        };
        loadSettings();
    }, []);

    const handleIPRangeChange = async (range) => {
        setSelectedIPRange(range);
        setValue(range);
        await AsyncStorage.setItem('selectedIPRange', range);
    };

    React.useEffect(() => {
        const getCurrentIPAddress = async () => {
            try {
                const ipAddress = await Network.getIpAddressAsync(); // Assuming you have a method to get the current IP address
                const ipRange = ipAddress.split('.').slice(0, 3).join('.');
                handleIPRangeChange(ipRange);
            } catch (error) {
                console.error('Failed to get IP address:', error);
            }
        };

        getCurrentIPAddress();
    }, []);

    const handlePortChange = async (port) => {
        setPort(port);
        await AsyncStorage.setItem('port', port);
    };



    const BACKGROUND_FETCH_TASK = 'search-for-network-printers';

    const _startSearch = async () => {
        ThermalPrinterModule.getBluetoothDeviceList().then(setBlePrinters);
        const mapNetworkiOS = (cb = false) => {
            return Promise.all([
                ...Array.from(Array(255).keys()).map((ipv4Ending, index) => {
                    return new Promise((resolve, reject) => {
                        return setTimeout(async () => {
                            try {
                                await NetPrinter.connectPrinter(
                                    `${value}.${ipv4Ending}`,
                                    Number(port || 9100),
                                    150
                                );
                                NetPrinter.printText(
                                    `${COMMANDS.TEXT_FORMAT.TXT_ALIGN_CT}${
                                        COMMANDS.TEXT_FORMAT.TXT_BOLD_ON
                                    }${COMMANDS.TEXT_FORMAT.TXT_CUSTOM_SIZE(
                                        2,
                                        2
                                    )}IP: ${value}.${ipv4Ending}${
                                        COMMANDS.TEXT_FORMAT.TXT_BOLD_OFF
                                    }`
                                );
                                NetPrinter.printText(
                                    `${COMMANDS.TEXT_FORMAT.TXT_ALIGN_CT}${
                                        COMMANDS.TEXT_FORMAT.TXT_BOLD_ON
                                    }${COMMANDS.TEXT_FORMAT.TXT_CUSTOM_SIZE(2, 2)}PORT: ${port}${
                                        COMMANDS.TEXT_FORMAT.TXT_BOLD_OFF
                                    }`
                                );
                                NetPrinter.printBill(``, {beep: true});

                                return resolve({
                                    ip: value + "." + ipv4Ending,
                                    port: port,
                                    reachable: true,
                                });
                            } catch (e) {
                                return reject(false);
                            }
                        }, index * 150);
                    }).then((data) => {
                        console.log(JSON.stringify(data));
                        //setNetworkPrinters(data.filter((e) => e !== false));
                        //props.setPrinters([...data.filter((e) => e !== false)]);
                    });
                }),
            ])
                .then((foundPrinters) => {
                    props.setPrinters([...foundPrinters.filter((e) => e !== false), ...blePrinters]);
                    setLoading(false)
                })
                .catch((e) => {
                    setLoading(false)
                    console.error(e);
                });
        };
        const mapNetWorkAndroid = async (cb = false) => {

            try {
                try {
                    const r_1 = await Promise.all([
                        ...Array.from(Array(255).keys()).map((ipv4Ending) => {
                            return new Promise(async (resolve, reject) => {
                                console.log({
                                    ip: value + "." + ipv4Ending,
                                    port: 9100,
                                    reachable: true,
                                });

                                const printer = await sendPrinterAndroid(
                                    getPrinterTestData({
                                        ip: value + "." + ipv4Ending,
                                        port: 9100,
                                        reachable: true,
                                    }),
                                    {
                                        ip: value + "." + ipv4Ending,
                                        port: Number(port),
                                        timeout: 150,
                                        autoCut: true,
                                        mmFeedPaper: 170,
                                        printerDpi: 203,
                                        printerWidthMM: 58,
                                        printerNbrCharactersPerLine: 32,
                                    },
                                    false,
                                    false
                                );

                                if (printer && !printer.error) {
                                    console.log(
                                        {ip: value + "." + ipv4Ending, port: port, reachable: true},
                                        printer
                                    );

                                    foundPrinters.push({
                                        name: 'Névtelen',
                                        deviceName: 'Névtelen',
                                        ip: value + "." + ipv4Ending,
                                        port: port,
                                        reachable: true,
                                    });
                                    return resolve({
                                        name: 'Névtelen',
                                        deviceName: 'Névtelen',
                                        ip: value + "." + ipv4Ending,
                                        port: port,
                                        reachable: true,
                                    });
                                }
                                return resolve(false);
                            })
                                .then((r) => {
                                    return r;
                                })
                                .catch((e) => {
                                    return false;
                                });
                        }),
                    ]);
                    props.setPrintersRedux(r_1.filter((e_2) => e_2 !== false));
                    setNetworkPrinters(r_1.filter((e_1) => e_1 !== false));
                    return r_1.filter((e_3) => e_3 !== false);
                } catch (e_4) {
                    console.error(e_4);
                }
            } finally {
                setLoading(false);
            }
        };
        if (Platform.OS === "ios") mapNetworkiOS();
        if (Platform.OS === "android") mapNetWorkAndroid();
    };

    // Call this function to start the background task

    const stopBackgroundSearch = () => {
        setLoading(false);
        clearInterval(searchTimeout);
        searchTimeout = null;
    };

    const startBackgroundSearch = () => {
        setLoading(true);
        searchTimeout = setTimeout(async () => new Promise(() => _startSearch()), 1300);
        setTimeout(() => stopBackgroundSearch(), 90 * 1000); // Stop search after 90 seconds
    };


    if (loading) {
        return (
            <LinearGradient
                colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']}
                style={{ flex: 1, width: '100%', height: '100%' }}
            >
                <ScrollView contentContainerStyle={styles.content}>
                    <SafeAreaView style={{flex: 1, backgroundColor: 'transparent', paddingTop: 10}}>
                        <StatusBar backgroundColor="transparent" translucent barStyle="dark-content"/>

                        <View
                            style={{
                                flex: 1,
                                flexGrow: 1,
                                justifyContent: "center",
                                width: "100%",
                                height: "100%",
                                alignItems: 'normal'
                            }}
                        >
                            <LottieView
                                source={loadingAnimation}
                                autoPlay
                                loop={true}
                                style={styles.lottie}
                                renderMode={'AUTOMATIC'}
                                resizeMode={'center'}
                                enableSafeModeAndroid={true}
                            />
                            <View style={{flex: 2, width: "100%"}}>
                                <Text
                                    style={{
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        fontSize: 25
                                    }}
                                >
                                    Nyomtatók felderítése...
                                </Text>
                                <Text
                                    style={{
                                        textAlign: "center",
                                        marginHorizontal: 60
                                    }}
                                >
                                    Ez akár néhány percet is igénybe vehet, minden észlelt nyomtatóra
                                    kinyomtatjuk az adatait!
                                </Text>
                            </View>
                        </View>
                    </SafeAreaView>
                </ScrollView>
            </LinearGradient>
        );
    }

    return (
        <LinearGradient
            colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']}
            style={{ flex: 1, width: '100%', height: '100%' }}
        >
            <SafeAreaView style={{
                flex: 1, backgroundColor: 'transparent', paddingTop: 15,
                paddingLeft: 10,
                alignItems: "center",
                justifyItems: "center", width: '100%', height: '100%'
            }}>
                <StatusBar backgroundColor="transparent" translucent barStyle="dark-content"/>

                <HappyComponent
                    visible={manualSettings}
                    onConfirm={() => {
                        setNetworkPrinters({
                            ip: manualSetupIP,
                            port: manualSetupPort,
                        });
                        props.setPrinters([
                            ...props.printers,
                            {
                                ip: manualSetupIP,
                                port: manualSetupPort,
                                manual: true,
                            },
                        ]);
                        setNetworkPrinters([
                            {
                                ip: manualSetupIP,
                                port: manualSetupPort,
                                manual: true,
                            },
                            ...props.printers,
                        ]);
                        setManualSettings(false);
                        setManualSetupIP(ipAddress);
                        setManualSetupPort("9100");
                    }}
                    onCancel={() => setManualSettings(false)}
                    title="Kezi nyomtato beallitasa"
                    message="Elobb a nzomtato IP cimet majd PORT szamat add meg!"
                    confirmText="Mentés"
                    cancelText="Mégse"
                >
                    <TextInput
                        value={manualSetupName}
                        onChangeText={setManualSetupName}
                        autoFocus
                        placeholder="Pl. 192.168.1.129"
                    />
                    <TextInput
                        value={manualSetupIP}
                        onChangeText={setManualSetupIP}
                        autoFocus
                        placeholder="Pl. 192.168.1.129"
                    />
                    <TextInput
                        value={manualSetupPort}
                        onChangeText={setManualSetupPort}
                        keyboardType="numeric"
                        autoFocus
                        placeholder="Pl. 9100"
                    />
                </HappyComponent>
                <ScrollView style={{flex: 1}}>
                    <View style={{flex: 4, width: "100%", marginTop: 10}}>
                        <Text
                            style={{
                                fontWeight: "bold",
                                textAlign: "left",
                                fontSize: 17,
                            }}
                        >
                            Válaszd ki a kívánt tartományokat, majd a portot,
                        </Text>
                        <Text style={{textAlign: "left", borderRadius: 10}}>
                            és rendszerünk autómatikusan feltérképezi a hálózaton található
                            használható nyomtatókat.
                        </Text>
                        <Text style={{textAlign: "left", marginTop: 15, borderRadius: 10}}>
                            Ezek után nincs más teendője, mint nyomtatási profilhoz rendelnie azt!
                        </Text>
                    </View>
                    <View
                        style={[
                            {
                                height: 100,
                                width: "90%",
                                borderRadius: 15,
                                padding: 10,
                                shadowOffset: {width: 0, height: 3},
                                shadowOpacity: 0.5,
                                shadowRadius: 5,
                                marginBottom: 30,
                                marginTop: 30,
                            },
                            {marginTop: 0, flexDirection: "row"},
                        ]}
                    >
                        <View
                            style={{
                                flexDirection: "column",
                                flex: 3,
                                width: 170,
                                borderColor: ThemeColor.gray6,
                                alignItems: 'center',
                                zIndex: 99999,
                            }}
                        >
                            <Text>Hálózati tartomány:</Text>
                            <DropDownPicker
                                open={open}
                                value={value}
                                placeholder={"Válassz egy tartományt"}
                                theme={"LIGHT"}
                                items={[
                                    ...ipSearchArray,
                                    {label: "Egyedi halozat megadasa", value: "unique"},
                                ]}
                                setOpen={setOpen}
                                setValue={(v) => {
                                    if (v === "unique") {
                                        alert("Egyedi IP tartomany ablak");
                                    } else {
                                        setValue(v);
                                    }
                                }}
                                setItems={(s) => console.log(s)}
                                listMode={"MODAL"}
                                style={{
                                    width: 210,
                                    backgroundColor: "white",
                                }}
                                dropDownContainerStyle={{
                                    backgroundColor: "white",
                                }}
                            />
                        </View>
                        <View style={{flexDirection: "column", flex: 2, alignItems: 'center', justifyContent: 'flex-start'}}>
                            <Text>Port:</Text>
                            <TextInput
                                numberOfLines={1}
                                keyboardType={"decimal-pad"}
                                onChangeText={(e) => {
                                    setPort(Number(e));
                                }}
                                style={{
                                    width: "80%",
                                    borderBottomColor: ThemeColor.gray,
                                    borderBottomWidth: 1,

                                }}
                                value={port}
                                defaultValue={port}
                            />
                        </View>
                    </View>
                    <View style={{flex: 2, width: "95%", marginLeft: 5}}>
                        <View style={{marginBottom: 30, marginTop: 30, height: 60}}>
                            <Button
                                title="Keresés a kijelölt hálózaton"
                                color={ThemeColor.orange}
                                style={{
                                    width: "100%",
                                    paddingHorizontal: 35,
                                    height: 70,
                                    paddingVertical: 15,
                                    borderRadius: 10,
                                }}
                                onPress={() => {
                                    //Alert.alert('Àtmenetileg tiltva!', 'Ez a funkció nem használható, kérlek kézzel adj hozzá nyomtatókat!')
                                    setLoading(true);
                                    startBackgroundSearch();
                                    //new Promise(() => setTimeout(() => _startSearch(), 900));
                                }}
                            />
                        </View>
                        <View style={{marginTop: -20}}>
                            <Button
                                title="Kézi beállítás"
                                color={ThemeColor.orangeHighlight}
                                style={{
                                    width: "100%",
                                    paddingHorizontal: 35,
                                    height: 60,
                                    paddingVertical: 15,
                                    borderRadius: 10
                                }}
                                onPress={() => setManualSettings(true)}
                            />
                        </View>
                    </View>
                    <View style={{flex: 4, width: "90%", marginLeft: 15}}>
                        <ScrollView>
                            {[...blePrinters, ...foundPrinters].length >= 1 && (
                                <Text
                                    style={{
                                        marginTop: 30,
                                        fontWeight: "bold",
                                        textAlign: "left",
                                        fontSize: 18,

                                    }}
                                >
                                    Talált eszközök:
                                </Text>
                            )}
                            {[...blePrinters, ...foundPrinters].length >= 1 && (
                                <View
                                    style={{
                                        width: "100%",
                                        borderColor: ThemeColor.gray4,
                                        borderBottomWidth: 1,
                                        flexDirection: "row",
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                    }}
                                >
                                    <Text
                                        style={{flex: 1, fontWeight: "bold"}}
                                    >
                                        IP / MAC cím:
                                    </Text>
                                    <Text
                                        style={{
                                            flex: 1,
                                            textAlign: "right",
                                            fontWeight: "bold",

                                        }}
                                    >
                                        Megnevezés / Port
                                    </Text>
                                </View>
                            )}
                            {[...blePrinters, ...foundPrinters].length >= 1 && (
                                <FlatList
                                    style={{marginTop: 0}}
                                    data={[...foundPrinters, ...blePrinters]}
                                    renderItem={(dp) => {
                                        const {item, index} = dp;
                                        return (
                                            <TouchableHighlight
                                                activeOpacity={1}
                                            >
                                                {item.macAddress ? (
                                                    <View
                                                        key={item.macAddress}
                                                        style={{
                                                            width: "100%",
                                                            borderColor: ThemeColor.gray,
                                                            borderBottomWidth: 0.5,
                                                            flexDirection: "row",
                                                            paddingTop: 10,
                                                            paddingBottom: 10,

                                                        }}
                                                    >
                                                        <Text style={{flex: 1}}>
                                                            {item.macAddress}
                                                        </Text>
                                                        <Text
                                                            style={{
                                                                flex: 1,
                                                                textAlign: "right",

                                                            }}
                                                        >
                                                            {item.deviceName}
                                                        </Text>
                                                    </View>
                                                ) : (
                                                    <View
                                                        key={item.ip}
                                                        style={{
                                                            width: "100%",
                                                            borderColor: ThemeColor.gray,
                                                            borderBottomWidth: 0.5,
                                                            flexDirection: "row",
                                                            paddingTop: 10,
                                                            paddingBottom: 10,
                                                        }}
                                                    >
                                                        <Text style={{flex: 1}}>
                                                            {item.ip}
                                                        </Text>
                                                        <Text
                                                            style={{
                                                                flex: 1,
                                                                textAlign: "right",

                                                            }}
                                                        >
                                                            {item.port}
                                                        </Text>
                                                    </View>
                                                )}
                                            </TouchableHighlight>
                                        );
                                    }}
                                />
                            )}
                        </ScrollView>
                    </View>
                </ScrollView>
            </SafeAreaView>
        </LinearGradient>
    );

}

const styles = StyleSheet.create({
    lottie: {
        width: Dimensions.get("screen").width - 32,
        height: Dimensions.get("screen").width -32,
    },
    content: {
        paddingHorizontal: 16, backgroundColor: 'transparent'
    }, section: {
        paddingVertical: 12,
    }, sectionTitle: {
        margin: 8,
        marginLeft: 12,
        fontSize: 13,
        letterSpacing: 0.33,
        fontWeight: '500',
        color: '#a69f9f',
        textTransform: 'uppercase',
    }, sectionBody: {
        borderRadius: 12, shadowColor: '#000', shadowOffset: {
            width: 0, height: 2,
        }, shadowOpacity: 0.5, shadowRadius: 1.41, elevation: 3,
    }, row: {
        height: 44,
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingRight: 12,
        paddingLeft: 16,
        backgroundColor: '#fff',
        borderTopWidth: 1,
        borderColor: '#f0f0f0',
    }, rowLabel: {
        fontSize: 16, letterSpacing: 0.24, color: '#000',
    }, rowSpacer: {
        flexGrow: 1, flexShrink: 1, flexBasis: 0,
    }, textInput: {
        height: 40, borderColor: 'gray', borderWidth: 1, paddingHorizontal: 8, borderRadius: 4,
    },
});



function mapStateToProps(state) {
    return {
        printers: state.device.printers
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setPrintersRedux: (printers) => dispatch(setPrinters(printers)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPrinterScreen);