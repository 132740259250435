import React, { createContext, useState, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';
import { getAccessToken } from './storage';
import Observables from "@happy-gastro/global-observables";

export const SystemContext = createContext();

export const SystemProvider = ({ children }) => {
    const [foods, setFoods] = useState([]);
    const [categories, setCategories] = useState([]);
    const [events, setEvents] = useState([]);
    const [restaurant, setRestaurant] = useState(null);
    const [restaurantPlace, setRestaurantPlace] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [defaultView, setDefaultView] = useState('categories');
    const [eventColumnCount, setEventColumnCount ] = useState(2);
    const [categoryColumnCount, setCategoryColumnCount] = useState(2);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);


    const fetchSystemData = async () => {
        const token = await getAccessToken();
        const restaurantData = await AsyncStorage.getItem('restaurant');
        const restaurantPlaceData = await AsyncStorage.getItem('restaurantPlace');
        const storedDefaultView = await AsyncStorage.getItem('defaultView');
        const storedEventColumnCount = await AsyncStorage.getItem('eventColumnCount');
        const storedCategoryColumnCount = await AsyncStorage.getItem('categoryColumnCount');

        if(!token || !restaurantData || !restaurantPlaceData) return;

        if(storedDefaultView && storedDefaultView !== defaultView) setDefaultView(storedDefaultView)
        if(storedEventColumnCount && Number(storedEventColumnCount) !== eventColumnCount) setEventColumnCount(Number(storedEventColumnCount))
        if(storedCategoryColumnCount && Number(storedCategoryColumnCount) !== categoryColumnCount) setCategoryColumnCount(Number(storedCategoryColumnCount))

        try {
            const [foodsResponse, categoriesResponse, eventsResponse] = await Promise.all([
                fetch('https://api.happygastro.hu/api/restaurant/' + JSON.parse(restaurantData)._id + '/foods?placeId=' + JSON.parse(restaurantPlaceData)._id + '&lowKey=false&beta=false&showDisabled=false', {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
                }),
                fetch('https://api.happygastro.hu/api/restaurant/' + JSON.parse(restaurantData)._id + '/categories?onlyVisible=false&lowKey=false', {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
                }),
                fetch('https://api.happygastro.hu/api/tickets/events/all', {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
                }),
            ]);

            const foodsData = await foodsResponse.json();
            const categoriesData = await categoriesResponse.json();
            const eventsData = await eventsResponse.json();

            console.log("Events:", eventsData.events)

            await AsyncStorage.setItem('foods', JSON.stringify(foodsData.filter(food => food.visible && food.enabled)));
            await AsyncStorage.setItem('categories', JSON.stringify(categoriesData.filter(category => category.visible && category.enabled && !category.name.hu.toLowerCase().includes('törölt'))));
            await AsyncStorage.setItem('events', JSON.stringify(eventsData));

            setRestaurant(JSON.parse(restaurantData));
            setRestaurantPlace(JSON.parse(restaurantPlaceData));
            setFoods(foodsData.filter(food => food.visible && food.enabled));
            setCategories(categoriesData.filter(category => category.visible && category.enabled && !category.name.hu.toLowerCase().includes('törölt')));
            setEvents(eventsData.events);

            setLoaded(true)
        } catch (error) {
            console.error(error);
            const storedFoods = await AsyncStorage.getItem('foods');
            const storedCategories = await AsyncStorage.getItem('categories');
            const storedEvents = await AsyncStorage.getItem('events');

            if (storedFoods) setFoods(JSON.parse(storedFoods));
            if (storedCategories) setCategories(JSON.parse(storedCategories));
            if (storedEvents) setEvents(JSON.parse(storedEvents));

            setLoaded(true)
        }
    };
    useEffect(() => {
        fetchSystemData();
        Observables.register('fetchSystemData', fetchSystemData);
    }, []);

    return (
        <SystemContext.Provider value={{ defaultView, fetchSystemData, loaded, foods, categories, events, restaurant, restaurantPlace, eventColumnCount, categoryColumnCount, setEventColumnCount, setCategoryColumnCount,selectedEvent, setSelectedEvent, selectedCategory, setSelectedCategory }}>
            {children}
        </SystemContext.Provider>
    );
};