import React, { useState } from 'react';
import { View, Text, Dimensions, StyleSheet, TouchableOpacity, Alert, ScrollView } from 'react-native';
import TimeAgo from "react-native-timeago";
import {priceFormatter} from "../helpers/numbers";


// TODO: We can create a more accurate event title based on the event type and details
const eventTitle = (title) => {
    switch (title) {
        case 'created': return 'Rendelés létrehozva';
        case 'itemsAdded': return 'Tételek hozzáadva';
        case 'update': return 'Számla módosítva';
        case 'statusUpdate': return 'Státusz módosítva';
        default: return 'Számla módosítva';
    }
}
const orderType = (type) => {
    switch (type) {
        case 'takeAway': return 'Gyorsnyugta';
        case 'dineIn': return 'Helyben fogyasztott';
        case 'online': return 'Házhoz szállítás';
        default: return 'Gyorsnyugta';
    }
}
const paymentType = (type) => {
    switch (type) {
        case 'temporary': return 'Fizetetlen';
        case 'cash': return 'Készpénz';
        case 'creditCard': return 'Bankkártya';
        case 'szepCard': return 'Szép kártya';
        case 'szepCreditCard': return 'Szép kártya';
        case 'voucher': return 'Kupon / Utvalvány';
        default: return 'Fizetetlen';
    }
}

const Timeline = (orderData) => {

    // TODO: Fix this
    /*
    const events = [
        ...orderData?.history.map(event => ({ date: new Date(event.time).toISOString(), title: event.event, details: event.details, user: (event.user ? (event.user?.name?.first + ' '+ event.user?.name?.last) : false)})),
        ...orderData?.events.map(event => ({ date: new Date(event.time).toISOString(), title: event.event, details: event.details, user: (event.user ? (event.user?.name?.first + ' '+ event.user?.name?.last) : false)}))
    ];
     */
    const events = [
        { date: '2024-11-21 15:23:10', title: 'Számla létrehozva', description: 'Farkas Ferenc' },
        { date: '2024-11-21 16:32:45', title: 'Lezárás', description: 'Rendszer' },
    ];


    return (
        <ScrollView>
            <Text style={styles.title}>Idővonal</Text>
            <View style={styles.timeline}>
                {events.map((event, index) => (
                    <View key={index} style={styles.eventRow}>
                        <View style={styles.iconContainer}>
                            <View style={styles.icon} />
                            {index < events.length - 1 && <View style={styles.verticalLine} />}
                        </View>
                        <View style={styles.eventCard}>
                            <Text style={styles.date}><TimeAgo time={new Date(event.date)}/></Text>
                            <Text style={styles.eventTitle}>{eventTitle(event.title)}</Text>
                            {event.user && <Text style={styles.eventDescription}>{event.user}</Text>}
                        </View>
                    </View>
                ))}
            </View>
        </ScrollView>
    );
};

const OrderDetailsScreen = ({route, navigation}) => {
    const [isDialogVisible, setIsDialogVisible] = useState(false);
    const { orderData = {} } = route.params;

    const handleRedButtonPress = () => {
        Alert.alert(
            "Stornózás megerősítése",
            "Biztosan szeretnéd stornózni a következő számlát? Ezt később nem lehet visszavonni!",
            [
                {
                    text: "Nem",
                    style: "cancel"
                },
                {
                    text: "Igen",
                    onPress: () => console.log("Stornózva!")
                }
            ]
        );
    };

    return (
        <View style={[styles.container, {maxHeight: Dimensions.get("screen").height,
            height: Dimensions.get("screen").height}]}>
            <ScrollView contentContainerStyle={[styles.scrollView, {maxHeight: Dimensions.get("screen").height,
                height: Dimensions.get("screen").height}]}>

                {orderData.status === "closed" && <View style={styles.header}>
                    <Text style={styles.headerText}>Ez a számla már le van zárva, további teendőd vele nincs!</Text>
                </View>}
                {(orderData.status === "cancelled" || orderData.storno) && <View style={[styles.header, {backgroundColor: 'darkred'}]}>
                    <Text style={styles.headerText}>Ez a számla törölve lett!</Text>
                </View>}
                {(orderData.preOrder) && <View style={[styles.header, {backgroundColor: 'orange'}]}>
                    <Text style={styles.headerText}>Ez egy előrendelés: {new Date(orderData.preOrder).toISOString()}</Text>
                </View>}

                <View style={styles.detailsContainer}>
                    <View style={styles.row}>
                        <Text style={styles.label}>Típus:</Text>
                        <Text style={styles.value}>{orderType(orderData.orderType)}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.label}>Fizetési mód(ok):</Text>
                        <Text style={styles.valuePaid}>{orderData.status === "closed" && "Fizetve!"}</Text>
                    </View>
                    {orderData.paymentTypes.map(p => <View style={styles.row}>
                        <Text style={styles.label}>{paymentType(p.paymentType)}</Text>
                        <Text style={styles.value}>{priceFormatter(p.amount)}</Text>
                    </View>)}
                    <View style={styles.row}>
                        <Text style={styles.label}>Platform:</Text>
                        <Text style={styles.value}>terminál</Text>
                    </View>
                    <View style={styles.row}>
                        <Text style={styles.label}>Idő:</Text>
                        <Text style={styles.value}>{new Date(orderData.events[0].time).toISOString()}</Text>
                    </View>
                </View>

                <View style={styles.itemsContainer}>
                    <Text style={styles.itemHeader}>Rendelés tételei</Text>
                    {orderData.items.map(item => <View style={styles.itemRow}>
                        <Text style={styles.itemName}>{item.remoteName || item.name}</Text>
                        <Text style={styles.itemQuantity}>{item.amount} db</Text>
                        <Text style={styles.itemPrice}>{priceFormatter((item.amount) * (item.remotePrice || item.price))}</Text>
                    </View>)}
                    <View style={[styles.totalRow, {
                        borderTopWidth: 0.6,
                        borderTopColor: '#b0b0b0',
                        marginVertical: 8,
                    }]}>
                        <Text style={styles.totalText}>Termékek összesen:</Text>
                        <Text style={styles.totalValue}>{priceFormatter(orderData.productsPrice)}</Text>
                    </View>
                    <View style={styles.totalRow}>
                        <Text style={styles.totalText}>Fizetve:</Text>
                        <Text style={styles.totalValue}>{priceFormatter(orderData.paymentTypes.reduce((a,b) => +a+b.amount, 0))}</Text>
                    </View>
                </View>
                <Timeline orderData={orderData}/>
            </ScrollView>
            <View style={styles.buttonContainer}>
                {(orderData.status !== "closed" || orderData.status !== "cancelled" || !orderData.storno) && <TouchableOpacity style={styles.greenButton}>
                    <Text style={styles.buttonText}>Számla lezárása</Text>
                </TouchableOpacity>}
                <TouchableOpacity style={styles.orangeButton}>
                    <Text style={styles.buttonText}>Újranyomtatások</Text>
                </TouchableOpacity>
                {(orderData.status !== "cancelled" || !orderData.storno) && <TouchableOpacity style={styles.redButton} onPress={handleRedButtonPress}>
                    <Text style={styles.buttonText}>Stornó / Visszatérítés</Text>
                </TouchableOpacity>}
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#f5f5f5',
        height: Dimensions.get("screen").height
    },
    scrollView: {
        padding: 16,
        paddingBottom: 20, // Add padding to avoid content being hidden behind buttons
        height: Dimensions.get("screen").height
    },
    header: {
        backgroundColor: '#d6f5d6',
        padding: 10,
        borderRadius: 5,
        marginBottom: 16,
    },
    headerText: {
        color: '#008000',
        fontSize: 14,
        fontWeight: "700",
        textAlign: 'center',
    },
    detailsContainer: {
        backgroundColor: '#ffffff',
        padding: 16,
        borderRadius: 5,
        marginBottom: 16,
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 8,
    },
    label: {
        fontSize: 14,
        color: '#555555',
    },
    value: {
        fontSize: 14,
        color: '#000000',
    },
    valuePaid: {
        fontSize: 14,
        color: '#008000',
        fontWeight: 'bold',
    },
    itemsContainer: {
        backgroundColor: '#ffffff',
        padding: 16,
        borderRadius: 5,
        marginBottom: 16,
    },
    itemHeader: {
        fontSize: 14,
        fontWeight: 'bold',
        marginBottom: 8,
    },
    itemRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 8,
    },
    itemName: {
        fontSize: 14,
        color: '#000000',
    },
    itemQuantity: {
        fontSize: 14,
        color: '#000000',
    },
    itemPrice: {
        fontWeight: 400,
        fontSize: 14,
        color: '#000000',
    },
    totalRow: {
        fontWeight: 900,
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 4,
    },
    totalText: {
        fontWeight: 900,
        fontSize: 14,
        color: '#555555',
    },
    totalValue: {
        fontWeight: 900,
        fontSize: 14,
        color: '#000000',
    },
    buttonContainer: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 16,
        maxHeight: 70,
        backgroundColor: '#f5f5f5',
    },
    greenButton: {
        flex: 1,
        backgroundColor: '#008000',
        padding: 10,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        marginRight: 8,
    },
    orangeButton: {
        flex: 1,
        backgroundColor: '#FFA500',
        padding: 10,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        marginRight: 8,
    },
    redButton: {
        flex: 1,
        backgroundColor: '#FF0000',
        padding: 10,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    buttonText: {
        color: '#ffffff',
        fontSize: 14,
        fontWeight: 'bold',
        justifyContent: 'center',
        textAlign: 'center',
    },
    eventContainer: {
        flexDirection: 'row',
        marginBottom: 24,
    },
    iconLineContainer: {
        alignItems: 'center',
        marginRight: 16,
    },
    line: {
        width: 2,
        height: 40,
        backgroundColor: '#d3d3d3',
        marginTop: 4,
    },
    eventContent: {
        flex: 1,
    },
    description: {
        fontSize: 14,
        color: '#555',
    },

    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
        color: '#333',
    },
    timeline: {
        flexDirection: 'column',
        position: 'relative',
    },
    eventRow: {
        flexDirection: 'row',
        marginBottom: 20,
    },
    iconContainer: {
        alignItems: 'center',
        width: 50,
    },
    icon: {
        width: 12,
        height: 12,
        borderRadius: 6,
        backgroundColor: '#ff8000',
    },
    verticalLine: {
        width: 2,
        height: 50,
        backgroundColor: '#d3d3d3',
        marginTop: 4,
    },
    eventCard: {
        backgroundColor: '#fff',
        padding: 16,
        borderRadius: 10,
        elevation: 2, // Adds shadow for Android
        shadowColor: '#000', // Adds shadow for iOS
        shadowOpacity: 0.1,
        shadowOffset: { width: 0, height: 2 },
        shadowRadius: 4,
        flex: 1,
    },
    date: {
        fontSize: 14,
        color: '#999',
        marginBottom: 8,
    },
    eventTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#333',
        marginBottom: 4,
    },
    eventDescription: {
        fontSize: 14,
        color: '#666',
    },
});

export default OrderDetailsScreen;