// Function to calculate total income
export default function StatisticsHelper(orders){
        let netSum = {};
        let vatSum = {};
        let grossSum = {};
        let deliveries = {};
        let cashiers = {};
        let discounts = {};
        let discountOrders = {};
        let discountsSum = 0;
        let serviceFee = 0;

        const allOrders = [...orders];
        const cancelledOrders = [...orders.filter((o) => o.status === "cancelled")];
        const unfilteredOrders = [
                ...orders.filter((o) => !o.storno && o.status !== "cancelled"),
        ];
        const unclosedDineInOrders = unfilteredOrders.filter(
            (o) => o.orderType === "dineIn" && o.status !== "closed"
        );
        const acceptedOrdersWithoutMap = unfilteredOrders.filter(
            (o) =>
                o.orderType !== "dineIn" ||
                (o.orderType === "dineIn" && o.status === "closed")
        );

        let acceptedOrders = [];
        acceptedOrdersWithoutMap.forEach((o) => {
                if (o.paymentTypes && o.paymentTypes.length > 0) {
                        o.paymentTypes.forEach((pT, i) => {
                                acceptedOrders.push({
                                        ...o,
                                        paymentType: pT.paymentType,
                                        orderSum: o.paymentTypes.length === 1 || i === 0 ? o.orderSum : 0,
                                        productsSum:
                                            o.paymentTypes.length === 1 || i === 0 ? o.productsSum : 0,
                                        tipAmount: o.paymentTypes.length === 1 || i === 0 ? o.tipAmount : 0,
                                        serviceFeePrice:
                                            o.paymentTypes.length === 1 || i === 0 ? o.serviceFeePrice : 0,
                                        deliveryFee:
                                            o.paymentTypes.length === 1 || i === 0 ? o.deliveryFee : 0,
                                });
                        });
                } else {
                        acceptedOrders.push(o);
                }
        });

        const productsSum = [...acceptedOrders].reduce(
            (total, order) =>
                order.orderSum -
                order.deliveryFee -
                Number(order.serviceFeePrice) +
                total,
            0
        );
        const ordersSum = [...acceptedOrders].reduce(
            (total, order) => order.orderSum + total,
            0
        );
        const deliveryFeeSum = [...acceptedOrders].reduce(
            (total, order) => order.deliveryFee + total,
            0
        );

        const tipSum = {
                dineIn: [...acceptedOrdersWithoutMap]
                    .filter((o) => o.orderType === "dineIn")
                    .reduce((total, order) => order.tipAmount + total, 0),
                takeAway: [...acceptedOrdersWithoutMap]
                    .filter((o) => o.orderType === "takeAway")
                    .reduce((total, order) => order.tipAmount + total, 0),
                online: [...acceptedOrdersWithoutMap]
                    .filter((o) => o.orderType === "online")
                    .reduce((total, order) => order.tipAmount + total, 0),
        };

        const orderSums = {
                dineIn: [...acceptedOrders]
                    .filter((o) => o.orderType === "dineIn")
                    .reduce((total, order) => order.orderSum + total, 0),
                takeAway: [...acceptedOrders]
                    .filter((o) => o.orderType === "takeAway")
                    .reduce((total, order) => order.orderSum + total, 0),
                online: [...acceptedOrders]
                    .filter((o) => o.orderType === "online")
                    .reduce((total, order) => order.orderSum + total, 0),
                vipBalance: [...acceptedOrders]
                    .filter((o) => o.paymentType === "vipBalance")
                    .reduce((total, order) => order.orderSum + total, 0),
        };

        let productsSumCalculated = 0;

        const stornoOrders = [...orders.filter((o) => o.storno)];
        const stornoSum = [...stornoOrders].reduce(
            (total, order) => +total + order.orderSum,
            0
        );

        const creditCardOrders = [...acceptedOrders].filter(
            (o) =>
                (o.paymentType === "creditCard" || o.paymentType === "szepCreditCard") &&
                o.orderFrom !== "wolt" &&
                o.orderFrom !== "netpincer" &&
                o.orderFrom !== "bolt" &&
                o.orderFrom !== "ordit" &&
                o.orderFrom !== "falatozz"
        );
        const woltCreditCardOrders = [...acceptedOrders].filter(
            (o) => o.paymentType === "creditCard" && o.orderFrom === "wolt"
        );
        const npCreditCardOrders = [...acceptedOrders].filter(
            (o) => o.paymentType === "creditCard" && o.orderFrom === "netpincer"
        );
        const npCashOrders = [...acceptedOrders].filter(
            (o) => o.paymentType === "cash" && o.orderFrom === "netpincer"
        );

        const falatozzCreditCardOrders = [...acceptedOrders].filter(
            (o) => o.paymentType === "creditCard" && o.orderFrom === "falatozz"
        );

        const boltCreditCardOrders = [...acceptedOrders].filter(
            (o) => o.paymentType === "creditCard" && o.orderFrom === "bolt"
        );
        const orditCreditCardOrders = [...acceptedOrders].filter(
            (o) => o.orderFrom === "ordit"
        );

        const phoneCardOrders = [...acceptedOrders].filter(
            (o) => o.paymentType === "creditCard" && o.orderFrom === "phone"
        );
        const phoneCashOrders = [...acceptedOrders].filter(
            (o) => o.paymentType === "cash" && o.orderFrom === "phone"
        );

        const cashOrders = [...acceptedOrders].filter(
            (o) => o.paymentType === "cash"
        );
        const vipOrders = [...acceptedOrders].filter(
            (o) => o.paymentType === "vipBalance"
        );

        const takeAwayOrders = [...acceptedOrders].filter(
            (o) => o.orderType === "takeAway"
        );
        const takeAwayOrdersCreditCard = [...acceptedOrders].filter(
            (o) => o.orderType === "takeAway" && o.paymentType === "creditCard"
        );
        const takeAwayOrdersCash = [...acceptedOrders].filter(
            (o) => o.orderType === "takeAway" && o.paymentType === "cash"
        );
        const takeAwayOrdersVIP = [...acceptedOrders].filter(
            (o) => o.orderType === "takeAway" && o.paymentType === "vipBalance"
        );

        const onlineOrders = [...acceptedOrders].filter(
            (o) => o.orderType === "online"
        );
        const onlineOrdersCreditCard = [...acceptedOrders].filter(
            (o) =>
                o.orderType === "online" &&
                (o.paymentType === "creditCard" || o.paymentType === "szepCreditCard")
        );
        const onlineOrdersCash = [...acceptedOrders].filter(
            (o) => o.orderType === "online" && o.paymentType === "cash"
        );

        let removedItems = 0;
        let removedItemsPrice = 0;
        let removedItemsOrders = [];
        let transferItems = 0;
        let transferItemsPrice = 0;
        let transferItemsOrders = [];

        if (acceptedOrders.length) {
                acceptedOrders.map((generatedOrder) => {
                        // Get orders by deliveries
                        if (
                            generatedOrder.delivery &&
                            generatedOrder.delivery.user &&
                            generatedOrder.delivery.user._id
                        ) {
                                if (!deliveries[generatedOrder.delivery.user._id]) {
                                        deliveries[generatedOrder.delivery.user._id] = [];
                                } else {
                                        deliveries[generatedOrder.delivery.user._id].push({
                                                user: generatedOrder.delivery.user,
                                                orders: [
                                                        ...deliveries[generatedOrder.delivery.user._id],
                                                        generatedOrder,
                                                ],
                                        });
                                }
                        }

                        const removeHistories = generatedOrder.history.filter(
                            (h) => h && h.event === "removeItems" && !h.extra.transfer
                        );
                        const transferHistories = generatedOrder.history.filter(
                            (h) => h && h.event === "removeItems" && h.extra.transfer
                        );

                        if (removeHistories.length > 0) removedItemsOrders.push(generatedOrder);
                        removeHistories.forEach((removeHistory) => {
                                if (removeHistory && removeHistory.extra && removeHistory.extra.items) {
                                        removedItems += removeHistory.extra.items.reduce(
                                            (a, b) => +a + b.amount * -1,
                                            0
                                        );
                                        removedItemsPrice += removeHistory.extra.items.reduce(
                                            (a, b) => +a + (b.remotePrice || b.price) * (b.amount * -1),
                                            0
                                        );
                                }
                        });
                        if (transferHistories.length > 0)
                                transferItemsOrders.push(generatedOrder);
                        transferHistories.forEach((transferHistory) => {
                                if (
                                    transferHistory &&
                                    transferHistory.extra &&
                                    transferHistory.extra.items
                                ) {
                                        transferItems += transferHistory.extra.items.reduce(
                                            (a, b) => +a + b.amount * -1,
                                            0
                                        );
                                        transferItemsPrice += transferHistory.extra.items.reduce(
                                            (a, b) => +a + (b.remotePrice || b.price) * (b.amount * -1),
                                            0
                                        );
                                }
                        });

                        // Get orders by users
                        const closeHistory = generatedOrder.history.find(
                            (h) => h && h.event === "payment"
                        );
                        if (
                            closeHistory &&
                            closeHistory.extra &&
                            closeHistory.extra.user &&
                            (generatedOrder.orderType === "dineIn" ||
                                generatedOrder.orderType === "takeAway")
                        ) {
                                const userId = closeHistory.extra.user._id;
                                if (cashiers[userId]) {
                                        cashiers[userId].push(generatedOrder);
                                } else {
                                        cashiers[userId] = [generatedOrder];
                                }
                        }

                        generatedOrder.items.map((cartItem) => {
                                let discountPercentage = 0;

                                if (generatedOrder.discounts) {
                                        if (generatedOrder.discounts.amount) {
                                                discountPercentage =
                                                    (generatedOrder.discounts.amount /
                                                        (generatedOrder.orderSum + generatedOrder.discounts.amount)) *
                                                    100;
                                        } else if (generatedOrder.discounts.percentage) {
                                                discountPercentage = generatedOrder.discounts.percentage;
                                        }
                                }

                                // Extras price
                                let extrasPrice = cartItem.extras.reduce(
                                    (a, b) => +a + (b.remotePrice || b.price),
                                    0
                                );

                                const cartItemPrice =
                                    ((cartItem.remotePrice || cartItem.price) + extrasPrice) *
                                    cartItem.amount;
                                productsSumCalculated += cartItemPrice;

                                const discountedPrice = cartItemPrice * (discountPercentage / 100);

                                const itemFullPrice = cartItemPrice - discountedPrice;

                                if (cartItem.item) {
                                        // For product VAT calculation
                                        if (cartItem.item && cartItem.item.vat) {
                                                if (!netSum[cartItem.item.vat[generatedOrder.orderType]])
                                                        netSum[cartItem.item.vat[generatedOrder.orderType]] = 0;
                                                const net =
                                                    itemFullPrice /
                                                    (Number(cartItem.item.vat[generatedOrder.orderType]) / 100 + 1);
                                                netSum[cartItem.item.vat[generatedOrder.orderType]] += net;

                                                if (!vatSum[cartItem.item.vat[generatedOrder.orderType]])
                                                        vatSum[cartItem.item.vat[generatedOrder.orderType]] = 0;
                                                vatSum[cartItem.item.vat[generatedOrder.orderType]] +=
                                                    itemFullPrice - net;

                                                if (!grossSum[cartItem.item.vat[generatedOrder.orderType]])
                                                        grossSum[cartItem.item.vat[generatedOrder.orderType]] = 0;
                                                grossSum[cartItem.item.vat[generatedOrder.orderType]] +=
                                                    itemFullPrice;
                                        }

                                        // For serviceFee calculation
                                        if (generatedOrder.serviceFee) {
                                                const productPrice = cartItemPrice + extrasPrice;
                                                const serviceFeeRatio = productPrice / generatedOrder.orderSum;

                                                const productServiceFee =
                                                    productPrice * (generatedOrder.serviceFee / 100);

                                                if (productServiceFee) {
                                                        if (!netSum["Sz" + cartItem.item.vat[generatedOrder.orderType]])
                                                                netSum["Sz" + cartItem.item.vat[generatedOrder.orderType]] = 0;
                                                        const serviceFeeNet =
                                                            productServiceFee /
                                                            (Number(cartItem.item.vat[generatedOrder.orderType]) / 100 + 1);
                                                        netSum["Sz" + cartItem.item.vat[generatedOrder.orderType]] +=
                                                            serviceFeeNet;

                                                        if (!vatSum["Sz" + cartItem.item.vat[generatedOrder.orderType]])
                                                                vatSum["Sz" + cartItem.item.vat[generatedOrder.orderType]] = 0;
                                                        vatSum["Sz" + cartItem.item.vat[generatedOrder.orderType]] +=
                                                            productServiceFee - serviceFeeNet;

                                                        if (!grossSum["Sz" + cartItem.item.vat[generatedOrder.orderType]])
                                                                grossSum[
                                                                "Sz" + cartItem.item.vat[generatedOrder.orderType]
                                                                    ] = 0;
                                                        grossSum["Sz" + cartItem.item.vat[generatedOrder.orderType]] +=
                                                            productServiceFee;
                                                }
                                        }
                                } else {
                                        if (!netSum[0]) netSum[0] = 0;
                                        const net = itemFullPrice / (Number(0) / 100 + 1);
                                        netSum[0] += net;

                                        if (!vatSum[0]) vatSum[0] = 0;
                                        vatSum[0] += itemFullPrice - net;

                                        if (!grossSum[0]) grossSum[0] = 0;
                                        grossSum[0] += itemFullPrice;
                                }
                        });

                        if (generatedOrder.deliveryFee) {
                                if (!netSum[27]) netSum[27] = 0;
                                const net = generatedOrder.deliveryFee / (Number(27) / 100 + 1);
                                netSum[27] += net;

                                if (!vatSum[27]) vatSum[27] = 0;
                                vatSum[27] += generatedOrder.deliveryFee - net;

                                if (!grossSum[27]) grossSum[27] = 0;
                                grossSum[27] += generatedOrder.deliveryFee;
                        }
                });
        }

        const statistics = {
                orders: acceptedOrders,
                    tipAmount: tipSum,
                productsSum: productsSumCalculated,
                    restaurantServiceFee: serviceFee,
                    deliveryCount: onlineOrders.length,
                    takeAwaySum: takeAwayOrders.reduce((a, b) => +a + b.orderSum, 0),
                    restaurantFullSum: acceptedOrders
                    .filter((o) => o.orderType === "dineIn" || o.orderType === "takeAway")
                    .reduce((a, b) => +a + b.orderSum, 0),
                    deliveryCreditCardSum: onlineOrdersCreditCard.reduce(
                    (a, b) => +a + b.orderSum,
                    0
                ),
                    deliveryCashSum: onlineOrdersCash.reduce((a, b) => +a + b.orderSum, 0),
                    allSum: orderSums,
                    restaurantTakeAwaySum: acceptedOrders
                    .filter((o) => o.orderType === "takeAway")
                    .reduce((a, b) => +a + b.orderSum, 0),
                    foodPandaAndWebshopCount: acceptedOrders.filter(
                    (o) => o.orderFrom === "foodPanda"
                ).length,
                    foodPandaAndWebshopSum: acceptedOrders
                    .filter((o) => o.orderFrom === "foodPanda")
                    .reduce((a, b) => +a + b.orderSum, 0),
                cashOrders:cashOrders,
                onlineCreditCardOrders:onlineOrdersCreditCard,
                creditCardOrders:creditCardOrders,
                cashOrdersCount:cashOrders.length,
                creditCardOrdersCount:creditCardOrders.length,
                creditCardOrdersSum:creditCardOrders.reduce((a, b) => +a + b.orderSum, 0),
                    fullCashIncome: cashOrders.reduce((a, b) => +a + b.orderSum, 0),
                    finalSum: orderSums.dineIn + orderSums.takeAway + orderSums.online,
                summary : {vat: vatSum, net: netSum, gross: grossSum},
                serviceFeePrice: serviceFee,
        }

        return {productsSum,  statistics,}

}
