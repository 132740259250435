import React, { useState } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import {LinearGradient} from "expo-linear-gradient";

const Pinpad = ({ onInput, onClose, onPay }) => {
    const [input, setInput] = useState('');

    const handlePress = (value) => {
        if (value === '.' && input.includes('.')) return;
        const newInput = input + value;
        setInput(newInput);
        onInput(newInput);
    };

    const handleClear = () => {
        setInput('');
        onInput('');
    };

    const handleDelete = () => {
        const newInput = input.slice(0, -1);
        setInput(newInput);
        onInput(newInput);
    };

    return (<LinearGradient
        colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']}
        style={{ flex: 1, width: '100%', height: '100%', borderRadius: 10 }}
    >
        <View style={styles.container}>

            <View style={styles.row}>
                {[1, 2, 3].map((num) => (
                    <TouchableOpacity key={num} style={styles.button} onPress={() => handlePress(num.toString())}>
                        <Text style={styles.buttonText}>{num}</Text>
                    </TouchableOpacity>
                ))}
            </View>
            <View style={styles.row}>
                {[4, 5, 6].map((num) => (
                    <TouchableOpacity key={num} style={styles.button} onPress={() => handlePress(num.toString())}>
                        <Text style={styles.buttonText}>{num}</Text>
                    </TouchableOpacity>
                ))}
            </View>
            <View style={styles.row}>
                {[7, 8, 9].map((num) => (
                    <TouchableOpacity key={num} style={styles.button} onPress={() => handlePress(num.toString())}>
                        <Text style={styles.buttonText}>{num}</Text>
                    </TouchableOpacity>
                ))}
            </View>
            <View style={styles.row}>
                <TouchableOpacity style={styles.button} onPress={handleClear}>
                    <Text style={styles.buttonText}>C</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.button} onPress={() => handlePress('0')}>
                    <Text style={styles.buttonText}>0</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.button} onPress={handleDelete}>
                    <Text style={styles.buttonText}>⌫</Text>
                </TouchableOpacity>
            </View>
            <View style={styles.row}>
                <TouchableOpacity style={styles.closeButton} onPress={onClose}>
                    <Text style={styles.buttonText}>Close</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.payButton} onPress={onPay}>
                    <Text style={styles.buttonText}>Fizetés</Text>
                </TouchableOpacity>
            </View>

        </View>  </LinearGradient>
    );
};

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        paddingTop: 10
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    button: {
        backgroundColor: 'orange',
        width: 120, // Set a fixed width
        height: 60, // Set a fixed height
        margin: 4, // Add margin for spacing
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonText: {
        color: 'white',
        fontSize: 20,
    },
    closeButton: {
        backgroundColor: '#aa2121',
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 5,
        marginBottom: 10,
        marginRight: 5,
    },
    payButton: {
        backgroundColor: '#308a0c',
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 5,
        marginBottom: 10,
        marginLeft: 5,

    },
});

export default Pinpad;