import React, { useContext, useEffect, useState } from 'react';
import {View, Text, StyleSheet, TouchableOpacity, Dimensions, Modal, TextInput, ToastAndroid} from 'react-native';
import AntDesign from '@expo/vector-icons/AntDesign';
import Pinpad from '../components/Pinpad';
import { LinearGradient } from "expo-linear-gradient";
import { CartContext } from "../helpers/CartContext";
import { priceFormatter } from "../helpers/numbers";
import { Feather } from "@expo/vector-icons";
import {
    handlePayment
} from "../helpers/paymentHelper";
import Spinner from "react-native-loading-spinner-overlay";
import {BluetoothContext} from "../helpers/BluetoothContext";
import {TableContext} from "../helpers/TableContext";
import {OrdersContext} from "../helpers/OrdersContext";

const { width, height } = Dimensions.get('window');

const PaymentModal = ({ visible, onClose, navigation }) => {
    const {sendData} = useContext(BluetoothContext);
    const { cartTotal = 0, cartItems = [] , setCart, originalCartTotal, productsPrice} = useContext(CartContext);
    const [pinpadVisible, setPinpadVisible] = useState(false);
    const [originalAmount, setOriginalAmount] = useState(cartTotal || 0);
    const [amount, setAmount] = useState(cartTotal || 0);
    const [paidAmount, setPaidAmount] = useState(0);
    const [amountToPay, setAmountToPay] = useState(originalAmount);
    const [changeToReturn, setChangeToReturn] = useState(0);
    const [paymentType, setPaymentType] = useState(false);
    const [processing, setProcessing] = useState(false);
    const {currentTable} = useContext(TableContext);
    const {orders} = useContext(OrdersContext);

    const togglePinpad = () => {
        setPinpadVisible(!pinpadVisible);
    };

    useEffect(() => {
        return () => {
            setProcessing(false);
        }
    }, []);

    useEffect(() => {
        setOriginalAmount(cartTotal);
        setAmount(cartTotal);
    }, [cartTotal]);

    useEffect(() => {
        const numericAmount = parseInt(amount) || 0;
        const remainingAmount = Math.max(originalAmount - numericAmount, 0);
        const change = Math.max(numericAmount - originalAmount, 0);

        setPaidAmount(numericAmount);
        setAmountToPay(remainingAmount);
        setChangeToReturn(change);
    }, [amount]);

    const handlePinInput = (input) => {
        setAmount(input);
    };

    const handleClosePinpad = () => {
        setPinpadVisible(false);
    };

    const handlePaymentTypeChange = (type) => {
        setPaymentType(type);
    };

    if (!visible) return null;

    return (
        <Modal
            animationType="slide"
            transparent={true}
            visible={visible}
            onRequestClose={onClose}
        >
            <View style={styles.backdrop}>
                <View style={styles.modal}>
                    <LinearGradient
                        colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']}
                        style={{ flex: 1, width: '100%', height: '100%', borderRadius: 10 }}
                    >
                        <Spinner
                            visible={processing}
                            textContent={'Feldolgozás...'}
                            textStyle={styles.spinnerTextStyle}
                        />
                        <View style={styles.tabsContainer}>
                            {[
                                { type: 'Készpénz', icon: 'dollar-sign' },
                                { type: 'Bankkártya', icon: 'credit-card' },
                                { type: 'Utalvány', icon: 'tag' },
                            ].map(({ type, icon }) => (
                                <TouchableOpacity
                                    key={type}
                                    style={[styles.tab, paymentType === type && styles.activeTab]}
                                    onPress={() => handlePaymentTypeChange(type)}
                                >
                                    <Feather name={icon} size={18} color={paymentType === type ? '#f1b34b' : '#666'} />
                                    <Text style={[styles.tabText, paymentType === type && styles.activeTabText]}>{type}</Text>
                                </TouchableOpacity>
                            ))}
                        </View>

                        <TextInput
                            style={styles.inputField}
                            value={amount.toString()}
                            onChangeText={setAmount}
                            keyboardType="numeric"
                            placeholder="Enter amount"
                            placeholderTextColor="#AAA"
                        />

                        {cartItems.length > 0 && (
                            <View style={styles.infoContainer}>
                                <View style={styles.infoRow}>
                                    <Text style={styles.infoLabel}>Fizetendő:</Text>
                                    <Text style={styles.infoValue}>{priceFormatter(originalAmount)}</Text>
                                </View>
                                <View style={[styles.infoRow, {
                                    color: Number(amountToPay) > 0 ? "red" : "#333",
                                    fontWeight: Number(amountToPay) > 0 ? "900" : "200"
                                }]}>
                                    <Text style={[styles.infoLabel, {
                                        color: Number(amountToPay) > 0 ? "red" : "#333",
                                        fontWeight: Number(amountToPay) > 0 ? "900" : "200"
                                    }]}>Még fizetendő:</Text>
                                    <Text style={[styles.infoValue, {
                                        color: Number(amountToPay) > 0 ? "red" : "#333",
                                        fontWeight: Number(amountToPay) > 0 ? "900" : "200"
                                    }]}>{priceFormatter(amountToPay)}</Text>
                                </View>
                                <View style={styles.infoRow}>
                                    <Text style={[styles.infoLabel, { fontWeight: Number(changeToReturn) > 0 ? "900" : "200" }]}>Visszajáró:</Text>
                                    <Text style={[styles.infoValue, { fontWeight: Number(changeToReturn) > 0 ? "900" : "200" }]}>{priceFormatter(changeToReturn)}</Text>
                                </View>
                            </View>
                        )}
                        {pinpadVisible ? (
                            <View style={styles.pinpad}>
                                <Pinpad onInput={handlePinInput} onPay={async () => {
                                    setProcessing(true)
                                    const tableOrder = orders?.find(
                                        (order) =>
                                            !order.storno &&
                                            order.status !== "cancelled" &&
                                            order.status !== "closed" &&
                                            order.tableId === currentTable?._id
                                    );
                                    return handlePayment(paymentType, paidAmount, originalAmount, setProcessing, navigation, cartTotal, setCart, cartItems, sendData, onClose, originalCartTotal, productsPrice);
                                }} onClose={handleClosePinpad} />
                            </View>
                        ) : (
                            <View style={styles.content}>
                                <View style={styles.buttonContainer}>
                                    <TouchableOpacity style={styles.button} onPress={async () => {
                                        setProcessing(true)
                                        return handlePayment(paymentType, paidAmount, originalAmount, setProcessing, navigation, cartTotal, setCart, cartItems, sendData, onClose, originalCartTotal, productsPrice);
                                    }}>
                                        <Text style={styles.buttonText}>Fizetés</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.greyButton} onPress={togglePinpad}>
                                        <Feather name="edit-3" size={18} />
                                    </TouchableOpacity>
                                </View>
                            </View>
                        )}
                    </LinearGradient>
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    backdrop: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        alignItems: 'center',
        zIndex: 1000,
    },
    modal: {
        width: width * 0.4, // Make the modal narrower
        padding: 20,
        height: height * 0.8,
        borderRadius: 10,
        alignItems: 'center',
    },
    content: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '80%',
    },
    button: {
        backgroundColor: 'green',
        paddingVertical: 15, // Increase padding for bigger buttons
        paddingHorizontal: 30, // Increase padding for bigger buttons
        borderRadius: 10, // Make the buttons nicer
        marginRight: 10,
    },
    greyButton: {
        backgroundColor: '#ccc',
        paddingVertical: 15, // Increase padding for bigger buttons
        paddingHorizontal: 30, // Increase padding for bigger buttons
        borderRadius: 10, // Make the buttons nicer
    },
    buttonText: {
        color: 'white',
        fontSize: 18, // Increase font size for better readability
        fontWeight: 'bold', // Make the text bold
    },
    pinpad: {
        backgroundColor: 'rgba(255,255,255,0)',
        borderRadius: 5,
        padding: '10px 0 10px',
        width: '100%',
        alignItems: 'center',
    },
    infoContainer: {
        marginTop: 20,
        padding: 10,
    },
    infoRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginVertical: 2,
    },
    infoLabel: {
        fontSize: 18,
        color: '#333',
        fontWeight: '500',
    },
    infoValue: {
        fontSize: 18,
        color: '#333',
        fontWeight: 'bold',
    },
    inputField: {
        marginTop: 50,
        fontSize: 28,
        textAlign: 'center',
        color: '#333',
        borderBottomWidth: 1,
        width: '80%',
        alignSelf: 'center',
    },
    tabsContainer: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        padding: 10,
        backgroundColor: '#EBE5F2',
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5
    },
    spinnerTextStyle: {
        color: '#FFF'
    },
    tab: {
        flex: 1,
        padding: 12,
        alignItems: 'center',
    },
    activeTab: {
        borderBottomWidth: 2,
        borderBottomColor: '#f1b34b',
    },
    tabText: {
        fontSize: 16,
        color: '#666',
        marginTop: 4,
    },
    activeTabText: {
        color: '#f1b34b',
    },
});
export default PaymentModal;