import React from 'react';
import { View, StyleSheet, Dimensions} from 'react-native';
import SaleScreen from './SaleScreen';
import CartScreen from './CartScreen';

const { width } = Dimensions.get('window');

const BigSaleScreen = ({route, navigation}) => {
    const { numColumns } = route.params || { numColumns: 3 };
    return (
        <View style={styles.container}>
            <View style={styles.saleScreenContainer}>
                <SaleScreen navigation={navigation} numColumns={numColumns} />
            </View>
            <View style={styles.cartScreenContainer}>
                <CartScreen navigation={navigation} />
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
    },
    saleScreenContainer: {
        flex: 6.5, // 70% of the screen width
        borderRightWidth: 1,
        borderRightColor: '#ccc',
        zIndex: 999

    },
    cartScreenContainer: {
        flex: 3.5, // 30% of the screen width
    },
});

export default BigSaleScreen;