const ordersDefault = {
	orders: [],
	cartItems: [],
	selectedTable: undefined,
	order: {},
	orderType: "takeAway",
	paymentType: "temporary",
	serviceFee: 0,
	discounts: { percentage: 0, amount: 0 },
};

export default ordersDefault;
