// HomeScreen.js
import React, {useContext, useEffect} from 'react';

import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {Alert, Linking, StyleSheet} from "react-native";
import Icon from 'react-native-vector-icons/FontAwesome';
import SettingsScreen from "./SettingsScreen";
import TableMapScreen from "./TableMapScreen";
import TablesPendingScreen from "./TablesPendingScreen";
import TablesBookingsScreen from "./TablesBookingsScreen";
const Tab = createBottomTabNavigator();


export default function TablesScreen({navigation}) {


    return (<Tab.Navigator
            detachInactiveScreens={false}
            tabBarLabelPosition={"below-icon"}
            lazy={false}
            backBehavior={"history"}
            headerMode={"none"}
            screenOptions={{headerShown: false}}
            tabBarOptions={{
                activeTintColor: '#a26909',
                inactiveTintColor: '#575757',
                activeBackgroundColor: '#f0f0f0',
                inactiveBackgroundColor: '#ffffff',
                showLabel: true,
                labelStyle: {
                    fontSize: 14,
                    fontWeight: 'bold',
                },
                tabStyle: {
                    paddingVertical: 10,
                    height: 70,
                    marginTop: -20
                },
                style: {
                    height: 70,
                    backgroundColor: '#e0e0e0',
                }
            }}
        >
            <Tab.Screen name="TableMap" component={TableMapScreen}
                        options={{
                            tabBarIcon: ({color, size}) => (
                                <Icon name="braille" color={color} size={size}/>
                            ),
                            title: 'Asztaltérkép',
                            tabBarLabel: 'Asztaltérkép',
                        }}/>
            <Tab.Screen name="OpenTables" component={TablesPendingScreen}
                        options={{
                            tabBarIcon: ({color, size}) => (
                                <Icon name="cutlery" color={color} size={size}/>
                            ),
                            title: 'Nyitott asztalok',
                            tabBarLabel: 'Nyitott asztalok',
                            tabBarBadge: 1
                        }}/>
            <Tab.Screen name="Bookings" component={TablesBookingsScreen}
                        options={{
                            tabBarIcon: ({color, size}) => (
                                <Icon name="calendar" color={color} size={size}/>
                            ),
                            title: 'Közelgő foglalások',
                            tabBarLabel: 'Foglalások'
                        }}/>
            <Tab.Screen name="TablesSettings" component={SettingsScreen}
                        options={{
                            tabBarIcon: ({color, size}) => (
                                <Icon name="wrench" color={color} size={size}/>
                            ),
                            title: "Asztaltérkép beállítása",
                            tabBarLabel: 'Beállítások',
                        }}/>
        </Tab.Navigator>
    );
}

const styles = StyleSheet.create({
    background: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
});
