import * as React from 'react';
import { useState } from 'react';
import { Button, Dialog, PaperProvider, Portal, Text, TextInput } from 'react-native-paper';
import { Modal } from "react-native";
/**
 * HappyPrompt component to display a prompt dialog with an input field.
 *
 * @param {Object} props - The properties object.
 * @param {boolean} props.visible - Determines if the dialog is visible.
 * @param {function} props.onConfirm - Callback function to handle confirm action.
 * @param {function} props.onCancel - Callback function to handle cancel action.
 * @param {string} [props.icon="info"] - Icon type to display in the dialog.
 * @param {string} [props.title] - Title of the dialog.
 * @param {string} [props.message] - Message to display in the dialog.
 * @param {string} [props.confirmText] - Text for the confirm button.
 * @param {string} [props.cancelText] - Text for the cancel button.
 * @param {Object} [props.inputProps] - Additional properties for the TextInput component.
 *
 * @example
 * <HappyPrompt
 *   visible={true}
 *   onConfirm={(value) => console.log(value)}
 *   onCancel={() => console.log('Cancelled')}
 *   icon="alert"
 *   title="Alert Title"
 *   message="This is an alert message."
 *   confirmText="Confirm"
 *   cancelText="Cancel"
 *   inputProps={{ placeholder: 'Enter your input' }}
 * />
 */
const HappyPrompt = ({ visible, onConfirm, onCancel, icon = "info", title, message, confirmText, cancelText, inputProps = {} }) => {
    const [inputValue, setInputValue] = useState('');

    function getIcon() {
        switch (icon) {
            case "alert":
                return "alert";
            case "error":
                return "alert-circle";
            case "warning":
                return "alert-decagram";
            case "info":
                return "information";
            default:
                return `${icon}`;
        }
    }

    return (
        <>
            {visible && (
                <Portal>
                    <Modal
                    animationType="slide"
                    transparent={true}
                    visible={visible}
                    onRequestClose={() => onCancel()}
                >
                    <PaperProvider>
                            <Dialog visible={visible} onDismiss={onCancel}>
                                <Dialog.Icon icon={getIcon()} />
                                <Dialog.Title>{title || ""}</Dialog.Title>
                                <Dialog.Content>
                                    <Text variant="bodyMedium">{message ||""}</Text>
                                    <TextInput
                                        value={inputValue}
                                        mode="outlined"
                                        style={{ marginTop: 10 }}
                                        onChangeText={text => setInputValue(text)}
                                        {...inputProps}
                                    />
                                </Dialog.Content>
                                <Dialog.Actions>
                                    <Button onPress={onCancel}>{cancelText || "Mégsem"}</Button>
                                    <Button onPress={() => onConfirm(inputValue)}>{confirmText || "Megerősít"}</Button>
                                </Dialog.Actions>
                            </Dialog>
                    </PaperProvider>
                </Modal>
                </Portal>
            )}
        </>
    );
};

export default HappyPrompt;