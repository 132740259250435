import React, { useState } from 'react';
import { Animated, Dimensions, StyleSheet, TextInput, TouchableOpacity, View, Text } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import IonIcons from 'react-native-vector-icons/Ionicons';
import { useNavigation } from "@react-navigation/native";
import { Menu, Provider } from 'react-native-paper';
import {searchFoodByKeywords} from "./FoodSearch";
import {TableContext} from "./TableContext";
import {CartContext} from "./CartContext";

const CustomHeader = (props) => {
    const navigation = useNavigation();
    const inputRef = React.useRef(null);
    const [searchText, setSearchText] = React.useState('');
    const [overlayVisible, setOverlayVisible] = React.useState(false);
    const overlayOpacity = React.useRef(new Animated.Value(0)).current;
    const [menuVisible, setMenuVisible] = useState(false);
    const { removeTable } = React.useContext(TableContext);
    const {setAlreadySentCartItems} = React.useContext(CartContext);

    const focusInput = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const startAnimation = () => {
        Animated.timing(overlayOpacity, {
            toValue: 1,
            duration: 700,
            useNativeDriver: true,
        }).start()
    };

    const endAnimation = () => {
        Animated.timing(overlayOpacity, {
            toValue: 0,
            duration: 700,
            useNativeDriver: true,
        }).start()
    };

    React.useEffect(() => {
        if (searchText.length > 0) {
            startAnimation();
        } else {
            endAnimation();
        }
    });

    const handleSelect = (value) => {
        console.log(`Selected: ${value}`);
        setMenuVisible(false);
        if(value === "dineIn") {
            navigation.navigate("TablesScreen");
            setAlreadySentCartItems([]);
        }
        if(value === "takeAway") {
            removeTable();
            setAlreadySentCartItems([]);
            navigation.navigate("SaleScreen");
        }
        if(value === "delivery") {
            removeTable();
        }
    };

    return (
            <View style={[styles.header, { zIndex: 9999999 }]}>
                <TouchableOpacity onPress={() => navigation.navigate("TicketEntry")} activeOpacity={0.9}>
                    <Icon name="ticket" size={30} style={[styles.icon]} />
                </TouchableOpacity>

                <View style={styles.searchContainer}>
                    <Icon name="search" size={20} style={styles.searchIcon} />
                    <TextInput
                        ref={inputRef}
                        style={[styles.searchInput, { color: props.searchString.length > 0 ? '#575757' : '#575757' }]}
                        placeholder="Keresés..."
                        onPress={focusInput}
                        value={props.searchString} onChangeText={props.setSearchString}
                    />
                </View>
                <TouchableOpacity activeOpacity={0.6} onPress={() => navigation.navigate("NotificationsScreen")}>
                    <IonIcons name="notifications" size={30} style={styles.icon} />
                </TouchableOpacity>
                <Menu
                    visible={menuVisible}
                    onDismiss={() => setMenuVisible(false)}
                    anchor={
                        <TouchableOpacity activeOpacity={0.6} onPress={() => setMenuVisible(true)}>
                            <IonIcons name="grid" size={30} style={styles.icon}/>
                        </TouchableOpacity>
                    }
                    contentStyle={styles.menuContent}
                    style={styles.menu}
                >
                    <Menu.Item titleStyle={{color: '#000', fontWeight: "900"}} contentStyle={{color: '#000'}}
                               color={"#000"} rippleColor={'rgba(185,185,185,0.3)'}
                               onPress={() => handleSelect('takeAway')} title="Gyorsnyugta"
                               leadingIcon="food-takeout-box"/>
                    <Menu.Item titleStyle={{color: '#F1B44C', fontWeight: "900"}} contentStyle={{color: '#F1B44C'}}
                               color={"#F1B44C"} rippleColor={"rgba(255,215,151,0.3)"}
                               onPress={() => handleSelect('dineIn')} title="Asztaltérkép"
                               leadingIcon="silverware-fork-knife"/>
                    <Menu.Item titleStyle={{color: '#50A5F1', fontWeight: "900"}} contentStyle={{color: '#50A5F1'}}
                               color={'#50A5F1'} rippleColor={'rgba(154,203,248,0.3)'}
                               onPress={() => handleSelect('delivery')} title="Házhozszállítás"
                               leadingIcon="truck-delivery"/>
                </Menu>
            </View>
    );
};

const styles = StyleSheet.create({
    header: {
        height: 60,
        maxHeight: 60,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingHorizontal: 10,
        border: 0,
    },
    icon: {
        paddingHorizontal: 10,
        color: '#6e6e6e',
    },
    searchContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        borderColor: '#bebebe',
        borderWidth: 1,
        borderRadius: 20,
        paddingHorizontal: 15,
        marginHorizontal: 15,
    },
    searchResultsContainer: {
        padding: 10,
        backgroundColor: '#fff',
        borderRadius: 10,
        margin: 10,
    },
    searchResultItem: {
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
    },
    searchIcon: {
        marginRight: 10,
        color: '#aaa',
    },
    searchInput: {
        flex: 1,
        height: 40,
        zIndex: 99999,
    },
    overlay: {
        flex: 1,
        width: '100%',
        height: Dimensions.get('screen').height + 50,
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,0.85)',
        marginTop: 0,
        zIndex: 999999999,
    },
    menu: {
        width: 240,
        position: 'absolute',
        top: 0,
        zIndex: 67234,
        backgroundColor: '#fff',
    },
    menuContent: {
        paddingVertical: 0,
        zIndex: 67234,
        backgroundColor: '#fff',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.40,
        shadowRadius: 1.41,

        elevation: 2,
    },
});

export default CustomHeader;