import React, {useState} from 'react';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {Alert, Dimensions, StyleSheet, View} from "react-native";
import Icon from 'react-native-vector-icons/FontAwesome';
import SettingsScreen from './SettingsScreen';
import SaleScreen from "./SaleScreen";
import CartScreen from "./CartScreen";
import {CartContext} from '../helpers/CartContext';
import StatisticsScreen from "./StatisticsScreen";
import BigSaleScreen from './BigSaleScreen';
import SuccessModal from "./SuccessModal";
import {useRoute} from "@react-navigation/native";
import FailModal from "./FailModal";
import FunctionsScreen from "./FunctionsScreen";

const Tab = createBottomTabNavigator();
const {width} = Dimensions.get('window');
export default function HomeScreen({navigation}) {
    const {cartItems = []} = React.useContext(CartContext);
    const route = useRoute();
    const [successModalVisible, setSuccessModalVisible] = useState(false);
    const [failModalVisible, setFailModalVisible] = useState(false);
    React.useEffect(() => {
        if (route.params?.paymentSuccess !== undefined) {
            if (route.params?.paymentSuccess === true) {
                setSuccessModalVisible(true);
                navigation.navigate('Sale'); // Navigate to the first tab
            } else {
                setFailModalVisible(true);

            }
        }
    }, [route.params]);


    const handleCloseModal = () => {
        setSuccessModalVisible(false);
        setFailModalVisible(false);
        navigation.setParams({paymentSuccess: undefined});
    };


    return (<View style={{flex: 1}}>
            <Tab.Navigator
                detachInactiveScreens={false}
                tabBarLabelPosition={"below-icon"}
                lazy={false}
                backBehavior={"history"}
                swipeEnabled={true}
                headerMode={"none"}
                screenOptions={{headerShown: false}}
                tabBarOptions={{
                    activeTintColor: '#a26909',
                    inactiveTintColor: '#575757',
                    activeBackgroundColor: '#f0f0f0',
                    inactiveBackgroundColor: '#ffffff',
                    showLabel: true,
                    labelStyle: {
                        fontSize: 14,
                        fontWeight: 'bold',
                    },
                    tabStyle: {
                        paddingVertical: 10,
                        height: 70,
                        marginTop: -20
                    },
                    style: {
                        height: 70,
                        backgroundColor: '#e0e0e0',
                    }
                }}
            >
                {width < 768 ? (
                    <>
                        <Tab.Screen name="Sale" component={SaleScreen}
                                    options={{
                                        tabBarIcon: ({color, size}) => (
                                            <Icon name="home" color={color} size={size}/>
                                        ),
                                        title: 'Értékesítés',
                                        tabBarLabel: 'Értékesítés',
                                    }}/>
                        <Tab.Screen name="Cart" component={CartScreen}
                                    options={{
                                        tabBarIcon: ({color, size}) => (
                                            <Icon name="shopping-basket" color={color} size={size}/>
                                        ),
                                        title: 'Kosárban lévő termékek',
                                        tabBarLabel: 'Kosár',
                                        ...((cartItems.length > 0) && {tabBarBadge: cartItems.length || 0}),
                                    }}/>
                    </>
                ) : (
                    <Tab.Screen name="BigSale" component={BigSaleScreen}
                                options={{
                                    tabBarIcon: ({color, size}) => (
                                        <Icon name="home" color={color} size={size}/>
                                    ),
                                    title: 'Értékesítés',
                                    tabBarLabel: 'Értékesítés',
                                    numColumns: 3,
                                }}/>
                )}
                <Tab.Screen name="Functions" component={FunctionsScreen}
                            options={{
                                tabBarIcon: ({color, size}) => (
                                    <Icon name="server" color={color} size={size}/>
                                ),
                                title: 'Funkciók és műveletek',
                                tabBarLabel: 'Funkciók',
                            }}/>
                <Tab.Screen name="Settings" component={SettingsScreen}
                            options={{
                                tabBarIcon: ({color, size}) => (
                                    <Icon name="wrench" color={color} size={size}/>
                                ),
                                title: "Fiókom és beállítás",
                                tabBarLabel: 'Beállítások',
                            }}/>
            </Tab.Navigator>


            <SuccessModal visible={successModalVisible} onClose={handleCloseModal}/>
            <FailModal visible={failModalVisible} onClose={handleCloseModal}/>
        </View>
    );
}

const styles = StyleSheet.create({
    background: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
});
