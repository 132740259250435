import * as React from 'react';
import {Button, Dialog, PaperProvider, Portal, Text} from 'react-native-paper';
import {Modal} from "react-native";

const HappyAlert = ({visible, onConfirm, onCancel, type, message, confirmText, cancelText}) => {
    /*
    *   visible={boolean}
    *    onConfirm={callback}
    *    onCancel={callback}
    *    type={string} // alert, error, warning, info *optional*
    *    message={string}
    *    confirmText={confirmButtonText} // string *optional*
    *    cancelText={cancelButtonText}   // string *optional*
    */

    function getIcon() {
        switch (type) {
            case "alert":
                return "alert";
            case "error":
                return "alert-circle";
            case "warning":
                return "alert-decagram";
            case "info":
                return "information";
            default:
                return "information-outline";
        }
    }

    function getTitle() {
        switch (type) {
            case "alert":
                return "Figyelem!";
            case "error":
                return "Hiba!";
            case "warning":
                return "Figyelmeztetés!";
            case "info":
                return "Információ!";
            default:
                return "Információ!";
        }
    }

    return (
        <>
            {visible && (
            <Modal
                    animationType="slide"
                    transparent={true}
                    visible={visible}
                    onRequestClose={() => onCancel()}
                >
                <PaperProvider>
                    <Portal>
                        <Dialog visible={visible} onDismiss={onCancel}>
                            <Dialog.Icon icon={getIcon()}/>
                            <Dialog.Title>{getTitle()}</Dialog.Title>
                            <Dialog.Content>
                                <Text variant="bodyMedium">{message || "Biztosan elvégzi ezt a műveletet?"}</Text>
                            </Dialog.Content>
                            <Dialog.Actions>
                                <Button onPress={onCancel}>{cancelText || "Mégsem"}</Button>
                                <Button onPress={onConfirm}>{confirmText || "Megerősít"}</Button>
                            </Dialog.Actions>
                        </Dialog>
                    </Portal>
                </PaperProvider>
            </Modal>
            )}
        </>

    );
};

export default HappyAlert;