import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Dimensions } from 'react-native';
const { width, height } = Dimensions.get('window');
import AntDesign from '@expo/vector-icons/AntDesign';

const SuccessModal = ({ visible, onClose }) => {
    if (!visible) return null;

    return (
        <View style={styles.backdrop}>
            <View style={styles.modal}>
                <Text style={styles.logo}>Sikeres rendelés!</Text>
                <View style={styles.content}>
                    <AntDesign name="checkcircle" size={68} color="#FFF" style={{ paddingBottom: 30 }} />
                    <Text style={[styles.logo, styles.orderId]}>A rendelés azonosítója: #246-244</Text>
                    <TouchableOpacity style={styles.button} onPress={onClose}>
                        <Text style={styles.buttonText}>Tovább a tételekhez</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    backdrop: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    modal: {
        width: width * 0.34,
        padding: 20,
        height: height * 0.5,
        backgroundColor: '#81F174',
        borderRadius: 10,
        alignItems: 'center',
    },
    content: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    logo: {
        fontSize: 40,
        fontWeight: 'bold',
        color: '#FFF',
    },
    orderId: {
        fontSize: 20,
        textAlign: 'center',
        color: 'green',
        marginBottom: 20,
        marginTop: 10,
    },
    button: {
        backgroundColor: 'green',
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 5,
    },
    buttonText: {
        color: 'white',
        fontSize: 16,
    },
});

export default SuccessModal;