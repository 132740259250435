import React, {useContext} from 'react';
import {
    Dimensions,
    FlatList,
    SafeAreaView,
    ScrollView,
    StatusBar,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
} from 'react-native';
import {LinearGradient} from 'expo-linear-gradient';
import {PieChart} from "react-native-chart-kit";
import TimeAgo from "react-native-timeago";
import {priceFormatter} from '../helpers/numbers';
import {OrdersContext} from "../helpers/OrdersContext";
import StatisticsHelper from "../helpers/StatisticsHelper";
import Observables from "@happy-gastro/global-observables";

export default function StatisticsScreen({navigation}) {
    const {orders} = useContext(OrdersContext);
    const {productsSum, statistics} = StatisticsHelper(orders);

    const [refreshing, setRefreshing] = React.useState(false);

    const onRefresh = async () => {
        setRefreshing(true);
        Observables.call("refreshOrders");
        setRefreshing(false);
    };
    return (
        <LinearGradient
            colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']}
            style={{flex: 1, width: '100%', height: '100%'}}
        >
            <SafeAreaView style={{flex: 1, backgroundColor: 'transparent', paddingTop: 20}}>
                <StatusBar backgroundColor="transparent" translucent barStyle="dark-content"/>
                <ScrollView>
                    <View style={styles.header}>
                        <Text numberOfLines={1} style={[styles.headerTitle, {marginBottom: 20}]}>
                            Statisztikák
                        </Text>
                    </View>


                    <ScrollView style={styles.container}>
                        {/* Traffic Data */}
                        <View style={styles.section}>
                            <Text style={styles.title}>Forgalmi adatok</Text>
                            <View style={styles.row}>
                                <Text>Termék forgalom:</Text>
                                <Text>{priceFormatter(statistics.productsSum || 0)}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text>Szervízdíjak:</Text>
                                <Text>{priceFormatter(statistics.serviceFeePrice || 0)}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text style={{fontWeight: "700", fontSize: 17}}>Teljes forgalom:</Text>
                                <Text style={{
                                    fontWeight: "700",
                                    fontSize: 17
                                }}>{priceFormatter((statistics.finalSum) || 0)}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text>Borravaló (Gyorsnyugta):</Text>
                                <Text>{priceFormatter(statistics?.tipAmount?.takeAway || 0)}</Text>
                            </View>
                        </View>

                        {/* Payment Method */}
                        <View style={styles.section}>
                            <Text style={styles.title}>Fizetési mód</Text>
                            <View style={styles.row}>
                                <Text>Készpénz:</Text>
                                <Text>{statistics.cashOrders.length} rendelés
                                    / {priceFormatter(statistics.cashOrders.reduce((a, b) => +a + b.orderSum, 0))}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text>Üzlet bankkártya:</Text>
                                <Text>{statistics.creditCardOrders.length} rendelés
                                    / {priceFormatter(statistics.creditCardOrders.reduce((a, b) => +a + b.orderSum, 0))}</Text>
                            </View>
                            <View style={styles.row}>
                                <Text>Online bankkárty:</Text>
                                <Text>{statistics.onlineCreditCardOrders.length} rendelés
                                    / {priceFormatter(statistics.onlineCreditCardOrders.reduce((a, b) => +a + b.orderSum, 0))}</Text>
                            </View>
                            <PieChart
                                data={[
                                    {
                                        name: 'Készpénz',
                                        population: statistics.cashOrders.reduce((a, b) => +a + b.orderSum, 0),
                                        color: '#50b600',
                                        legendFontColor: '#7F7F7F',
                                        legendFontSize: 12
                                    },
                                    {
                                        name: 'Üzlet BK',
                                        population: statistics.creditCardOrders.reduce((a, b) => +a + b.orderSum, 0),
                                        color: '#4287f5',
                                        legendFontColor: '#7F7F7F',
                                        legendFontSize: 12
                                    },
                                    {
                                        name: 'Online BK',
                                        population: statistics.onlineCreditCardOrders.reduce((a, b) => +a + b.orderSum, 0),
                                        color: '#5345c9',
                                        legendFontColor: '#7F7F7F',
                                        legendFontSize: 12
                                    },
                                    {
                                        name: 'Utalvány',
                                        population: 0,
                                        color: '#e08a55',
                                        legendFontColor: '#7F7F7F',
                                        legendFontSize: 12
                                    },
                                ]}
                                width={Dimensions.get('screen').width - 32}
                                height={220}
                                chartConfig={{
                                    backgroundColor: '#1cc910',
                                    backgroundGradientFrom: '#eff3ff',
                                    backgroundGradientTo: '#efefef',
                                    color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
                                }}
                                accessor="population"
                                backgroundColor="transparent"
                                paddingLeft="15"
                                absolute
                            />
                        </View>

                        {/* VAT Breakdown */}
                        <View style={styles.section}>
                            <Text style={styles.title}>Áfa bontás</Text>
                            <View style={styles.tableContainer}>
                                <View style={styles.tableRow}>
                                    <Text style={styles.tableCell}>27%</Text>
                                    <Text style={styles.tableCell}>0 Ft</Text>
                                    <Text style={styles.tableCell}>0 Ft</Text>
                                    <Text style={styles.tableCell}>0 Ft</Text>
                                </View>
                            </View>
                        </View>

                        {/* Invoice List */}
                        <View style={styles.section}>
                            <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                                <Text style={styles.title}>Számla lista</Text>
                                <Text style={styles.title}>({statistics.orders.length} db)</Text>
                            </View>
                            <FlatList
                                data={[
                                    ...statistics.orders.slice(0, 5).map(order => ({
                                        id: '#' + order.orderCode,
                                        amount: priceFormatter(order.orderSum),
                                        when: order.createdAt,
                                        orderData: order
                                    })),
                                ]}
                                renderItem={({item}) => (
                                    <TouchableOpacity style={[styles.row, {marginVertical: 10}]}
                                                      onPress={() => navigation.navigate('OrderDetailsScreen', {orderData: item.orderData})}>
                                        <Text style={{fontWeight: "700", flex: 1}}><TimeAgo time={new Date(item.when)}
                                                                                          interval={10}/></Text>
                                        <Text style={{fontWeight: "700", flex: 1, textAlign: 'right'}}>{item.id}</Text>
                                        <Text
                                            style={{fontWeight: "700", flex: 1, textAlign: 'right'}}>{item.amount}</Text>
                                    </TouchableOpacity>
                                )}
                                keyExtractor={(item) => item.id}
                            />
                            {statistics.orders.length > 5 &&
                                <TouchableOpacity style={[styles.row, {marginVertical: 10}]}
                                                  onPress={() => {
                                                  }}>
                                    <Text style={{flex: 1, textAlign: 'center'}}>Teljes számla lista</Text>
                                </TouchableOpacity>}
                        </View>

                        {/* Other Traffic Data */}
                        <View style={[styles.section, {marginBottom: 80}]}>
                            <Text style={styles.title}>Egyéb forgalmi adatok</Text>
                            <View style={styles.row}>
                                <Text>Tétel stornó:</Text>
                                <Text>0 db / 0 Ft</Text>
                            </View>
                            <View style={styles.row}>
                                <Text>Átlagos érték:</Text>
                                <Text>-- Ft</Text>
                            </View>
                        </View>
                    </ScrollView>
                </ScrollView>
                {false && <View style={styles.fixedButtonContainer}>
                    <TouchableOpacity style={styles.closeDayButton}
                                      onPress={() => navigation.navigate('DayClosingScreen')}>
                        <Text style={styles.closeDayButtonText}>Nap zárása</Text>
                    </TouchableOpacity>
                </View>}
            </SafeAreaView>
        </LinearGradient>
    );
}

const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

const chartConfig = {
    backgroundGradientFrom: '#fff',
    backgroundGradientTo: '#fff',
    color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
    labelColor: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
    strokeWidth: 2,
    barPercentage: 0.5,
};

const styles = StyleSheet.create({
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        paddingHorizontal: 16,
    },
    headerTitle: {
        fontSize: 19,
        fontWeight: "600",
        color: '#000',
        textAlign: 'center',
        marginTop: -7
    },
    content: {
        paddingHorizontal: 16,
        backgroundColor: 'transparent',
    },
    fixedButtonContainer: {
        width: '100%',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: 20,
        backgroundColor: 'transparent',
        alignItems: 'center',
        justifyContent: 'center',
    },
    fullWidthBox: {
        width: '100%',
        padding: 20,
        backgroundColor: '#FEFEFE',
        borderRadius: 12,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 10,
    },
    sectionTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginTop: 20,
        marginBottom: 10,
    },
    statsContainer: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginBottom: 20,
    },
    statText: {
        fontSize: 16,
    },
    tableContainer: {
        marginBottom: 20,
    },
    tableRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingVertical: 5,
    },
    tableCell: {
        flex: 1,
        textAlign: 'center',
        fontSize: 16,
    },
    orderItem: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 15,
        backgroundColor: '#fff',
        borderRadius: 10,
        marginBottom: 10,
    },
    orderId: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    orderSum: {
        fontSize: 16,
        color: '#333',
    },
    closeDayButton: {
        backgroundColor: 'orange',
        padding: 15,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
        margin: 15,
        width: '100%',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 5,
        },
        shadowOpacity: 0.36,
        shadowRadius: 6.68,

        elevation: 11,
    },
    closeDayButtonText: {
        fontSize: 20,
        color: '#fff',
        fontWeight: 'bold',
    },
    boxText: {
        fontSize: 18,
        fontWeight: '600',
        color: '#292929',
    },

    container: {
        flex: 1,
        //backgroundColor: '#fff',
        marginHorizontal: 16,
        marginBottom: 20
    },
    section: {
        marginBottom: 24,
        padding: 16,
        backgroundColor: '#f9f9f9',
        borderRadius: 8,
        shadowColor: '#000',
        shadowOpacity: 0.1,
        shadowRadius: 4,
        shadowOffset: {width: 0, height: 2},
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 8,
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 8,
    },
});