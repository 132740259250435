import React, { useEffect } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Popup } from 'react-native-popup-confirm-toast';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { setAccessToken, setUserInfo } from '../helpers/storage';
import { useNavigation } from '@react-navigation/native';
import {Icon} from "react-native-elements";

const RestaurantPlaceChooser = ({ data }) => {
    const navigation = useNavigation();

    const handlePlaceSelection = async (place) => {
        await setUserInfo(data.user);
        await setAccessToken(data.token);

        await AsyncStorage.setItem('lastUser', JSON.stringify(data.user));
        await AsyncStorage.setItem('restaurant', JSON.stringify(data.user.restaurants[0]));
        await AsyncStorage.setItem('restaurantPlace', JSON.stringify(place));

        Popup.hide();
        navigation.navigate('HomeScreen');
    };

    const bodyComponent = ({ bodyProps }) => (
        <View
            style={{
                padding: 20,
                width: '100%',
                backgroundColor: 'white',
                borderRadius: 15,
                shadowColor: '#000',
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.2,
                shadowRadius: 5,
                elevation: 5,
            }}
            onLayout={(e) => {
                if (bodyProps.onLayout) bodyProps.onLayout(e);
            }}
        >
            <Text
                style={{
                    fontSize: 18,
                    fontWeight: 'bold',
                    marginBottom: 20,
                    textAlign: 'center',
                }}
            >
                Válasszon egy üzletet
            </Text>
            {data?.user?.restaurants[0]?.places?.map((place, index) => (
                <TouchableOpacity
                    key={index}
                    style={{
                        backgroundColor: '#ffedd5',
                        borderRadius: 10,
                        padding: 18,
                        marginBottom: 15,
                        alignItems: 'center',
                        shadowColor: '#000',
                        shadowOffset: { width: 1, height: 1 },
                        shadowOpacity: 0.2,
                        shadowRadius: 3,
                        elevation: 2,
                    }}
                    onPress={() => handlePlaceSelection(place)}
                    activeOpacity={0.7}
                >
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <Icon name="store" size={30} color="#333" style={{ marginRight: 10 }} />
                        <Text style={{ color: '#333', fontSize: 16, fontWeight: '600' }}>
                            {place.name || place.raw}
                        </Text>
                    </View>
                </TouchableOpacity>
            ))}
        </View>
    );

    useEffect(() => {
        Popup.show({
            type: 'confirm',
            useNativeDriver: true,
            label: 'Üzlet kiválasztása',
            textBody: 'Válassz üzletet a továbblépéshez:',
            bodyComponentForce: true,
            bodyComponent: (bodyProps) => bodyComponent({ bodyProps }),
            iconEnabled: true,
            buttonEnabled: false,
        });
    }, []);

    return null;
};

export default RestaurantPlaceChooser;
