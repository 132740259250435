import React, { useState, useRef } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, ScrollView } from 'react-native';
import { Modal, Portal, Button, TextInput } from 'react-native-paper';
import { MaterialCommunityIcons } from "@expo/vector-icons";

const VatModal = ({ visible, onDismiss, onSubmit }) => {
    const [tab, setTab] = useState('company');
    const [step, setStep] = useState(0);
    const [selectedType, setSelectedType] = useState('company');
    const [suggestions, setSuggestions] = useState([]);
    const [focusedField, setFocusedField] = useState(null);
    const [error, setError] = useState('');

    const formRef = useRef({
        firstName: '',
        lastName: '',
        name: '',
        taxRefNum: '',
        postCode: '',
        city: '',
        address: '',
        streetType: '',
        houseNumber: '',
        comment: '',
        email: '',
        phone: '',
        type: 'company',
        arbev: '',
        cegj_sz: '',
        fullAddress: '',
        oo_cegj_sz: '',
    });

    const handleInputChange = async (name, value) => {
        formRef.current[name] = value;
        if (name === 'type') {
            setSelectedType(value);
        } else if (name === 'name') {
            if (value.length < 2) return;
            try {
                const response = await fetch(`https://api.happygastro.hu/api/restaurant/name/${value}/search`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                const data = await response.json();
                setSuggestions(data?.suggestions);
            } catch (error) {
                console.error('Error fetching company data:', error);
            }
        }
    };

    const handleEmailOrPhoneChange = (value) => {
        formRef.current.emailOrPhone = value;
    };

    const handleSuggestionSelect = (suggestion) => {
        const regex = /(\d{4})\s+([^\d]+),\s+(.+)\s+(akna|allé|alsó rakpart|alsósor|árok|átjáró|bekötőút|bokor|dűlő|dűlőút|erdősor|fasor|felső rakpart|felsősor|forduló|főtér|főút|gát|határ|határsor|határút|ipartelep|kapu|kert|kertsor|korzó|környék|körönd|körtér|körút|köz|lakópark|lakótelep|lejáró|lejtő|lépcső|lépcsősor|liget|major|majorság|mélyút|negyed|oldal|orom|országút|ösvény|park|part|pincesor|puszta|rakpart|sétány|sikátor|sor|sugárút|szállás|szektor|szél|szer|sziget|szőlőhegy|tag|tanya|telep|tér|tere|tető|udvar|út|utca|útja|üdülőpart|üdülősor|üdülőtelep|vár|várkert|város|villasor|völgy|zug|u)\s+(\d+)/i;
        const match = suggestion.cim.match(regex);

        if (match) {
            formRef.current.postCode = match[1];
            formRef.current.city = match[2];
            formRef.current.address = match[3];
            formRef.current.streetType = match[4];
            formRef.current.houseNumber = match[5];
        }

        formRef.current.name = suggestion.nev;
        formRef.current.taxRefNum = suggestion.adoszam;
        formRef.current.arbev = suggestion.arbev;
        formRef.current.cegj_sz = suggestion.cegj_sz;
        formRef.current.fullAddress = suggestion.cim;
        formRef.current.oo_cegj_sz = suggestion.oo_cegj_sz;
        setSuggestions([]);
    };

    const handleNextStep = () => {
        const emailOrPhone = formRef.current.emailOrPhone;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const phoneRegex = /^(\+|0)\d{9,14}$/;

        if (emailRegex.test(emailOrPhone)) {
            formRef.current.email = emailOrPhone;
            formRef.current.phone = '';
            setError('');
        } else if (phoneRegex.test(emailOrPhone)) {
            formRef.current.phone = emailOrPhone;
            formRef.current.email = '';
            setError('');
        } else {
            setError('Please enter a valid email or phone number');
            return;
        }

        if (formRef.current.type) {
            setTab(formRef.current.type);
            setStep(1);
        }
    };

    const InitialStep = () => (
        <View>
            <Text style={styles.title}>Áfás számla</Text>
            <View style={styles.companyRow}>
                <TouchableOpacity
                    style={[styles.tab, selectedType === 'company' && styles.activeTab]}
                    onPress={() => handleInputChange('type', 'company')}
                >
                    <MaterialCommunityIcons name="briefcase-account" size={30} color={selectedType === "company" ? '#fff' : '#666'} />
                    <Text style={[styles.tabText, selectedType === 'company' && styles.activeTabText]}>Céges</Text>
                </TouchableOpacity>
                <TouchableOpacity
                    style={[styles.tab, selectedType === 'noncompany' && styles.activeTab]}
                    onPress={() => handleInputChange('type', 'noncompany')}
                >
                    <MaterialCommunityIcons name="account" size={30} color={selectedType === "noncompany" ? '#fff' : '#666'} />
                    <Text style={[styles.tabText, selectedType === 'noncompany' && styles.activeTabText]}>Magán</Text>
                </TouchableOpacity>
            </View>
            {selectedType === 'noncompany' && (
                <View style={styles.row}>
                    <TextInput
                        label="Keresztnév"
                        defaultValue={formRef.current.firstName}
                        onChangeText={(text) => handleInputChange('firstName', text)}
                        style={[styles.input, styles.halfInput]}
                        autoFocus={focusedField === 'firstName'}
                        onFocus={() => setFocusedField('firstName')}
                    />
                    <TextInput
                        label="Vezetéknév"
                        defaultValue={formRef.current.lastName}
                        onChangeText={(text) => handleInputChange('lastName', text)}
                        style={[styles.input, styles.halfInput]}
                        autoFocus={focusedField === 'lastName'}
                        onFocus={() => setFocusedField('lastName')}
                    />
                </View>
            )}
            <TextInput
                label="Email vagy telefonszám"
                defaultValue={formRef.current.emailOrPhone}
                onChangeText={handleEmailOrPhoneChange}
                style={styles.input}
                autoFocus={focusedField === 'emailOrPhone'}
                onFocus={() => setFocusedField('emailOrPhone')}
                error={!!error}
            />
            {error ? <Text style={styles.errorText}>{error}</Text> : null}
            <Button mode="contained" onPress={handleNextStep} style={styles.button}>
                <Text style={styles.buttonText}>Tovább</Text>
            </Button>
        </View>
    );

    const CompanyRoute = () => (
        <View>
            <Text style={styles.title}>Céges adatok</Text>
            <TextInput
                label="Cég Név"
                defaultValue={formRef.current.name}
                onChangeText={(text) => handleInputChange('name', text)}
                style={styles.input}
                autoFocus={focusedField === 'name'}
                onFocus={() => setFocusedField('name')}
            />
            {suggestions.length > 0 && (
                <ScrollView style={styles.suggestionsBox}>
                    {suggestions.map((suggestion, index) => (
                        <TouchableOpacity key={index} onPress={() => handleSuggestionSelect(suggestion)}>
                            <Text style={styles.suggestionText}>{suggestion.nev}</Text>
                        </TouchableOpacity>
                    ))}
                </ScrollView>
            )}
            <TextInput
                label="Adószám"
                defaultValue={formRef.current.taxRefNum}
                onChangeText={(text) => handleInputChange('taxRefNum', text)}
                style={styles.input}
                autoFocus={focusedField === 'taxRefNum'}
                onFocus={() => setFocusedField('taxRefNum')}
            />
            <View style={styles.row}>
                <TextInput
                    label="Iranyítószám"
                    defaultValue={formRef.current.postCode}
                    onChangeText={(text) => handleInputChange('postCode', text)}
                    style={[styles.input, styles.postCode]}
                    autoFocus={focusedField === 'postCode'}
                    onFocus={() => setFocusedField('postCode')}
                />
                <TextInput
                    label="Város"
                    defaultValue={formRef.current.city}
                    onChangeText={(text) => handleInputChange('city', text)}
                    style={[styles.input, styles.city]}
                    autoFocus={focusedField === 'city'}
                    onFocus={() => setFocusedField('city')}
                />
            </View>
            <TextInput
                label="Címsor"
                defaultValue={formRef.current.address}
                onChangeText={(text) => handleInputChange('address', text)}
                style={styles.input}
                autoFocus={focusedField === 'address'}
                onFocus={() => setFocusedField('address')}
            />
            <View style={styles.row}>
                <TextInput
                    label="Közterület típusa"
                    defaultValue={formRef.current.streetType}
                    onChangeText={(text) => handleInputChange('streetType', text)}
                    style={[styles.input, styles.streetType]}
                    autoFocus={focusedField === 'streetType'}
                    onFocus={() => setFocusedField('streetType')}
                />
                <TextInput
                    label="Házszám"
                    defaultValue={formRef.current.houseNumber}
                    onChangeText={(text) => handleInputChange('houseNumber', text)}
                    style={[styles.input, styles.number]}
                    autoFocus={focusedField === 'houseNumber'}
                    onFocus={() => setFocusedField('houseNumber')}
                />
            </View>
            <View style={styles.row}>
                <TextInput
                    label="Megjegyzés"
                    defaultValue={formRef.current.comment}
                    onChangeText={(text) => handleInputChange('comment', text)}
                    style={[styles.input, styles.number]}
                    autoFocus={focusedField === 'comment'}
                    onFocus={() => setFocusedField('comment')}
                />
            </View>
            <Button mode="contained" onPress={() => {
                onSubmit(formRef.current);
            }} style={styles.button}>
                <Text style={styles.buttonText}>Submit</Text>
            </Button>
        </View>
    );

    const NonCompanyRoute = () => (
        <View>
            <Text style={styles.title}>Személyes adatok</Text>
            <TextInput
                label="Adószám"
                defaultValue={formRef.current.taxRefNum}
                onChangeText={(text) => handleInputChange('taxRefNum', text)}
                style={styles.input}
                autoFocus={focusedField === 'taxRefNum'}
                onFocus={() => setFocusedField('taxRefNum')}
            />
            <View style={styles.row}>
                <TextInput
                    label="Iranyítószám"
                    defaultValue={formRef.current.postCode}
                    onChangeText={(text) => handleInputChange('postCode', text)}
                    style={[styles.input, styles.postCode]}
                    autoFocus={focusedField === 'postCode'}
                    onFocus={() => setFocusedField('postCode')}
                />
                <TextInput
                    label="Város"
                    defaultValue={formRef.current.city}
                    onChangeText={(text) => handleInputChange('city', text)}
                    style={[styles.input, styles.city]}
                    autoFocus={focusedField === 'city'}
                    onFocus={() => setFocusedField('city')}
                />
            </View>
            <TextInput
                label="Címsor"
                defaultValue={formRef.current.address}
                onChangeText={(text) => handleInputChange('address', text)}
                style={styles.input}
                autoFocus={focusedField === 'address'}
                onFocus={() => setFocusedField('address')}
            />
            <View style={styles.row}>
                <TextInput
                    label="Közterület típusa"
                    defaultValue={formRef.current.streetType}
                    onChangeText={(text) => handleInputChange('streetType', text)}
                    style={[styles.input, styles.streetType]}
                    autoFocus={focusedField === 'streetType'}
                    onFocus={() => setFocusedField('streetType')}
                />
                <TextInput
                    label="Házszám"
                    defaultValue={formRef.current.houseNumber}
                    onChangeText={(text) => handleInputChange('houseNumber', text)}
                    style={[styles.input, styles.number]}
                    autoFocus={focusedField === 'houseNumber'}
                    onFocus={() => setFocusedField('houseNumber')}
                />
            </View>
            <View style={styles.row}>
                <TextInput
                    label="Megjegyzés"
                    defaultValue={formRef.current.comment}
                    onChangeText={(text) => handleInputChange('comment', text)}
                    style={[styles.input, styles.number]}
                    autoFocus={focusedField === 'comment'}
                    onFocus={() => setFocusedField('comment')}
                />
            </View>
            <Button mode="contained" onPress={() => {
                onSubmit(formRef.current);
            }} style={styles.button}>
                <Text style={styles.buttonText}>Mentés</Text>
            </Button>
        </View>
    );

    return (
        <Portal>
            <Modal visible={visible} onDismiss={onDismiss} contentContainerStyle={styles.modalContainer}>
                {step === 0 ? (
                    <InitialStep />
                ) : (
                    <>
                        {selectedType === 'company' ? <CompanyRoute /> : <NonCompanyRoute />}
                    </>
                )}
            </Modal>
        </Portal>
    );
};

const styles = StyleSheet.create({
    modalContainer: {
        backgroundColor: 'white',
        padding: 20,
        margin: 20,
        borderRadius: 10,
        minHeight: "fit-content",
    },
    input: {
        marginBottom: 10,
    },
    buttonText: {
        fontWeight: 'bold',
        fontSize: 17,
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10,
    },
    companyRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10,
        backgroundColor: '#f1f1f1',
        padding: 10,
        borderRadius: 5,
    },
    tab: {
        flex: 1,
        padding: 12,
        paddingVertical: 16,
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
    },
    activeTab: {
        backgroundColor: '#f1b34b',
    },
    tabText: {
        fontSize: 12,
        color: '#666',
        marginTop: 4,
    },
    activeTabText: {
        color: '#fff',
    },
    postCode: {
        flex: 1,
        marginRight: 5,
    },
    city: {
        flex: 2,
    },
    streetType: {
        flex: 3,
        marginRight: 5,
    },
    number: {
        flex: 2,
    },
    button: {
        marginTop: 20,
        flex: 1,
        marginHorizontal: 5,
        borderRadius: 5,
        paddingVertical: 10,
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 20,
    },
    suggestionsBox: {
        position: 'absolute',
        top: 110, // Adjust based on the position of the TextInput
        left: 0,
        right: 0,
        maxHeight: 100,
        backgroundColor: '#f1f1f1',
        borderRadius: 5,
        zIndex: 1,
    },
    suggestionText: {
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
    },
    halfInput: {
        flex: 1,
        marginRight: 5,
    },
    errorText: {
        color: 'red',
        marginBottom: 10,
    },
});

export default VatModal;