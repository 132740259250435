import ObjectID from "bson-objectid";

export const setPendingOrders = (count) => ({
	type: "SET_PENDING_ORDERS",
	pendingOrders: count,
});

export const setUpdatesAvailable = (available) => ({
	type: "SET_UPDATES_AVAILABLE",
	available: available,
});

export const setSelectedCategory = (item) => ({
	type: "SET_SELECTED_CATEGORY",
	category: item,
});
export const updateCart = (cartItems) => ({
	type: "UPDATE_CART",
	cartItems: cartItems || [],
});

export const setPrinters = (item) => ({
	type: "SET_PRINTERS",
	printers: item,
});

export const setPrinterEvents = (item) => ({
	type: "SET_PRINTER_EVENTS",
	printerEvents: [...item],
});

export const setAdmins = (items) => ({
	type: "SET_ADMINS",
	admins: [...items],
});

export const setPairingEvents = (item) => ({
	type: "PAIR_PRINTER_EVENTS",
	event: item.event,
	printer: item.printer,
});

export const setFoods = (item) => ({
	type: "SET_FOODS",
	foods: item,
});

export const setGuests = (guests) => ({
	type: "SET_GUESTS",
	guests: guests,
});

export const setOrders = (items) => ({
	type: "SET_ORDERS",
	orders: items,
});
export const addOrder = (order) => ({
	type: "ADD_ORDER",
	order: order,
});
export const changeOrder = (order) => ({
	type: "CHANGE_ORDER",
	order: order,
});
export const setUnpaidOrders = (item) => ({
	type: "SET_UNPAID_ORDERS",
	orders: item,
});

export const setSettings = (item) => ({
	type: "SET_SETTINGS",
	settings: item,
});

export const setFoodCategories = (item) => ({
	type: "SET_FOOD_CATEGORIES",
	categories: item,
});

export const setVipCards = (item) => ({
	type: "SET_VIPCARDS",
	vipCards: item,
});

export const setSearchText = (item) => ({
	type: "SEARCH_TEXT",
	value: item,
});

export const setBarcodeScanned = (item) => ({
	type: "SET_BARCODE",
	value: item,
});

export const setVipCardScanned = (item) => ({
	type: "SET_VIP",
	value: item,
});

export const setStatisticsModal = (item) => ({
	type: "SET_STATISTICS_MODAL",
	visible: item,
});

export const setPaymentModal = (item) => ({
	type: "SET_PAYMENT_MODAL",
	visible: item,
});
export const setSettingsModal = ({ visible = true, modal }) => ({
	type: "SETTINGS_MODAL",
	visible: visible,
	modal: modal,
});
export const setMapModal = (item) => ({
	type: "MAP_MODAL",
	visible: item,
});
export const setTablesModal = (item) => ({
	type: "TABLES_MODAL",
	visible: item,
});
export const setTablesSidebar = (item) => ({
	type: "TABLES_SIDEBAR",
	visible: item,
});
export const setTablesEditor = (item) => ({
	type: "TABLES_EDITOR",
	visible: item,
});
export const setShowLogin = (item) => ({
	type: "SHOW_LOGIN",
	visible: item,
});
export const setAddressModal = (item) => ({
	type: "SET_ADDRESS_MODAL",
	visible: item,
});
export const setDiscountsModal = (item) => ({
	type: "SET_DISCOUNTS_MODAL",
	visible: item,
});
export const setTutorialVisible = (item) => ({
	type: "SET_TUTORIAL_VISIBLE",
	visible: item,
});
export const setCustomerSearchVisible = (item) => ({
	type: "SET_CUSTOMER_MODAL",
	visible: item,
});

export const setTutorialVisited = (value) => ({
	type: "SET_TUTORIAL_VISITED",
	value: value,
});
export const setCashierId = (value) => ({
	type: "SET_CASHIER",
	value: value,
});
export const setCashiers = (value) => ({
	type: "SET_CASHIERS",
	value: value,
});
/*export const addOrderToQueue = (value) => ({
  type: 'ADD_ORDER_TO_QUEUE',
  value: value
});*/

export const setCurrentOrder = (item) => ({
	type: "CURRENT_ORDER",
	order: item,
});

export const setOrderEdit = (item) => ({
	type: "EDIT_ORDER",
	order: item,
});
export const setTransferOrder = (item) => ({
	type: "SET_TRANSFER_ORDER",
	order: item,
});

//// Cart cart actions
export const removeComment = (action) => ({
	guestIndex: action.guestIndex || 1,
	type: "REMOVE_COMMENT",
	commentIndex: action.comment || action.commentIndex || "",
	cartIndex: action.cartIndex || -1,
});

export const addToCart = (action) => ({
	guestIndex: action.guestIndex || 1,
	type: "ADD_CART",
	item: action.item,
	serveType: action.serveType || "",
	sizeIndex: action.sizeIndex || 0,
	extras: action.extras || [],
	quantity: action.quantity || 1,
	cartIndex: Number(action.cartIndex) > -1 ? Number(action.cartIndex) : -1,
	round: action.round || 0,
	_id: action._id || ObjectID().str,
});

export const changeAmount = (action) => ({
	guestIndex: action.guestIndex || 1,
	type: "CHANGE_AMOUNT",
	item: action.item,
	itemIndex: Number(action.itemIndex) || false,
	sizeIndex: action.sizeIndex || 0,
	quantity: action.quantity,
});

export const changeRound = (action) => ({
	type: "CHANGE_ROUND",
	cartIndex: action.cartIndex,
	round: action.round,
});

export const removeFromCart = (item) => ({
	type: "REMOVE_CART",
	item: item,
});
export const showExtras = (item) => ({
	type: "EXTRAS_SHOW",
	item: item,
});
export const hideExtras = () => ({
	type: "EXTRAS_CLOSE",
});
export const emptyCart = () => ({
	type: "EMPTY_CART",
});
export const setCashier = () => ({
	type: "SET_CASHIER",
});
