import React, {useState} from "react";

import {Provider as ThemeProvider} from "react-native-paper";
import {StatusBar, StyleSheet, TouchableOpacity} from "react-native";
import {NavigationContainer} from "@react-navigation/native";
import {createStackNavigator} from "@react-navigation/stack";
import {theme} from "./app/core/theme";
import {
    HomeScreen,
    LoginScreen,
    NotificationsScreen,
    OrderDetailsScreen, SettingsPrinterPairingScreen,
    StartScreen,
    SuccessEntryScreen,
    SuccessPaymentScreen,
    TablesScreen,
    TermsOfServicesScreen,
} from "./app/screens";
import Toast from 'react-native-toast-message';
import SplashScreenComponent from './app/screens/SplashScreen';
import {Feather, FontAwesome, Ionicons, MaterialCommunityIcons, MaterialIcons} from '@expo/vector-icons';
import PaymentScreen from "./app/screens/PaymentScreen";
import ResultScreen from "./app/screens/ResultScreen";
import WaitingForPayment from "./app/screens/WaitingForPayment";
import FailedPaymentScreen from "./app/screens/FailedPaymentScreen";
import TicketEntryScreen from "./app/screens/TicketEntryScreen";
import {CartProvider} from "./app/helpers/CartContext";
import {OrdersProvider} from "./app/helpers/OrdersContext";
import {BluetoothProvider} from "./app/helpers/BluetoothContext";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {Root} from "react-native-popup-confirm-toast";

import FailedEntryScreen from "./app/screens/FailedEntryScreen";
import PaymentsSettingsScreen from "./app/screens/PaymentsSettingsScreen";
import ScanDevicesScreen from "./app/screens/ScanDevicesScreen";
import PrivacyPolicyScreen from "./app/screens/PrivacyPolicyScreen";
import {activateKeepAwakeAsync} from 'expo-keep-awake';

import * as TaskManager from 'expo-task-manager';
import * as BackgroundFetch from 'expo-background-fetch';
import DayClosingScreen from "./app/screens/DayClosingScreen";
import DayOpeningScreen from "./app/screens/DayOpeningScreen";
import DayOpeningConfirmationScreen from "./app/screens/DayOpeningConfirmationScreen";
import Observables from "@happy-gastro/global-observables";
import {SystemProvider} from "./app/helpers/SystemContext";
import DesignSettingsScreen from "./app/screens/DesignSettingsScreen";
import SuccessDayClosingScreen from "./app/screens/SuccessDayClosingScreen";
import SupportChatScreen from "./app/screens/SupportChatScreen";

import {persistor, store} from "./app/store";
import {Provider as ReduxProvider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";

import './happyWebStyles.css';
import {TableProvider} from "./app/helpers/TableContext";
import SettingsPrinterSearchScreen from "./app/screens/SettingsPrinterSearchScreen";
import StatisticsScreen from "./app/screens/StatisticsScreen";
import * as Font from "expo-font";

let moment = require('moment');
require('moment/locale/hu');
moment.locale('hu');


const BACKGROUND_FETCH_TASK = 'happy-services-sync';
TaskManager.defineTask(BACKGROUND_FETCH_TASK, async (result) => {
    try {
        // Perform your background task here
        // Example: Fetch data from an API
        console.log('Background fetch task running');
        // const orders = await fetchOrdersFromAPI();

        return {type: 'BACKGROUND_TASK', action: BACKGROUND_FETCH_TASK, result: [...result]};
    } catch (error) {
        console.error('Background fetch task error:', error);
        return false;
    }
});


const Stack = createStackNavigator();

export default function App({navigation, route}) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [appIsReady, setAppIsReady] = useState(false);
    const [isSplashVisible, setIsSplashVisible] = useState(true);
    const [isDayOpen, setIsDayOpen] = useState(false);


    React.useEffect(() => {

        const keepAwake = async () => {
            await activateKeepAwakeAsync();
        }
        keepAwake();

        Observables.register('reloadDayOpeningClosing', async (data) => {
            const dayOpened = await AsyncStorage.getItem('dayOpened');
            if (dayOpened === 'true' || dayOpened) {
                setIsDayOpen(true);
            } else {
                setIsDayOpen(false);
            }
        });

        const loadFont = async () => {

            await Font.loadAsync({
                "MaterialIcons": require("@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/MaterialIcons.ttf"),
                "FontAwesome": require("@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/FontAwesome.ttf"),
                "FontAwesome5": require("@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/FontAwesome5_Regular.ttf"),
                "Ionicons": require("@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/Ionicons.ttf"),
                "MaterialCommunityIcons": require("@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf"),
                "Feather": require("@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/Feather.ttf")
            });

            const dayOpened = await AsyncStorage.getItem('dayOpened');
            if (dayOpened === 'true' || dayOpened) {
                setIsDayOpen(true);
            } else {
                setIsDayOpen(false);
            }
        };

        loadFont();
    }, []);


    React.useEffect(() => {
        const initBackgroundFetch = async () => {
            try {
                await BackgroundFetch.registerTaskAsync(BACKGROUND_FETCH_TASK, {
                    minimumInterval: 15, // Fetch interval in minutes
                    stopOnTerminate: false, // Continue running after app is terminated
                    startOnBoot: true, // Start task when device is booted
                });
                console.log('Background fetch task registered');
            } catch (error) {
                console.error('Background fetch task registration error:', error);
            }
        };

        initBackgroundFetch();
    }, []);

    const handleAnimationFinish = () => {
        setIsSplashVisible(false);
    };

    React.useEffect(() => {
        if (route?.params?.successCloseDay) {
            setIsDayOpen(route?.params?.successCloseDay || false);
        }
    }, [route]);

    if (isSplashVisible) {
        return <SplashScreenComponent onAnimationFinish={handleAnimationFinish}/>;
    }

    // TODO: Change back to StartScreen instead of HomeScreen, and make isLoggedIn: false
    return (
        <ThemeProvider theme={theme}>
            <NavigationContainer>
                <Root>
                    <ReduxProvider store={store}>
                        <PersistGate loading={null} persistor={persistor}>
                            <OrdersProvider>
                                <SystemProvider>
                                    <TableProvider>
                                    <CartProvider>
                                        <BluetoothProvider>
                                            <StatusBar hidden={true} backgroundColor="transparent"
                                                       barStyle="dark-content"/>
                                            <Stack.Navigator
                                                initialRouteName={!isLoggedIn ? "StartScreen" : (isDayOpen ? "HomeScreen" : "DayOpeningScreen")}>
                                                <Stack.Screen
                                                    name="StartScreen"
                                                    component={StartScreen}
                                                    options={{
                                                        title: 'Kezdőképernyő',
                                                        headerShown: false,
                                                        gestureEnabled: false,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        },
                                                        headerLeft: null, // Disable left icon
                                                        headerRight: null, // Disable right icon
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="LoginScreen"
                                                    component={LoginScreen}
                                                    options={{
                                                        title: 'Bejelentkezés',
                                                        headerShown: false,
                                                        gestureEnabled: false,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        },
                                                        headerLeft: null, // Disable left icon
                                                        headerRight: null, // Disable right icon
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="HomeScreen"
                                                    component={HomeScreen}
                                                    options={{
                                                        title: 'Értékesítés',
                                                        headerShown: false,
                                                        gestureEnabled: false,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        },
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="TablesScreen"
                                                    component={TablesScreen}
                                                    options={{
                                                        title: 'Asztaltérkép',
                                                        headerShown: false,
                                                        gestureEnabled: false,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        },
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="TermsOfServicesScreen"
                                                    component={TermsOfServicesScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Felhasználási feltételek",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        gestureEnabled: false,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        },
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="DayOpeningScreen"
                                                    component={DayOpeningScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Pénzügyi nap nyitása",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        gestureEnabled: false,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        },
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="DayOpeningConfirmationScreen"
                                                    component={DayOpeningConfirmationScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Pénzügyi nap nyitása",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        gestureEnabled: false,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        },
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="DayClosingScreen"
                                                    component={DayClosingScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Pénzügyi nap zárása",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        gestureEnabled: false,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        },
                                                    }}
                                                />

                                                <Stack.Screen
                                                    name="StatisticsScreen"
                                                    component={StatisticsScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Napi kimutatás",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        gestureEnabled: false,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        },
                                                    }}
                                                />
                                                <Stack.Screen
                                                name="SuccessDayClosingScreen"
                                                component={SuccessDayClosingScreen}
                                                options={{
                                                    headerShown: false,
                                                    title: "Sikeres pénzügyi nap zárás",
                                                    headerTitleAlign: 'center', // Center align the title text
                                                    backTitle: "Vissza",
                                                    gestureEnabled: false,
                                                    transitionSpec: {
                                                        open: {animation: 'timing', config: {duration: 500}},
                                                        close: {animation: 'timing', config: {duration: 500}},
                                                    },
                                                }}
                                            />
                                                <Stack.Screen
                                                    name="PrivacyPolicyScreen"
                                                    component={PrivacyPolicyScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Adatvédelmi nyilatkozat",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        gestureEnabled: false,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        },
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="PaymentsSettingsScreen"
                                                    component={PaymentsSettingsScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Fizetési és adóügyi eszközök",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        gestureEnabled: false,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        }
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="DesignSettingsScreen"
                                                    component={DesignSettingsScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Megjelenés beállításai",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        gestureEnabled: false,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        }
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="SettingsPrinterSearchScreen"
                                                    component={SettingsPrinterSearchScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Nyomtatók keresése a hálózaton",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        gestureEnabled: false,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        }
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="SettingsPrinterPairingScreen"
                                                    component={SettingsPrinterPairingScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Nyomtató profilok",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        gestureEnabled: false,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        },
                                                        headerRight: () => (
                                                            <TouchableOpacity style={{marginRight: 15}} onPress={() => navigation.navigate('SettingsPrinterSearchScreen')}>
                                                                <Ionicons name="ios-search" size={24} color="black" />
                                                            </TouchableOpacity>
                                                        ),                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="SupportChatScreen"
                                                    component={SupportChatScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Support támogatás",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        gestureEnabled: false,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        }
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="OrderDetailsScreen"
                                                    component={OrderDetailsScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Rendelés részletei",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        gestureEnabled: false,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        }
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="ScanDevicesScreen"
                                                    component={ScanDevicesScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Vezeték nélküli eszközök",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        gestureEnabled: false,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        }
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="NotificationsScreen"
                                                    component={NotificationsScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Értesítések",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        leftIconColor: '#ffffff',
                                                        gestureEnabled: false,
                                                        headerStyle: {
                                                            backgroundColor: '#393939', // Set the desired background color
                                                        },
                                                        headerTintColor: '#ffffff', // Change the back arrow color here
                                                        headerTitleStyle: {
                                                            color: 'rgba(255,255,255,1)', // Set the desired color
                                                        },
                                                        icon: "#FEFEFE",
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 100}},
                                                            close: {animation: 'timing', config: {duration: 100}},
                                                        },
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="PaymentScreen"
                                                    component={PaymentScreen}
                                                    options={{
                                                        headerShown: false,
                                                        title: "Fizetés",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        leftIconColor: '#ffffff',
                                                        gestureEnabled: false,
                                                        headerStyle: {
                                                            backgroundColor: '#393939', // Set the desired background color
                                                        },
                                                        headerTintColor: '#ffffff', // Change the back arrow color here
                                                        headerTitleStyle: {
                                                            color: 'rgba(255,255,255,1)', // Set the desired color
                                                        },
                                                        icon: "#FEFEFE",
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 100}},
                                                            close: {animation: 'timing', config: {duration: 100}},
                                                        },
                                                        headerLeft: null, // Disable left icon
                                                        headerRight: null, // Disable right icon
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="SuccessPayment"
                                                    component={SuccessPaymentScreen}
                                                    options={{
                                                        title: 'Sikeres fizetés!',
                                                        headerShown: false,
                                                        gestureEnabled: false,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        },

                                                        headerLeft: null, // Disable left icon
                                                        headerRight: null, // Disable right icon
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="FailedPayment"
                                                    component={FailedPaymentScreen}
                                                    options={{
                                                        headerShown: false,
                                                        gestureEnabled: false,
                                                        title: 'Sikertelen fizetés!',
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        },
                                                        headerLeft: null, // Disable left icon
                                                        headerRight: null, // Disable right icon
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="TicketEntry"
                                                    component={TicketEntryScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Beléptetés",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        gestureEnabled: false,
                                                        headerStyle: {
                                                            backgroundColor: '#393939', // Set the desired background color
                                                        },
                                                        headerTintColor: '#ffffff', // Change the back arrow color here
                                                        headerTitleStyle: {
                                                            color: 'rgba(255,255,255,1)', // Set the desired color
                                                        },
                                                        icon: "#FEFEFE",
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 100}},
                                                            close: {animation: 'timing', config: {duration: 100}},
                                                        }
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="SuccessEntryScreen"
                                                    component={SuccessEntryScreen}
                                                    options={{
                                                        headerShown: false,
                                                        title: "Sikeres beléptetés!",
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 100}},
                                                            close: {animation: 'timing', config: {duration: 100}},
                                                        },
                                                        headerLeft: null, // Disable left icon
                                                        headerRight: null, // Disable right icon
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="FailedEntryScreen"
                                                    component={FailedEntryScreen}
                                                    options={{
                                                        headerShown: false,
                                                        title: "Sikertelen beléptetés!",
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 100}},
                                                            close: {animation: 'timing', config: {duration: 100}},
                                                        },
                                                        headerLeft: null, // Disable left icon
                                                        headerRight: null, // Disable right icon
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="ResultScreen"
                                                    component={ResultScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Fizetési eredménye",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        leftIconColor: '#ffffff',
                                                        gestureEnabled: false,
                                                        headerStyle: {
                                                            backgroundColor: '#393939', // Set the desired background color
                                                        },
                                                        headerTintColor: '#ffffff', // Change the back arrow color here
                                                        headerTitleStyle: {
                                                            color: 'rgba(255,255,255,1)', // Set the desired color
                                                        },
                                                        icon: "#FEFEFE",
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 100}},
                                                            close: {animation: 'timing', config: {duration: 100}},
                                                        },
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="WaitingForPayment"
                                                    component={WaitingForPayment}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Várakozás a fizetésre...",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        leftIconColor: '#ffffff',
                                                        gestureEnabled: false,
                                                        headerStyle: {
                                                            backgroundColor: '#393939', // Set the desired background color
                                                        },
                                                        headerTintColor: '#ffffff', // Change the back arrow color here
                                                        headerTitleStyle: {
                                                            color: 'rgba(255,255,255,1)', // Set the desired color
                                                        },
                                                        icon: "#FEFEFE",
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 100}},
                                                            close: {animation: 'timing', config: {duration: 100}},
                                                        },
                                                        headerLeft: null, // Disable left icon
                                                        headerRight: null, // Disable right icon
                                                    }}
                                                />
                                            </Stack.Navigator>
                                        </BluetoothProvider>
                                    </CartProvider>
                                    </TableProvider>
                                </SystemProvider>
                            </OrdersProvider>
                        </PersistGate>
                    </ReduxProvider>
                </Root>
            </NavigationContainer>
            <Toast/>
        </ThemeProvider>
    );
}

const styles = StyleSheet.create({
    background: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
});