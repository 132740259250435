import React, {useContext, useState} from 'react';
import {
    SafeAreaView,
    ScrollView,
    StatusBar,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    Modal,
    Button,
    Alert,
    Platform, Switch, TextInput
} from 'react-native';
import FeatherIcon from 'react-native-vector-icons/Feather';
import { LinearGradient } from "expo-linear-gradient";
import {SystemContext} from "../helpers/SystemContext";
import AsyncStorage from "@react-native-async-storage/async-storage";

export default function DesignSettingsScreen({ navigation }) {
    const { defaultView, setEventColumnCount, setCategoryColumnCount } = useContext(SystemContext);

    const [modalVisible, setModalVisible] = useState(false);
    const [selectedDefaultView, setSelectedDefaultView] = useState(defaultView || 'categories');
    const [columnModalVisible, setColumnModalVisible] = useState(false);
    const [eventModalVisible, setEventModalVisible] = useState(false);
    const [columnInput, setColumnInput] = useState('');
    const [eventInput, setEventInput] = useState('');

    const handleDefaultViewChange = (view) => {
        setSelectedDefaultView(view);
        AsyncStorage.setItem('defaultView', view);
        setModalVisible(false);
    };

    const handleColumnSetting = () => {
        setColumnModalVisible(true);
    };

    const handleEventSetting = () => {
        setEventModalVisible(true);
    };

    const handleColumnSubmit = () => {
        AsyncStorage.setItem('categoryColumnCount', columnInput);
        setCategoryColumnCount(Number(columnInput));
        setColumnModalVisible(false);
    };

    const handleEventSubmit = () => {
        AsyncStorage.setItem('eventColumnCount', eventInput);
        setEventColumnCount(Number(eventInput));
        setEventModalVisible(false);
    };

    return (
        <LinearGradient
            colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']}
            style={{ flex: 1, width: '100%', height: '100%' }}
        >
            <SafeAreaView style={{ flex: 1, backgroundColor: 'transparent', paddingTop: 10 }}>
                <StatusBar backgroundColor="transparent" translucent barStyle="dark-content" />
                <ScrollView contentContainerStyle={styles.content}>
                    <View style={styles.section}>
                        <Text style={styles.sectionTitle}>Design Settings</Text>
                        <View style={styles.sectionBody}>
                            <TouchableOpacity
                                style={styles.row}
                                activeOpacity={1}
                                onPress={() => setModalVisible(true)}
                            >
                                <Text style={styles.rowLabel}>Alapértelmezett értékesítés nézet:</Text>
                                <View style={styles.rowSpacer} />
                                <FeatherIcon
                                    color="#bcbcbc"
                                    name="chevron-right"
                                    size={19}
                                />
                            </TouchableOpacity>
                            <TouchableOpacity
                                style={styles.row}
                                activeOpacity={1}
                                onPress={handleColumnSetting}
                            >
                                <Text style={styles.rowLabel}>Kategória nézet oszlopok</Text>
                                <View style={styles.rowSpacer} />
                                <FeatherIcon
                                    color="#bcbcbc"
                                    name="chevron-right"
                                    size={19}
                                />
                            </TouchableOpacity>
                            <TouchableOpacity
                                style={styles.row}
                                activeOpacity={1}
                                onPress={handleEventSetting}
                            >
                                <Text style={styles.rowLabel}>Események száma soronként</Text>
                                <View style={styles.rowSpacer} />
                                <FeatherIcon
                                    color="#bcbcbc"
                                    name="chevron-right"
                                    size={19}
                                />
                            </TouchableOpacity>
                        </View>
                    </View>
                </ScrollView>
            </SafeAreaView>

            {modalVisible && <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
                onRequestClose={() => setModalVisible(false)}
            >
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <Text style={styles.modalTitle}>Alapértelmezett értékesítés nézet:</Text>
                        <TouchableOpacity style={styles.modalButton} onPress={() => handleDefaultViewChange('events')}>
                            <Text style={styles.modalButtonText}>Események / Jegyek</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.modalButton}
                                          onPress={() => handleDefaultViewChange('categories')}>
                            <Text style={styles.modalButtonText}>Csoportok / Termékek</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.closeButton} onPress={() => setModalVisible(false)}>
                            <Text style={styles.closeButtonText}>Mégsem</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </Modal>}

            {columnModalVisible && <Modal
                animationType="slide"
                transparent={true}
                visible={columnModalVisible}
                onRequestClose={() => setColumnModalVisible(false)}
            >
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <Text style={styles.modalTitle}>Csoportok nézet oszlopok</Text>
                        <TextInput
                            style={styles.input}
                            placeholder="Hány oszlopot szeretne a kategória nézetben?"
                            keyboardType="numeric"
                            value={columnInput}
                            onChangeText={setColumnInput}
                        />
                        <TouchableOpacity style={styles.modalButton} onPress={handleColumnSubmit}>
                            <Text style={styles.modalButtonText}>OK</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.closeButton} onPress={() => setColumnModalVisible(false)}>
                            <Text style={styles.closeButtonText}>Mégsem</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </Modal>}

            {eventModalVisible && <Modal
                animationType="slide"
                transparent={true}
                visible={eventModalVisible}
                onRequestClose={() => setEventModalVisible(false)}
            >
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <Text style={styles.modalTitle}>Események száma oszloponként</Text>
                        <TextInput
                            style={styles.input}
                            placeholder="Hány eseményt szeretne látni?"
                            keyboardType="numeric"
                            value={eventInput}
                            onChangeText={setEventInput}
                        />
                        <TouchableOpacity style={styles.modalButton} onPress={handleEventSubmit}>
                            <Text style={styles.modalButtonText}>OK</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.closeButton} onPress={() => setEventModalVisible(false)}>
                            <Text style={styles.closeButtonText}>Mégsem</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </Modal>}
        </LinearGradient>
    );
}

const styles = StyleSheet.create({
    content: {
        paddingHorizontal: 16,
        backgroundColor: 'transparent'
    },
    section: {
        paddingVertical: 12,
    },
    sectionTitle: {
        margin: 8,
        marginLeft: 12,
        fontSize: 13,
        letterSpacing: 0.33,
        fontWeight: '500',
        color: '#a69f9f',
        textTransform: 'uppercase',
    },
    sectionBody: {
        borderRadius: 12,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.5,
        shadowRadius: 1.41,
        elevation: 3,
    },
    row: {
        height: 44,
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingRight: 12,
        paddingLeft: 16,
        backgroundColor: '#fff',
        borderTopWidth: 1,
        borderColor: '#f0f0f0',
    },
    rowLabel: {
        fontSize: 16,
        letterSpacing: 0.24,
        color: '#000',
    },
    rowSpacer: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 0,
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        width: '80%',
        backgroundColor: 'white',
        borderRadius: 10,
        padding: 20,
        alignItems: 'center',
    },
    modalTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 20,
    },
    modalButton: {
        backgroundColor: '#2196F3',
        borderRadius: 5,
        padding: 10,
        marginVertical: 5,
        width: '100%',
        alignItems: 'center',
    },
    modalButtonText: {
        color: 'white',
        fontWeight: 'bold',
    },
    closeButton: {
        marginTop: 20,
        backgroundColor: '#f44336',
        borderRadius: 5,
        padding: 10,
        width: '100%',
        alignItems: 'center',
    },
    closeButtonText: {
        color: 'white',
        fontWeight: 'bold',
    },
    input: {
        width: '100%',
        padding: 10,
        borderColor: '#ccc',
        borderWidth: 1,
        borderRadius: 5,
        marginBottom: 10,
    },
});