// AdminScreen.js
import React from "react";
import WebView from "react-native-webview";
import {StyleSheet, View} from "react-native";
import {LinearGradient} from "expo-linear-gradient";
import LottieView from "lottie-react-native";

export default function TablesBookingsScreen({navigation}) {
    return (
        <LinearGradient
            colors={['#f2e2d2', '#e6d6c1', '#dcd5ce']}
            style={{flex: 1}}
        >
            <View style={styles.container}>
                <LottieView
                    source={require('../../assets/animations/no-bookings.json')}
                    style={styles.lottie}
                    autoPlay
                    loop
                />
            </View>
        </LinearGradient>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',

    },
    lottie: {
        width: 500,
        height: 500,
    },
});
