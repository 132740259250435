import React, {useEffect, useState} from 'react';
import {
    FlatList,
    Modal,
    PermissionsAndroid,
    Platform,
    SafeAreaView,
    StatusBar,
    StyleSheet,
    Switch,
    Text,
    TouchableOpacity,
    View
} from 'react-native';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import {LinearGradient} from 'expo-linear-gradient';
import RNBluetoothClassic from 'react-native-bluetooth-classic';
import AsyncStorage from "@react-native-async-storage/async-storage";

const ScanDevicesScreen = ({navigation}) => {
    const [isScanning, setIsScanning] = useState(false);
    const [devices, setDevices] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [cashierDevice, setCashierDevice] = useState(null);
    const [printerDevice, setPrinterDevice] = useState(null);

    useEffect(() => {
        if (Platform.OS === 'android' && Platform.Version >= 23) {
            PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION).then(async (result) => {
                if (!result) {
                    console.error('User refused location permission');
                } else {

                    await PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.BLUETOOTH_CONNECT);

                    startScan();
                }
            });
        }

        const loadStoredDevices = async () => {
            const storedCashierDevice = await AsyncStorage.getItem('cashierDevice');
            const storedPrinterDevice = await AsyncStorage.getItem('printerDevice');
            if (storedCashierDevice) setCashierDevice(JSON.parse(storedCashierDevice));
            if (storedPrinterDevice) setPrinterDevice(JSON.parse(storedPrinterDevice));
        };

        loadStoredDevices()
    }, []);

    const startScan = async () => {
        if (!isScanning) {
            setDevices([]);
            setIsScanning(true);

            try {
                const boundedDevicesList = await RNBluetoothClassic.getBondedDevices();
                const availableBoundedDevices = [...boundedDevicesList.map((device) => ({...device, bounded: true}))];
                setDevices(availableBoundedDevices);
            } catch (error) {
                console.error('Error scanning for Bluetooth devices:', error);
            } finally {
                setIsScanning(false);
            }
            try {
                const discoverDevices = await RNBluetoothClassic.startDiscovery();
                if (discoverDevices) {
                    const availableDiscoveredDevices = [...discoverDevices.map((device) => ({
                        ...device,
                        bounded: false
                    }))];
                    setDevices(availableDiscoveredDevices);
                }
            } catch (error) {
                console.error('Error scanning for Bluetooth devices:', error);
            } finally {
                setIsScanning(false);
            }
        }
    };

    const getDeviceIcon = (device) => {
        if (device.bounded === false) {
            return 'progress-question';
        } else if (device.name.includes('ECR-')) {
            return 'cash-register';
        } else if (device.name.includes('Keyboard')) {
            return 'keyboard';
        } else if (device.name.includes('Mouse')) {
            return 'mouse-pointer';
        } else if (device.name.includes('Phone')) {
            return 'phone';
        } else if (device.name.includes('Tablet')) {
            return 'tablet';
        } else if (device.name.includes('Laptop')) {
            return 'laptop';
        } else {
            return 'bluetooth';
        }
    };
    const handleDeviceSelection = (device) => {
        if (selectedDevice && selectedDevice.address === device.address) {
            setSelectedDevice(null); // Deselect the device if it is already selected
        } else {
            setSelectedDevice(device); // Select the new device
            setModalVisible(true);
        }
    };

    const handleDeviceTypeSelection = async (type) => {
        if (selectedDevice) {
            if (type === 'Cashier') {
                if (await AsyncStorage.getItem('cashierDevice') || (cashierDevice && cashierDevice.address === selectedDevice.address)) {
                    await AsyncStorage.removeItem('cashierDevice');
                    setCashierDevice(null);
                } else {
                    setCashierDevice(selectedDevice);
                    if (printerDevice && printerDevice.address === selectedDevice.address) {
                        await AsyncStorage.removeItem('printerDevice');
                        setPrinterDevice(null);
                    }
                }
                await AsyncStorage.setItem('cashierDevice', JSON.stringify(selectedDevice));

            } else if (type === 'Printer') {
                if (await AsyncStorage.getItem('printerDevice') || (printerDevice && printerDevice.address === selectedDevice.address)) {
                    await AsyncStorage.removeItem('printerDevice');
                    setPrinterDevice(null);
                } else {
                    setPrinterDevice(selectedDevice);
                    if (await AsyncStorage.getItem('printerDevice') || (cashierDevice && cashierDevice.address === selectedDevice.address)) {
                        await AsyncStorage.removeItem('cashierDevice');
                        setCashierDevice(null);
                    }
                }
                await AsyncStorage.setItem('printerDevice', JSON.stringify(selectedDevice));
            }
            setModalVisible(false);
        }
    };


    const loadStoredDevices = async () => {
        const storedCashierDevice = await AsyncStorage.getItem('cashierDevice');
        const storedPrinterDevice = await AsyncStorage.getItem('printerDevice');
        if (storedCashierDevice) setCashierDevice(JSON.parse(storedCashierDevice));
        if (storedPrinterDevice) setPrinterDevice(JSON.parse(storedPrinterDevice));
    };

    useEffect(() => {
        loadStoredDevices();
    }, []);

    useEffect(() => {
        loadStoredDevices();
    }, [cashierDevice, printerDevice]);

    const renderItem = ({item}) => {
        const isSelected = (cashierDevice && item.address === cashierDevice.address) || (printerDevice && item.address === printerDevice.address);
        return (
            <TouchableOpacity style={styles.deviceContainer} onPress={() => handleDeviceSelection(item)}>
                <MaterialCommunityIcons name={getDeviceIcon(item)} size={24} color="#000" style={styles.deviceIcon}/>
                <Text style={styles.deviceName}>{item.name}</Text>
                <Text style={styles.deviceId}>{item.address}</Text>
                {isSelected && (
                    <View style={styles.badge}>
                        <MaterialCommunityIcons name="check-bold" size={16} color="#fff"/>
                    </View>
                )}
            </TouchableOpacity>
        );
    };

    const [filterUnnamed, setFilterUnnamed] = useState(true);

    return (
        <LinearGradient colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']} style={{flex: 1, width: '100%', height: '100%'}}>
            <SafeAreaView
                style={{flex: 1, backgroundColor: 'transparent', paddingTop: Platform.OS === 'web' ? 10 : 50}}>
                <StatusBar backgroundColor="transparent" translucent barStyle="dark-content"/>
                <View style={styles.content}>
                    <TouchableOpacity style={styles.scanButton} onPress={startScan}>
                        <Text
                            style={styles.scanButtonText}>{isScanning ? 'Keresés leállítása...' : 'Eszközök keresése'}</Text>
                    </TouchableOpacity>
                    <View style={styles.switchContainer}>
                        <Text style={styles.switchLabel}>Csak a névvel rendelkező eszközök megjelenítése</Text>
                        <Switch
                            value={filterUnnamed}
                            onValueChange={setFilterUnnamed}
                        />
                    </View>
                    <FlatList
                        data={filterUnnamed ? devices.filter((d) => d.name) : devices}
                        keyExtractor={(item) => item.address}
                        renderItem={renderItem}
                        contentContainerStyle={[styles.deviceList, {paddingBottom: 20}]} // Add padding to the bottom
                    />
                </View>
            </SafeAreaView>
            <Modal
                animationType="slide"
                transparent={true}
                visible={modalVisible}
                onRequestClose={() => setModalVisible(false)}
            >
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <Text style={styles.modalTitle}>Eszköz típusának kiválasztása:</Text>
                        <TouchableOpacity
                            style={[
                                styles.modalButton,
                                cashierDevice && selectedDevice && cashierDevice.address === selectedDevice.address && styles.selectedButton
                            ]}
                            onPress={() => handleDeviceTypeSelection('Cashier')}
                        >
                            <MaterialCommunityIcons name="cash-register" size={24} color="#fff"
                                                    style={styles.modalButtonIcon}/>
                            <Text style={styles.modalButtonText}>Pénztárgép</Text>
                            {cashierDevice && selectedDevice && cashierDevice.address === selectedDevice.address && (
                                <MaterialCommunityIcons name="check-bold" size={24} color="#fff"
                                                        style={styles.modalButtonPipeIcon}/>
                            )}
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={[styles.modalButton, styles.disabledButton]}
                            disabled={true}
                        >
                            <MaterialCommunityIcons name="printer" size={24} color="#fff"
                                                    style={styles.modalButtonIcon}/>
                            <Text style={styles.modalButtonText}>Adóügyi nyomtató</Text>
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={[
                                styles.modalButton,
                                printerDevice && selectedDevice && printerDevice.address === selectedDevice.address && styles.selectedButton
                            ]}
                            onPress={() => handleDeviceTypeSelection('Printer')}
                        >
                            <MaterialCommunityIcons name="printer" size={24} color="#fff"
                                                    style={styles.modalButtonIcon}/>
                            <Text style={styles.modalButtonText}>Nyomtató</Text>
                            {printerDevice && selectedDevice && printerDevice.address === selectedDevice.address && (
                                <MaterialCommunityIcons name="check-bold" size={24} color="#fff"
                                                        style={styles.modalButtonPipeIcon}/>
                            )}
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.closeButton} onPress={() => setModalVisible(false)}>
                            <Text style={styles.closeButtonText}>Mégsem</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </Modal>
        </LinearGradient>
    );
};

const styles = StyleSheet.create({
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        paddingHorizontal: 16,
    },
    headerAction: {
        width: 40,
        height: 40,
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    headerTitle: {
        fontSize: 19,
        fontWeight: '600',
        color: '#000',
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 0,
        textAlign: 'center',
    },
    content: {
        paddingHorizontal: 16,
        backgroundColor: 'transparent',
    },
    scanButton: {
        alignItems: 'center',
        justifyContent: 'center',
        paddingVertical: 16,
        paddingHorizontal: 16,
        backgroundColor: '#f2b34c',
        margin: 10,
        borderRadius: 12,
    },
    scanButtonText: {
        fontSize: 16,
        letterSpacing: 0.25,
        color: '#fff',
    },
    deviceList: {
        paddingVertical: 16,
    },
    deviceContainer: {
        padding: 12,
        backgroundColor: '#fff',
        borderRadius: 12,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: 10,
    },
    selectedDevice: {
        backgroundColor: 'green',
    },
    deviceName: {
        fontSize: 16,
        fontWeight: '600',
        color: '#292929',
    },
    deviceId: {
        fontSize: 14,
        color: '#858585',
    },
    switchContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingHorizontal: 16,
        marginVertical: 10,
    },
    deviceIcon: {
        marginRight: 10,
    },
    switchLabel: {
        fontSize: 16,
        color: '#000',
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        width: '80%',
        backgroundColor: 'white',
        borderRadius: 10,
        padding: 20,
        alignItems: 'center',
    },
    modalTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 20,
    },
    badge: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        width: 20,
        height: 20,
        borderRadius: 10,
        backgroundColor: 'green',
        alignItems: 'center',
        justifyContent: 'center',
    },


    modalButton: {
        backgroundColor: '#4CAF50',
        borderRadius: 10,
        padding: 15,
        marginVertical: 10,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    disabledButton: {
        backgroundColor: '#B0BEC5',
    },
    modalButtonText: {
        color: 'white',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16,
    },
    modalButtonIcon: {
        marginRight: 10,
    },
    modalButtonPipeIcon: {
        marginLeft: 'auto',
    },
    closeButton: {
        marginTop: 20,
        backgroundColor: '#f44336',
        borderRadius: 10,
        padding: 15,
        alignItems: 'center',
        width: '100%',
    },
    closeButtonText: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: 16,
    },
    selectedButton: {
        backgroundColor: '#FF9800',
    },
});

export default ScanDevicesScreen;